import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FileUpload from '../../components/FileUpload/FileUpload';

const RelatedOffers: FC = () => {
  const { t } = useTranslation('relatedOffers');

  return (
    <div className="wrapper" data-testid="related-offers-container">
      <div className="w-[800px]">
        <h1 className="text-xl font-semibold mb-6">{t('title')}</h1>
        <div className="rounded-xl bg-white p-4">
          <h2 className="text-lg font-semibold">{t('subtitle')}</h2>
          <p className="text-base py-6">{t('helper')}</p>
          <FileUpload type="AFFILIATES" />
        </div>
      </div>
    </div>
  );
};

export default RelatedOffers;
