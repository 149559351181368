import clsx from 'clsx';
import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BrandContext } from '../../../context/BrandContext';
import useBrandsById from '../../../services/queries/useBrandById';
import { getAllBrandClaims } from '../../../services/queries/userManagement';
import Icon from '../../UI/Icon/Icon';
import Loader from '../../UI/Loader/Loader';


interface Props {
    setBackClaimSample?: any;
}

const ClaimSampleContainer: FC<Props> = ({ setBackClaimSample }) => {
    const { brandId } = useParams();
    const navigate = useNavigate();
    const { setBrand } = useContext(BrandContext);
    const [brandClaimSamples, setBrandClaimSamples] = useState<any>();
    const [loading, setLoading] = useState<boolean>(true);
    const { data: brand, refetch: getBrandById } = useBrandsById(brandId);
    useEffect(() => {
        getBrandById().then(res => setBrand(res.data));
    }, []);

    const getClaimSampleData = () => {
        getAllBrandClaims('', `brandclaimsample/${brandId}`, (res: any) => {
            if (res.isSuccess) {
                setBrandClaimSamples(res?.data?.brandClaimSamples);
            } else {
                setBrandClaimSamples([]);
            }
            setLoading(false)
        });
    };

    useEffect(() => {
        if (brandId) {
            getClaimSampleData();
        } else {
            setBrandClaimSamples([])
        }
    }, [brandId]);

    return (
        <div className="wrapper mb-40 items-start" data-testid="brand-container">
            <h1 className="text-center w-full">{brand?.displayName}</h1>

            <div className="flex justify-start ">
                <button
                    type="button"
                    onClick={() => navigate(-1)}
                    aria-label="go back"
                    data-testid="back-button"
                >
                    <Icon name="back-arrow" width="24" />
                </button>
                <h1 className="font-semibold">Claim Samples</h1>
            </div>

            <div className="flex flex-col bg-white rounded-md w-[1000px]">

                <div className="flex flex-row item-center w-full text-white  bg-[#474747]">

                    <div style={{ width: '30%' }} className="font-semibold p-4">Media</div>
                    <div style={{ width: '70%' }} className="font-semibold p-4">Title</div>
                </div>

                {/* <h3 className="font-semibold p-5 pb-2">Claim Samples</h3> */}
                <div className="w-full rounded-[100px]">

                    <div className="first:border-none p-4">
                        {/* eslint-disable-next-line */}
                        {loading ? <div className="h-16"><Loader /></div> : brandClaimSamples?.length ? brandClaimSamples?.map((bc: any, i: number) => (
                            <div
                                key={i}
                                data-testid="brand-ad"
                                className={clsx(
                                    ' flex items-center w-full',
                                    // index > 0 && 'border-t-[1px] border-lightGrey'
                                )}
                            >
                                <div style={{ width: '30%' }} className="w-48 pl-4 flex items-center">
                                    <img src={bc?.imageUrl} alt="Ad" style={{ width: '150px' }} className=" rounded-lg" />

                                </div>
                                <div style={{ width: '70%' }} className="flex items-center">
                                    <p className="text-[1.125rem] pl-4">{bc?.title}</p>
                                </div>
                            </div>
                        )) : <div className="w-full text-red-500 p-8">
                            <div style={{ width: '100%', padding: '50px' }}>
                                No Claim Samples Available
                            </div>
                        </div>}
                    </div>
                </div>

            </div>
        </div>
    )
};

export default ClaimSampleContainer;