import React, { createContext, Dispatch, SetStateAction, useMemo, useState } from 'react';
import { BrandResponse } from '../api';

interface Props {
  children: React.ReactNode;
}

export interface BrandInterface {
  brand?: BrandResponse;
  setBrand: Dispatch<SetStateAction<BrandResponse | undefined>>;
}

export const BrandContext = createContext<BrandInterface>({} as BrandInterface);

export const BrandContextProvider: React.FC<Props> = ({ children }) => {
  const [brand, setBrand] = useState<BrandInterface['brand']>(undefined);

  const BrandContextValue = useMemo(
    () => ({
      brand,
      setBrand,
    }),
    [brand, setBrand]
  );
  return (
    <BrandContext.Provider value={BrandContextValue}>{children}</BrandContext.Provider>
  );
};
