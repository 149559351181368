import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { GetBrandsResponse } from '../../api';
import TickleApi from '../Services';

const useBrands = () => {
  const getBrands = async () => (await TickleApi.getBrands()).data;

  return useQuery<GetBrandsResponse, AxiosError>(['brands'], getBrands, {
    enabled: false,
  });
};

export default useBrands;
