import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ListStories from '../../components/ListViews/ListStories/ListStories';
import Button from '../../components/UI/Button/Button';
import Loader from '../../components/UI/Loader/Loader';
import Modal from '../../components/UI/Modal/Modal';
import { BrandContext } from '../../context/BrandContext';
import { StoryContext } from '../../context/StoryContext';
import useBrandsById from '../../services/queries/useBrandById';
import { deleteBrandStory, getBrandStory } from '../../services/queries/userManagement';


const Story: FC = () => {
    const { brandId } = useParams();
    const nav = useNavigate();
    const { setBrand } = useContext(BrandContext);
    const { data: brand, refetch: getBrandById } = useBrandsById(brandId);
    const [stories, setStories] = useState<any>();
    const [error, setError] = useState<any>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [modal, setModal] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<any>();
    const { setStory } = useContext(StoryContext);

    const getBrandStories = () => {
        setLoading(true);

        getBrandStory(
            '',
            `/brand/${brandId}/stories`,
            (res: any) => {
                if (res.isSuccess) {
                    setStories(res?.data?.brandStories);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            }
        );
    };

    useEffect(() => {
        getBrandById().then(res => setBrand(res.data));
        getBrandStories();
    }, []);

    const delBrandStory = (item: any) => {
        deleteBrandStory(
            '',
            `/brand/story/${item?.id}`,
            (res: any) => {
                if (res.isSuccess) {
                    getBrandStories();
                    setError('');
                    setModal(false);
                } else {
                    setError(res.error.message);
                }
            }
        );
    }

    return (
        <div className="wrapper" data-testid="brand-partners-container">
            <h1>{brand?.displayName}</h1>
            {/* <h1 className="text-[28px] font-semibold mb-6 self-start">Stories</h1> */}
            <div className={`bg-white p-12 items-center rounded-md flex flex-row ${error?.length ? 'justify-between' : 'justify-end'} w-full`}>
                {error?.length ? <div className="text-red-500 text-lg"> {error}</div> : null}
                <Button
                    type="secondary"
                    onClick={() => {
                        setStory(undefined);
                        nav(`${window.location.pathname}/../${brandId}/create`);
                    }}
                    className=""
                >
                    Create Story
                </Button>
            </div>

            {loading ? (
                <Loader />
            ) : (
                <div>


                    {stories && stories.length > 0 ? (
                        <div className="flex flex-col bg-white rounded-md w-[1000px]">
                            {/* <h3 className="font-semibold p-5 pb-2">{t('adContainerTitle')}</h3> */}

                            <div className="flex flex-row item-center w-full text-white  bg-[#474747]">
                                <div className="flex flex-row" style={{ width: '85%' }} >
                                    <div style={{ width: '20%' }} className="font-semibold p-4">Media</div>
                                    <div style={{ width: '60%' }} className="font-semibold p-4">Title</div>
                                    <div style={{ width: '20%' }} className="font-semibold p-4">Type</div>
                                    <div style={{ width: '20%' }} className="font-semibold p-4">Date Created</div>
                                </div>
                                <div className="font-semibold p-4" style={{ width: '15%' }}>Remove</div>

                            </div>
                            <div>
                                {stories?.sort((a: any, b: any) => (b.creationDate.localeCompare(a.creationDate))).map((ad: any, index: number) => (
                                    <ListStories
                                        key={ad.id}
                                        brandId={brandId}
                                        adId={ad.id}
                                        item={ad}
                                        onClick={() => {
                                            setStory(ad);
                                            nav(`${window.location.pathname}/edit/${ad.id}`)
                                        }}
                                        delBrandStory={(item: any) => { setModal(true); setDeleteId(item) }}
                                        index={index} />
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className="w-full rounded-xl flex flex-row p-6 items-center">
                            {/* <h3 className="font-semibold self-start justify-self-start">{t('ad')}</h3> */}
                            <p className="mb-3 mt-4 text-textSecondary">No Stories Available</p>
                            {/* <Button
                                type="outline"
                                onClick={() => {
                                    setStory(undefined);
                                    nav(`${window.location.pathname}/../${brandId}/create`);
                                }}
                            >
                                Create Story
                            </Button> */}
                        </div>
                    )}
                </div>
            )}



            {/* </Modal> */}
            <Modal type="small" isOpen={modal} setIsOpen={setModal}>
                <div className="h-full flex flex-col justify-between">
                    <div>
                        <h2 className="text-[20px] font-semibold mb-3">Delete Story</h2>
                        <p>Are you sure you want to delete Story?</p>
                        {error ? <div className="text-red-500">{error}</div> : null}
                    </div>
                    <div className="flex justify-between">
                        <Button type="text" onClick={() => setModal(false)}>
                            No, go back
                        </Button>
                        <Button
                            type="secondary"
                            onClick={() => {
                                delBrandStory(deleteId);
                            }}
                        >
                            Delete
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
};

export default Story;