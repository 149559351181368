import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Dispatch, FC, Fragment, useRef } from 'react';
import Icon from '../Icon/Icon';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<boolean>;
  title?: string;
  children: React.ReactNode;
  type: 'small' | 'large';
  showCloseIcon?: boolean;
  height?: string;
}

const Modal: FC<Props> = ({
  isOpen,
  setIsOpen,
  title,
  children,
  type,
  showCloseIcon = false,
  height,
}) => {
  const refDiv = useRef(null);
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        initialFocus={refDiv}
        onClose={() => setIsOpen(false)}
        className="relative z-50"
        data-testid="modal"
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/70" aria-hidden="true" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="fixed inset-0 flex justify-center items-center" >
            <Dialog.Panel
              ref={refDiv}
              className={clsx(
                'bg-white rounded-lg p-5',
                { 'w-[600px] h-[200px]': !height && type === 'small' },
                { 'w-[600px] h-[560px]': !height && type === 'large' },
                { 'w-[650px]': height },
                height
              )}
              style={{ overflowY: 'auto' }}
            >
              <Dialog.Title className="text-xl font-bold flex justify-between">
                {title}
                {showCloseIcon && (
                  <button
                    type="button"
                    onClick={() => setIsOpen(false)}
                    data-testid="close-modal-icon"
                  >
                    <Icon name="cross" className="cursor-pointer" />
                  </button>
                )}
              </Dialog.Title>
              {children}
            </Dialog.Panel>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default Modal;
