import clsx from 'clsx';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineDelete } from 'react-icons/ai';
import { BrandAd } from '../../../api';
import Video from '../../../assets/shared/video.png';


interface Props {
  item: BrandAd | any;
  adId: string;
  brandId: any;
  onClick: () => void;
  index: number;
  delBrandStory: any;
}

const ListStories: FC<Props> = ({ item, adId, brandId, delBrandStory, index, onClick }) => {

  const { t } = useTranslation('ads');


  useEffect(() => {
    const app = document.getElementById('qrcode-add');
    if (app) {
      app.style.display = 'none';
    }
  }, []);

  return (
    <div>
      <div
        data-testid="brand-ad"
        className={clsx(
          'p-4 flex items-center w-full justify-between',
          index > 0 && 'border-t-[1px] border-lightGrey'
        )}
      >
        <div style={{ width: '85%' }}>
          <button type="button" className="flex items-center w-full justify-start cursor-pointer"
            onClick={onClick}
          >
            <div style={{ width: "20%" }}>
              <img src={item?.storyType === 'image' ? item.storyUrlPath : Video} alt="Ad" className="w-20 rounded-lg" />
            </div>

            <div style={{ width: "60%" }}>
              <p className="text-[1.125rem] flex pl-4 justify-start">{item.title}</p>
            </div>

            <div style={{ width: "20%" }}>
              <p className="text-[1.125rem] text-left  pl-4  w-32">{item?.storyType?.length ? (item.storyType.charAt(0).toUpperCase() + item.storyType.slice(1)) : ''}</p>
            </div>

            <div style={{ width: "20%" }}>
              <p className="text-[1.125rem] text-left pl-4 w-32">{item.creationDate?.split('T')?.[0]}</p>
            </div>
          </button>
        </div>


        <div style={{ width: "15%" }}>
          <div className="flex w-full justify-center">
            <AiOutlineDelete className="text-red-500 text-xl ml-4 mr-2 cursor-pointer"
              onClick={() => delBrandStory(item)}
            />
          </div>

        </div>

      </div>
    </div >
  );
};

export default ListStories;
