import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { getAUserRequest } from '../../../services/queries/userManagement';
import Loader from '../../UI/Loader/Loader';
import { UserFormSchema } from './UserFormTypes';

interface Props {
  backUserData?: any;
  clickError?: boolean;
}

const UserFormDetails: FC<Props> = ({ backUserData, clickError }) => {
  const { t } = useTranslation('users');
  const { id } = useParams();
  const location = useLocation();
  const [user, setUser] = useState<any>({});
  const [error, setError] = useState<any>({
    FirstName: '',
    Surname: '',
    Email: '',
    UserName: '',
    Password: '',
    ConfirmPassword: '',
  });
  const [loader, setLoader] = useState(true);
  const {
    // control,
    register,
    formState: { errors },
  } = useFormContext<UserFormSchema>();
  // const titleWatcher = useWatch({ control, name: 'title' });

  const getData = () => {
    getAUserRequest('', `/${id}`, (res: any) => {
      if (res.isSuccess) {
        setUser(res?.data?.data);
        setError({ flag: false });
      } else {
        setError({ flag: true });
      }
      setLoader(false);
    });
  };

  useEffect(() => {
    getData();
  }, [id]);

  useEffect(() => {
    const { FirstName, Surname, Email, UserName, Password, ConfirmPassword,
    } = error;
    if ((!FirstName && !Surname && !Email && !UserName && !Password && !ConfirmPassword)
      || (!FirstName && !Surname && !Email && !UserName && id)
    ) {
      backUserData(user);
    }
  }, [user]);

  if (loader) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col bg-white rounded-md h-fit p-4 gap-3">
      {/* {clickError && <p className="text-red-500 my-2">Please Fill All Required Fields</p>} */}
      <label className="relative">
        <p className="font-semibold">{t('form.firstName')}*</p>
        <input
          type="text"
          data-testid="title-input"
          maxLength={50}
          className={clsx('input w-full', error.FirstName && 'border-error')}
          value={user?.FirstName}
          onChange={(e: any) => {
            setError({
              ...error,
              FirstName: (e.target.value?.length && e.target.value?.length < 50) ? '' : 'Please Enter First Name (Limit 50 Characters)',
            });
            setUser({ ...user, FirstName: e.target.value });
          }}
        />
        <p className="text-red-500 mt-2">{error.FirstName}</p>
      </label>

      <label className="relative">
        <p className="font-semibold">{t('form.lastName')}*</p>
        <input
          type="text"
          data-testid="title-input"
          maxLength={50}
          className={clsx('input w-full', error.Surname && 'border-error')}
          value={user?.Surname}
          onChange={(e: any) => {
            setError({
              ...error,
              Surname: (e.target.value?.length && e.target.value?.length < 50) ? '' : 'Please Enter Last Name (Limit 50 Characters)',
            });
            setUser({ ...user, Surname: e.target.value });
          }}
        // {...register('lastName', {
        //   required: 'Last Name is required.',
        //   minLength: {
        //     value: 3,
        //     message: 'Last Name.',
        //   },
        //   maxLength: 25,
        // })}
        />
        {/* <p className="absolute top-17 right-0 text-md text-[#666666]">
          {titleWatcher?.length}/25
        </p> */}
        <p className="text-error mt-2" role="alert">
          {error.Surname}
        </p>
      </label>

      <label>
        <p className="font-semibold">{t('form.emailId')}*</p>
        <input
          type="text"
          // eslint-disable-next-line
          disabled={id ? true : false}
          data-testid="id-input"
          className={clsx('input w-full', error?.Email && 'border-error')}
          value={user?.Email}
          onChange={(e: any) => {
            const mailformat =
              /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            setError({
              ...error,
              Email: e.target.value?.match(mailformat)
                ? ''
                : 'Please Enter Correct Email',
            });
            setUser({ ...user, Email: e.target.value });
          }}
        />
        <p className="text-error mt-2" role="alert">
          {error.Email}
        </p>
      </label>

      <label>
        <p className="font-semibold">{t('form.username')}*</p>
        <input
          type="text"
          // eslint-disable-next-line
          disabled={id ? true : false}
          data-testid="id-input"
          className={clsx('input w-full', error?.UserName && 'border-error')}
          value={user?.UserName}
          onChange={(e: any) => {
            setError({
              ...error,
              UserName: e.target.value?.length ? '' : 'Please Enter Username',
            });
            setUser({ ...user, UserName: e.target.value });
          }}
        />
        <p className="text-error mt-2" role="alert">
          {error.UserName}
        </p>
      </label>

      {id &&
        <>
          <label>
            <p className="font-semibold">{t('form.creationDate')}</p>
            <input
              type="text"
              // eslint-disable-next-line
              disabled={id ? true : false}
              data-testid="id-input"
              className={clsx('input w-full')}
              value={user?.CreationTime ? new Date(user?.CreationTime).toString() : ''}
              onChange={(e: any) => {
                console.log('')
              }}
            />
          </label>
          <label>
            <p className="font-semibold">{t('form.lastUpdated')}</p>
            <input
              type="text"
              // eslint-disable-next-line
              disabled={id ? true : false}
              data-testid="id-input"
              className={clsx('input w-full')}
              value={user?.LastUpdated ? new Date(user?.LastUpdated).toString() : ''}
              onChange={(e: any) => {
                console.log('')
              }}
            />
          </label>
        </>
      }

      {!id ? (
        <label>
          <p className="font-semibold">{t('form.password')}*</p>
          <input
            type="password"
            data-testid="id-input"
            className={clsx('input w-full', error?.Password && 'border-error')}
            value={user?.Password}
            onChange={(e: any) => {
              setError({
                ...error,
                Password:
                  e.target.value?.length > 7
                    ? ''
                    : 'Please Enter Password with atleast 8 Characters',
              });
              setUser({ ...user, Password: e.target.value });
            }}
          />
          <p className="text-error mt-2" role="alert">
            {error.Password}
          </p>
        </label>
      ) : (
        <span />
      )}

      {!id ? (
        <label>
          <p className="font-semibold">{t('form.confirmPassword')}*</p>
          <input
            type="password"
            data-testid="id-input"
            className={clsx('input w-full', error?.ConfirmPassword && 'border-error')}
            value={user?.ConfirmPassword}
            onChange={(e: any) => {
              setError({
                ...error,
                ConfirmPassword:
                  e.target.value === user?.Password ? '' : 'Please Match the Password',
              });
              setUser({ ...user, ConfirmPassword: e.target.value });
            }}
          />
          <p className="text-error mt-2" role="alert">
            {error.ConfirmPassword}
          </p>
        </label>
      ) : (
        <span />
      )}
      <label htmlFor="Status">
        <p className="font-semibold">{t('form.status')}*</p>

        <Select
          value={user?.Status ? user?.Status : 'Active'}
          onChange={(e: any) => setUser({ ...user, Status: e.target.value })}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value="Active" selected>
            Active
          </MenuItem>
          <MenuItem value="Pending">Pending</MenuItem>
          <MenuItem value="Blocked">Blocked</MenuItem>
        </Select>
        {/* <p className="text-error mt-2" role="alert">
          {errors.title?.message}
        </p> */}
      </label>
    </div>
  );
};

export default UserFormDetails;
