import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
  href: string;
  className?: string;
}

const NavButton: FC<Props> = ({ children, href, className }) => {
  const location = useLocation();
  return (
    <Link
      to={href}
      className={`w-full text-black rounded-[4px] h-12 mb-3 flex items-center px-4 gap-3
        ${className} ${location.pathname.includes(href) ? 'bg-[#f0f0f0] font-bold' : 'font-medium'}
      `}
      data-testid="nav-button"
    >
      {children}
    </Link>
  );
};

export default NavButton;
