import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../UI/Button/Button';

interface Props {
  discard: () => void;
  save: () => void;
  saveLoading?: boolean;
  positiveCTAText?: string;
  negativeCTAText?: string;
  clickError?: boolean;
  brandError?: any;
}

const StickyFooter: FC<Props> = ({
  discard,
  save,
  saveLoading,
  positiveCTAText,
  negativeCTAText,
  clickError,
  brandError
}) => {
  const { t } = useTranslation('common');
  return (
    <div
      className="flex flex-row justify-end items-center bg-white w-full h-[116px] fixed bottom-0 left-0 gap-4 p-8 shadow-top z-10"
      data-testid="sticky-footer"
    >
      {clickError && <p className="text-red-500 my-2 px-2">Please Fill All Required Fields</p>}
      {brandError && <p className="text-red-500 my-2 px-2">{brandError}</p>}
      <Button type="outline" onClick={discard}>
        {negativeCTAText || t('buttons.discard-changes')}
      </Button>
      {/* loading={saveLoading} */}
      <Button type="secondary" onClick={() => save()} >
        {positiveCTAText || t('buttons.save-changes')}
      </Button>
    </div>
  );
};

export default StickyFooter;
