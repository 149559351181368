import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { OfferResponse } from '../../api';
import TickleApi from '../Services';

const useWelcomeOffer = () => {
  const getWelcomeOffer = async () => (await TickleApi.getWelcomeOffer()).data;

  return useQuery<OfferResponse, AxiosError>(['welcomeoffer'], getWelcomeOffer);
};

export default useWelcomeOffer;
