import clsx from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ImageUploader from '../../ImageUploader/ImageUploader';
import { AdFormSchema } from './AdFormTypes';

interface AdFormImageProps {
  defaultImage?: string;
}

const AdFormImage = ({ defaultImage }: AdFormImageProps) => {
  const { t } = useTranslation('ads');

  const {
    control,
    clearErrors,
    setError,
    formState: { errors },
  } = useFormContext<AdFormSchema>();

  return (
    <div
      className={clsx(
        'bg-white rounded-md h-fit p-4',
        errors.imagePath && 'border-error'
      )}
      data-testid="image-input"
    >
      <h2 className="text-lg font-semibold">{t('form.ad-image')}</h2>
      <Controller
        control={control}
        name="imagePath"
        rules={{ required: 'Ad image is required.' }}
        render={({ field: { onChange } }) => (
          <ImageUploader
            subText="or drop an image to upload"
            criteria="Image size 900 x 750 px (.jpg .png or .webp). Maximum 5MB."
            widthReq={900}
            heightReq={750}
            defaultImg={defaultImage || ''}
            imageUrlCB={onChange}
            setErrorAdvert={error => setError('imagePath', error)}
            clearErrorsAdvert={clearErrors}
            imgType="AD_IMAGE"
            formError={errors.imagePath?.message}
          />
        )}
      />
    </div>
  );
};

export default AdFormImage;
