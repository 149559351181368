import { RadioGroup as HeadlessRadio } from '@headlessui/react';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsMutating } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import AdsSummary from '../../components/AdsSummary/AdsSummary';
import ListOfferLibrary from '../../components/ListViews/ListOfferLibrary/ListOfferLibrary';
import StickyFooter from '../../components/StickyFooter/StickyFooter';
import Button from '../../components/UI/Button/Button';
import Icon from '../../components/UI/Icon/Icon';
import Loader from '../../components/UI/Loader/Loader';
import Modal from '../../components/UI/Modal/Modal';
import Toast from '../../components/UI/Toast/Toast';
import { BrandContext } from '../../context/BrandContext';
import useBrandsById from '../../services/queries/useBrandById';
import useBrandOffers from '../../services/queries/useBrandOffers';
import useEditWelcomeOfferMutation from '../../services/queries/useEditWelcomeOfferMutation';
import useOfferShowOnMutation from '../../services/queries/useOfferShowOnMutation';
import useWelcomeOffer from '../../services/queries/useWelcomeOffer';

const OffersLibrary: FC = () => {
  const { brandId } = useParams();
  const { setBrand } = useContext(BrandContext);
  const { t } = useTranslation('offersLibrary');
  const [changes, setChanges] = useState<Map<string, boolean>>(new Map());
  const [modifiedGift, setModifiedGift] = useState<string | null>(null);
  const [confirmGift, setConfirmGift] = useState<string | null>(null);
  const nav = useNavigate();

  const { data: offers, isLoading } = useBrandOffers(brandId);
  const { data: currentOffer, isLoading: isCurrentOfferLoading } = useWelcomeOffer();
  const { data: brand } = useBrandsById(brandId);
  const {
    mutateAsync: updateOfferShowOn,
    isError: updateShowOnError,
    reset: resetShownOnError,
  } = useOfferShowOnMutation();
  const {
    mutateAsync: editWelcomeOfferAsync,
    isError: editWelcomeOfferError,
    reset: resetWelcomeOfferError,
  } = useEditWelcomeOfferMutation();
  const activeMutationCount = useIsMutating(['updateOfferShowOn', 'updateWelcomeOffer']);

  useEffect(() => {
    if (brand) {
      setBrand(brand);
    }
  }, [brand]);

  useEffect(() => {
    document.title = brand ? brand.displayName : 'Tickle';

    return () => {
      document.title = 'Tickle';
    };
  }, [brandId]);

  const clearChanges = () => {
    setChanges(new Map());
    setModifiedGift(null);
  };

  const onSave = () => {
    // Build an array of the mutations on each offer
    const mutations: Promise<unknown>[] = Array.from(changes).map(
      ([offerId, showOnBrandPage]) =>
        updateOfferShowOn({
          brandId: brandId || '',
          offerId,
          showOn: showOnBrandPage === true ? 'BRAND_PAGE' : undefined,
        })
    );

    if (modifiedGift !== null) {
      mutations.push(editWelcomeOfferAsync({ offerId: modifiedGift }));
    }

    // Wait for response from all mutations
    Promise.all(mutations)
      .then(() => {
        clearChanges();
      })
      .catch(() => {
        clearChanges();
      });
  };

  const onDiscard = () => {
    clearChanges();
  };

  const onGiftChanged = (value: string) => {
    // Don't show modal if there is no current gift or change is already pending
    if (currentOffer?.id === null || modifiedGift !== null) {
      setModifiedGift(value);
    } else {
      setConfirmGift(value);
    }
  };

  if (isLoading || isCurrentOfferLoading) {
    return (
      <div className="wrapper">
        <Loader />
      </div>
    );
  }

  return (
    <div className="wrapper" data-testid="offers-library-container">
      <h1>{brand?.displayName}</h1>
      <AdsSummary
        offers={offers?.items.filter(fl => fl.offerStatus !== 'Draft').length}
        boosts={offers?.totals.boosts}
        ctaText={t('buttons.create')}
        ctaAction={() => {
          nav(`${window.location.pathname}/create`);
        }}
      />
      {offers ? (
        <div
          className="text-left bg-white w-[1000px] rounded-md"
          data-testid="offers-container"
        >
          {offers.items.filter(fl => fl.offerStatus !== 'Draft').length > 0 ? (
            <HeadlessRadio
              data-testid="welcomeGiftSelectionRadio"
              name="welcomeGiftSelectionRadio"
              value={modifiedGift || currentOffer?.id}
              onChange={onGiftChanged}
            >
              <table className="w-full rounded-[100px]">
                <thead className="font-medium text-white bg-[#474747] rounded-[100px]">
                  <tr className="">
                    <th className="w-48 rounded-tl-md" />
                    <th className="w-4/12">
                      <h3 className="font-bold py-5">{t('offers-title')}</h3>
                    </th>
                    <th>
                      <h3 className="font-bold">{t('boosts')}</h3>
                    </th>
                    <th>
                      <h3 className="font-bold">{t('assigned')}</h3>
                    </th>
                    <th>
                      <h3 className="font-bold">{t('on-brand-page')}</h3>
                    </th>
                    <th>
                      <h3 className="font-bold">{t('gift')}</h3>
                    </th>
                  </tr>
                </thead>
                <tbody className="children:border-t-[1px] children:border-lightGrey first:border-none">
                  {offers.items.filter(fl => fl.offerStatus !== 'Draft').map(offer => (
                    <ListOfferLibrary
                      key={offer.id}
                      // eslint-disable-next-line
                      item={offer}
                      changes={changes}
                      setChanges={setChanges}
                      onClick={() => nav(`${window.location.pathname}/edit/${offer.id}`)}
                    />
                  ))}
                </tbody>
              </table>
            </HeadlessRadio>
          ) : (
            <div className="flex flex-col justify-center items-center h-[500px] gap-4">
              <Icon name="no-offers" width="88px" height="68px" />
              <h3 className="text-[20px] font-semibold">{t('no-offers')}</h3>
            </div>
          )}
        </div>
      ) : (
        <div className="w-full rounded-xl flex flex-row p-6 items-center">
          {/* <h3 className="font-semibold self-start justify-self-start">{t('ad')}</h3> */}
          <p className="mb-3 mt-4 text-textSecondary">{t('noAds')}</p>
          {/* <Button
            type="outline"
            onClick={() => {
              nav(`${window.location.pathname}/create`);
            }}
          >
            {t('buttons.create')}
          </Button> */}
        </div>
      )}
      <Modal
        type="small"
        isOpen={confirmGift !== null}
        setIsOpen={() => setConfirmGift(null)}
      >
        <div className="h-full flex flex-col justify-between">
          <div>
            <h2 className="text-[20px] font-semibold mb-3">
              {t('edit-welcome-offer-dialog-title')}
            </h2>
            <p>
              {t('edit-welcome-offer-dialog-message')}
              <strong>{currentOffer?.title}</strong>
            </p>
          </div>
          <div className="flex justify-between">
            <Button type="text" onClick={() => setConfirmGift(null)}>
              No, go back
            </Button>
            <Button
              type="secondary"
              onClick={() => {
                setModifiedGift(confirmGift);
                setConfirmGift(null);
              }}
            >
              Yes, replace gift
            </Button>
          </div>
        </div>
      </Modal>
      {(changes.size !== 0 || modifiedGift != null) && (
        <StickyFooter
          save={onSave}
          saveLoading={activeMutationCount > 0}
          discard={onDiscard}
        />
      )}
      <Toast
        message={t('update-show-on-error')}
        variant="warning"
        open={updateShowOnError}
        onClose={resetShownOnError}
      />
      <Toast
        message={t('edit-welcome-offer-error')}
        variant="warning"
        open={editWelcomeOfferError}
        onClose={resetWelcomeOfferError}
      />
    </div>
  );
};

export default OffersLibrary;
