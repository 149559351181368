import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { FileError, useDropzone } from 'react-dropzone';
import { ErrorOption, UseFormClearErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ImageUploadRequest } from '../../services/queries/usePostUploadImage';
import usePostUploadVideo from '../../services/queries/usePostUploadVideo';
import { AdFormSchema } from '../Forms/AdForm/AdFormTypes';
import { OfferFormType } from '../Forms/OfferForm/OfferForm';
import ProgressBar from '../ProgressBar/ProgressBar';
import Button from '../UI/Button/Button';
import Icon from '../UI/Icon/Icon';

interface Props {
  subText: string;
  criteria: string;
  widthReq: number;
  heightReq: number;
  imgType: ImageUploadRequest['type'];
  defaultImg: string | undefined;
  disabled?: boolean;
  logo?: boolean;
  formError?: string;
  imageUrlCB?: (response: string | undefined) => void;
  clearErrorsAdvert?: UseFormClearErrors<AdFormSchema>;
  clearErrorsOffer?: UseFormClearErrors<OfferFormType>;
  setErrorAdvert?: (error: ErrorOption) => void;
  setErrorOffer?: (error: ErrorOption) => void;
  backUpload?: any;
}

const VideoUploader: FC<Props> = ({
  subText,
  criteria,
  logo,
  widthReq,
  heightReq,
  disabled = false,
  defaultImg,
  imgType,
  formError,
  imageUrlCB,
  setErrorAdvert,
  clearErrorsAdvert,
  setErrorOffer,
  clearErrorsOffer,
  backUpload,
}) => {
  const { t } = useTranslation('brand');
  const [errors, setErrors] = useState<FileError>();
  const [imgUrl, setImgUrl] = useState<string | undefined>(defaultImg);
  const URL = window.URL || window.webkitURL;
  const { brandId } = useParams();

  const { mutate: uploadVideo, isLoading: uploading } = usePostUploadVideo(brandId);

  useEffect(() => {
    setImgUrl(defaultImg);
  }, [defaultImg]);

  useEffect(() => {
    backUpload(uploading);
  }, [uploading]);

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    minSize: 0,
    maxSize: 31457280,
    accept: {
      'video/*': ['.mp4'],
    },
    onDrop: (acceptedFile, fileRejections) => {
      fileRejections.forEach(fileItem => {
        fileItem.errors.forEach(err => {
          if (err.code === 'file-invalid-type') {
            setErrors({
              code: 'file-invalid-type',
              message: `Error: File needs to be mp4`,
            });
          }
          if (err.code === 'file-too-large') {
            setErrors({
              code: 'file-size',
              message: `Error: File is larger than 30mb.`,
            });
          }
        });
      });

      if (fileRejections.length > 0) return;

      uploadVideo(
        { file: acceptedFile[0] },
        {
          onSuccess: response => {
            setImgUrl(response.orignalPath);
            if (imageUrlCB) {
              imageUrlCB(response.path);
            }
          },
          onError: err => {
            setImgUrl('');
            if (imageUrlCB) {
              imageUrlCB('');
            }
            setErrors({
              message: err.data,
              code: 'Upload Failed.',
            });
          },
        }
      );
    },
  });

  return (
    <div
      className={clsx(
        'file-upload flex flex-col border-[1px] border-dashed rounded-md',
        (formError || errors) && 'bg-error-container border-[#C24F4F]',
        disabled && 'bg-lightGrey pointer-events-none'
      )}
      data-testid="file-upload"
    >
      <div
        {...getRootProps({ className: 'dropzone' })}
        className="flex flex-col items-center gap-3 py-8 w-full h-full"
      >
        <input {...getInputProps()} />

        {imgUrl ? (
          <>
            <video style={{ height: '500px' }} controls>
              <track src="" kind="captions" srcLang="en" label="english_captions" />
              <source src={imgUrl} />
            </video>

            {uploading && <ProgressBar />}

            <div className="flex justify-center">
              {/* <Button type="outline" onClick={() => open()} disabled={disabled}>
                {t('buttons.change')}
              </Button> */}
              <Button
                type="outline"
                disabled={disabled}
                onClick={() => {
                  setErrors(undefined);
                  setImgUrl('');
                  imageUrlCB?.('');
                }}
              >
                {t('buttons.remove')}
              </Button>
            </div>
          </>
        ) : (
          <>
            {errors ? (
              <Icon name="image-error" width="40px" />
            ) : (
              <Icon name="default-image" width="40px" />
            )}
            <Button
              type={errors ? 'cancel' : 'outline'}
              onClick={() => open()}
              disabled={disabled}
              data-testid="upload-button"
              loading={uploading}
            >
              {errors ? t('buttons.try-again') : t('buttons.add-video')}
            </Button>

            {uploading && <ProgressBar />}
          </>
        )}
        <aside
          className="flex flex-col justify-center items-center gap-2"
          aria-label={`info and criteria for ${imgType} image uploads`}
        >
          {!imgUrl && (
            <>
              <p>{subText}</p>
              <p className="text-md">{criteria}</p>
            </>
          )}
          <div className="flex items-center gap-1">
            <p className="text-errorText text-md">{formError || errors?.message}</p>
            {(formError || errors) && (
              <Icon name="error-exclamation" width="16px" height="16px" />
            )}
          </div>
        </aside>
      </div>
    </div>
  );
};

export default VideoUploader;
