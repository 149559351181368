import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import TickleApi from '../Services';

const useUsers = () => {
    const getUsers = async () => (await TickleApi.getUsers()).data;

    return useQuery<any, AxiosError>(['userList'], getUsers, {
        enabled: false,
    });
};

export default useUsers;
