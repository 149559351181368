import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Image from '../../assets/shared/login-bg.png';
import Button from '../../components/UI/Button/Button';
import Icon from '../../components/UI/Icon/Icon';
import { AuthContext } from '../../context/AuthContext';

type Inputs = {
  password: string;
  verifyPassword: string;
};

const ResetPassword = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [localError, setLocalError] = useState<string | null>(null);
  const [code, setCode] = useState<string | null>(null);
  const [submitted, setSubmitted] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation('auth');
  const { resetPassword, error } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (searchParams.get('oobCode')) {
      setCode(searchParams.get('oobCode'));
    }
  }, [searchParams]);

  const onSubmit: SubmitHandler<Inputs> = async data => {
    if (data.password !== data.verifyPassword) {
      setLocalError('Passwords need to match.');
    } else if (code) {
      resetPassword(code, data.password);
      setSubmitted(true);
    } else {
      setLocalError('Error occured, please try resetting your password again.');
    }
  };

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (submitted) return;
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSubmit]);

  return (
    <div className="flex flex-row w-full" data-testid="reset-password-container">
      <div className="flex flex-col items-center justify-center gap-12 max-h-[900px] w-[500px]">
        <div className="flex flex-col justify-between w-[500px] p-6">
          <form className="flex flex-col gap-4">
            <h1 className="text-xl font-semibold">{t('confirm-a-new-password')}</h1>
            {error && <p className="text-error mb-4 font-semibold">{error}</p>}
            {localError && <p className="text-error mb-4 font-semibold">{localError}</p>}
            <label className="relative">
              <p className="text-md">{t('new-password')}</p>
              <input
                type={passwordShown ? 'text' : 'password'}
                data-testid="reset-password-input"
                className={clsx('input w-full', errors.password && 'border-error')}
                disabled={submitted}
                {...register('password', {
                  required: true,
                  minLength: 8,
                  maxLength: 60,
                  value: '',
                })}
              />
              <button
                type="button"
                className="absolute top-8 right-4 cursor-pointer"
                aria-label="Password visiibility toggle"
                onClick={e => {
                  e.preventDefault();
                  setPasswordShown(!passwordShown);
                }}
                data-testid="password-visibility-toggle"
              >
                {passwordShown ? (
                  <Icon name="open-eye" className="h-6 w-6" />
                ) : (
                  <Icon name="closed-eye" className="h-6 w-6" />
                )}
              </button>
              <p className="text-error mt-2" role="alert">
                {errors.password && 'Valid password required.'}
              </p>
            </label>
            <label className="relative">
              <p className="text-md">{t('verify-password')}</p>
              <input
                type={passwordShown ? 'text' : 'password'}
                data-testid="reset-verifyPassword-input"
                className={clsx('input w-full', errors.verifyPassword && 'border-error')}
                disabled={submitted}
                {...register('verifyPassword', {
                  required: true,
                  minLength: 8,
                  maxLength: 60,
                  value: '',
                })}
              />
              <button
                type="button"
                className="absolute top-8 right-4 cursor-pointer"
                aria-label="Password visiibility second toggle"
                onClick={e => {
                  e.preventDefault();
                  setPasswordShown(!passwordShown);
                }}
                data-testid="password-visibility-toggle"
              >
                {passwordShown ? (
                  <Icon name="open-eye" className="h-6 w-6" />
                ) : (
                  <Icon name="closed-eye" className="h-6 w-6" />
                )}
              </button>
              <p className="text-error mt-2" role="alert">
                {errors.verifyPassword && 'Valid password required.'}
              </p>
            </label>
            {submitted ? (
              <>
                {!error && (
                  <h2 data-testid="reset-password-sent">{t('password-updated')}</h2>
                )}
                <Button
                  className="mt-4"
                  type="secondary"
                  size="full"
                  onClick={() => navigate('/login')}
                  data-testid="reset-navigate-button"
                >
                  {t('buttons.navigate-sign-in')}
                </Button>
              </>
            ) : (
              <>
                <p className="text-md">{t('passwords-match')}</p>
                <Button
                  className="mt-4"
                  type="secondary"
                  size="full"
                  onClick={handleSubmit(onSubmit)}
                  data-testid="reset-submit-button"
                  disabled={submitted}
                >
                  {t('buttons.reset-password')}
                </Button>
              </>
            )}
          </form>
        </div>
        <p className="text-sm">{t('tickle-footer')}</p>
      </div>
      <div className="flex justify-center w-[100%]">
        <img
          src={Image}
          alt="background"
          style={{ height: '100vh', width: '100%', objectFit: 'cover' }}
        />
      </div>
    </div>
  );
};

export default ResetPassword;
