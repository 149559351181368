import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { AddEditOfferRequest, OfferResponse } from '../../api';
import TickleApi from '../Services';

const useEditOffer = (brandId: string | undefined, id: string | undefined) => {
  const queryClient = useQueryClient();
  const editOffer = async (offer: AddEditOfferRequest) =>
    (await TickleApi.editOffer(brandId || '', id || '', offer)).data;

  return useMutation<OfferResponse, AxiosResponse, AddEditOfferRequest>(
    ['brand', id],
    offer => editOffer(offer),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['brand', id]);
      },
    }
  );
};

export default useEditOffer;
