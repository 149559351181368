import { RadioGroup as HeadlessRadio } from '@headlessui/react';
import clsx from 'clsx';
import Icon from '../Icon/Icon';

type SelectRadioOption = {
  id: string;
  name: string;
  value: string;
};

export type RadioProps = {
  id: string;
  name: string;
  options: SelectRadioOption[];
  value: SelectRadioOption | undefined;
  onChange: (option: SelectRadioOption) => void;
  onBlur?: () => void;
  error?: boolean;
  disabled?: boolean;
  /** The number of columns to split into. Will be based on breakpoint if undefined */
  cols?: {
    mobile: 1 | 2;
    tablet: 1 | 2;
    desktop: 1 | 2;
  };
};

const Radio = ({
  id,
  name,
  options,
  value,
  onChange,
  onBlur,
  error,
  disabled,
  cols,
}: RadioProps) => {
  return (
    <div className="w-full">
      <HeadlessRadio
        data-testid={id}
        name={name}
        value={options.find(o => o.id === value?.id)}
        onChange={onChange}
        disabled={disabled}
        onBlur={onBlur}
      >
        <div
          className={clsx(
            'grid relative gap-6',
            { 'grid-cols-1 md:grid-cols-2': !cols },
            { 'grid-cols-1': cols?.mobile === 1 },
            { 'grid-cols-2': cols?.mobile === 2 },
            { 'sm:grid-cols-1': cols?.tablet === 1 },
            { 'sm:grid-cols-2': cols?.tablet === 2 },
            { 'lg:grid-cols-1': cols?.desktop === 1 },
            { 'lg:grid-cols-2': cols?.desktop === 2 }
          )}
        >
          {options.map(option => (
            <HeadlessRadio.Option
              data-testid={`radio-${option.id}`}
              key={option.name}
              value={option}
              className={() =>
                clsx(
                  'gap-3 rounded-lg transition-colors selector-base flex items-center',
                  { 'opacity-40': disabled },
                  { 'cursor-pointer': !disabled },
                  { 'border-red-600': error }
                )
              }
            >
              {({ checked }) => (
                <>
                  <div
                    className={clsx(
                      'flex shrink-0 justify-center items-center  border-brandBlue w-[18px] h-[18px] rounded-full border-2 transition-colors',
                      { 'border-selector-border-1': !checked },
                      {
                        'border-popup-selector-active': !checked,
                      },
                      {
                        'bg-transparent': !checked,
                      }
                    )}
                  >
                    <Icon
                      name="radio"
                      className={clsx('flex items-center h-[11px]', {
                        invisible: !checked,
                      })}
                    />
                  </div>
                  <HeadlessRadio.Label as="p" className="truncate">
                    {option.name}
                  </HeadlessRadio.Label>
                </>
              )}
            </HeadlessRadio.Option>
          ))}
        </div>
      </HeadlessRadio>
    </div>
  );
};

export default Radio;
