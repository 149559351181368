import { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineUser } from 'react-icons/ai';
import { FaHistory, FaFirstdraft, FaRegNewspaper, FaSave } from 'react-icons/fa';
import { MdOutlineProductionQuantityLimits } from 'react-icons/md';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import { BrandContext } from '../../context/BrandContext';
import Icon from '../UI/Icon/Icon';
import NavButton from '../UI/NavButton/NavButton';

const Sidebar: FC = () => {
  const { brand, setBrand } = useContext(BrandContext);
  const { t } = useTranslation('common');
  const location = useLocation();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (location.pathname === '/partners' || location.pathname === '/related-offers'
      || location.pathname === '/users') {
      setBrand(undefined);
      queryClient.removeQueries(['brand'], { exact: true });
    }
  }, [location]);

  return (
    <div
      className="fixed mt-16 w-[304px] border-r-[1px] border-light py-7 px-3 flex-shrink-0 flex flex-col bg-[#f8f8f8] min-h-full z-20"
      data-testid="sidebar"
    >
      <>
        <NavButton href="/partners">
          <Icon width="24" name="brand" />
          {t('navigation.brand-partners')}
        </NavButton>
        {(brand && location.pathname !== '/users' && location.pathname !== '/user/create-user' && !location.pathname.includes('edit-users')) &&
          <>
            <NavButton className="mx-4" href={`brand/${brand?.id}`}>
              <Icon width="24" name="brand" />
              {t('navigation.brand-page')}
            </NavButton>
            <NavButton className="mx-4" href={`ads/${brand?.id}`}>
              <Icon width="24" name="ad" />
              {t('navigation.ads')}
            </NavButton>
            <NavButton className="mx-4" href={`offers-library/${brand?.id}`}>
              <Icon width="24" name="offers" />
              {t('navigation.offers-library')}
            </NavButton>
            <NavButton className="mx-4" href={`story/${brand?.id}`}>
              <FaHistory width="24" className="mr-2" />
              {t('navigation.story')}
            </NavButton>
            <NavButton className="mx-4" href={`offers-draft/${brand?.id}`}>
              <FaFirstdraft width="24" className="mr-2" />
              {t('navigation.offers-draft')}
            </NavButton>
            <NavButton className="mx-4" href={`claim-sample/${brand?.id}`}>
              <FaSave width="24" className="mr-2" />
              {t('navigation.claim-sample')}
            </NavButton>
          </>
        }
        <NavButton href="/related-offers">
          <Icon width="24" name="upload" />
          {t('navigation.related-offers-csv')}
        </NavButton>

        <NavButton href="/users">
          <AiOutlineUser width="24" className="mr-2" />
          {t('navigation.user')}
        </NavButton>

        <NavButton href="/product-sample">
          <MdOutlineProductionQuantityLimits width="24" className="mr-2" />
          {t('navigation.product-sample')}
        </NavButton>
        <NavButton href="/newsletter">
          <FaRegNewspaper width="24" className="mr-2" />
          {t('navigation.news-letter')}
        </NavButton>
        {/* {(location.pathname === '/users' || location.pathname === '/user/create-user' || location.pathname.includes('/edit-users')) &&
          <NavButton className="mx-4" href="/user/create-user">
            <AiOutlineUserAdd width="24" className="mr-2" />
            {t('navigation.create-user')}
          </NavButton>
        } */}

      </>
    </div>
  );
};

export default Sidebar;
