// import Paper from '@mui/material/Paper';
// import Table from '@mui/material/Table';
import { Pagination } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { BrandContext } from '../../context/BrandContext';

import MenuItem from '@mui/material/MenuItem';
import Button from '../../components/UI/Button/Button';
import Loader from '../../components/UI/Loader/Loader';
import useBrands from '../../services/queries/useBrands';
import { GetClaimResponse } from '../../services/queries/useClaims';
import { GetUserResponse } from '../../services/queries/useUsers';
import { getAllClaimSample, getNotifyClaimSample } from '../../services/queries/userManagement';

const GlobalProductSample: FC = () => {
    const { brandId } = useParams();
    const { data: brands, refetch, isLoading } = useBrands();
    const { setBrand } = useContext(BrandContext);
    // const { data: brand, refetch } = useBrandsById(brandId);
    const { t } = useTranslation('common');
    const nav = useNavigate();
    const [users, setUsers] = useState<GetUserResponse[]>([]);
    const [upError, setUpError] = useState<boolean>(false);
    const [page, setPage] = useState<{ currentPage: number; pageLength: number }>({
        currentPage: 1,
        pageLength: 1,
    });
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(true);
    const [brandStatus, setBrandStatus] = useState<any>();
    const [emailSent, setEmailSent] = useState<any>();
    const [isFocus, setIsFocus] = useState<any>();
    const [brandCheck, setBrandCheck] = useState<any>();

    const getData = (brandVal: string) => {
        const searchFilter = search;
        setLoading(true);
        const brandData = brandVal?.length ? brandVal : '';
        const data = {
            brandId: brandData,
            pageSize: 10,
            pageNumber: page?.currentPage ? page?.currentPage : 1,
            searchFilter,
            type: 'CLAIM'
        }

        getAllClaimSample(
            data,
            '',
            (res: any) => {
                if (res.isSuccess) {
                    setPage({
                        ...page,
                        pageLength: res?.data?.recordsCount > 0 ? Math?.ceil(res.data.recordsCount / 10) : 1
                    });
                    setUsers(res?.data?.userClaims);
                    if (res?.data?.userClaims?.length === 0) {
                        setUpError(true);
                    } else {
                        setUpError(false);
                    }
                } else {
                    setUpError(true);
                    setUsers([]);
                }
                setLoading(false);
            }
        );
    };

    useEffect(() => {
        getData(brandCheck);
    }, [page?.currentPage]);

    useEffect(() => {
        refetch();
    }, []);

    const postEmails = () => {
        // .filter((fs: any) => fs?.emailStatus !== 'SENT')

        getNotifyClaimSample(
            '',
            'notifyclaimsample',
            (res: any) => {
                if (res.isSuccess) {
                    getData(brandCheck?.length ? brandCheck : '');
                    setEmailSent('Your email sent successfully');
                } else {
                    setEmailSent(res.error.message);
                }
                setTimeout(() => {
                    setEmailSent('');
                }, 5000);
            }
        );
    }

    const pressMailEnter = () => {
        setPage({ ...page, currentPage: 1 });
        setSearch('');
        postEmails();
        // }
    };

    const pressSearchEnter = (e: any) => {

        if (e.key === 'Enter') {
            setPage({ ...page, currentPage: 1 });
            getData(brandCheck?.length ? brandCheck : '');
        }
    };

    return (
        <div
            className="flex flex-col w-full gap-4 mt-10 px-32"
            data-testid="offers-library-container"
        >
            <h2 className="text-lg font-semibold py-2">Product Sample</h2>
            <div className="py-4 w-full">
                <div className="py-4 flex flex-row justify-between w-full">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { marginRight: '15px', width: '35ch' },
                        }}
                        noValidate
                        autoComplete="on"
                    >
                        <TextField
                            id="outlined-basic"
                            label="Search by Firstname, Lastname, Brand and Email"
                            variant="outlined"
                            value={search}
                            style={{ width: "400px" }}
                            onChange={(e) => { setSearch(e.target.value) }}
                            onKeyDown={(e) => pressSearchEnter(e)}
                        />

                        <TextField
                            id="select"
                            label="Brand"
                            onChange={e => {
                                setPage({ ...page, currentPage: 1 });
                                getData(e.target.value === 'All' ? '' : e.target.value);
                                setBrandStatus(e.target.value === 'All' ? '' : e.target.value);
                            }}
                            value={brandStatus}
                            select
                        >
                            {/* <div style={{ height: '300px' }}> */}
                            <MenuItem onClick={(e: any) => {
                                setPage({ ...page, currentPage: 1 });
                                getData('');
                                setBrandCheck('');
                                setBrandStatus('All');
                            }} value="">All</MenuItem>
                            {brands?.items?.length &&
                                brands.items?.sort((a: any, b: any) => (a.displayName.localeCompare(b.displayName)))?.map((br, i) => (
                                    <MenuItem
                                        onClick={(e: any) => {
                                            setPage({ ...page, currentPage: 1 });
                                            getData(br?.id === 'All' ? '' : br?.id);
                                            setBrandCheck(br?.id === 'All' ? '' : br?.id);
                                            setBrandStatus(br?.displayName === 'All' ? '' : br?.displayName);
                                        }}
                                        key={br?.id} value={br?.id}>{br?.displayName}</MenuItem>

                                ))}
                            {/* </div> */}
                        </TextField>
                        {/* {brandStatus && <div className="pt-4">
                            <span className="font-bold pr-2">Brand: </span>
                            {brandStatus}</div>} */}
                    </Box>

                    <Button
                        type="primary"
                        onClick={() => pressMailEnter()}
                        // disabled={!mail}
                        style={{ width: '160px' }}
                    >
                        Send Email
                    </Button>
                </div>
                {emailSent && <div className={`${emailSent.includes('successfully') ? 'text-green-600' : 'text-red-600'} `}>{emailSent}</div>}
            </div>
            {
                loading ? (
                    <div style={{ height: '500px' }}>
                        <Loader />
                    </div>
                ) : (
                    users && (
                        <div className="w-full" data-testid="brand-partners-list">
                            <div
                                key={1}
                                className={clsx(
                                    'flex items-center py-6 px-5 text-white  bg-[#474747] h-[72px] last:rounded-b-md',
                                )}
                                style={{ padding: "20px" }}
                                data-testid="product-item"
                            >
                                <div style={{ width: "15%" }} className="font-semibold">Claim Sample</div>
                                <div style={{ width: "10%", paddingLeft: "20px" }} className="font-semibold">Brand</div>
                                <h2 style={{ width: "15%", paddingLeft: "20px", wordWrap: "break-word" }} className="font-semibold">User Name</h2>
                                <h2 style={{ width: "20%", paddingLeft: "20px", wordWrap: "break-word" }} className="font-semibold">Address</h2>
                                <div className="font-semibold" style={{
                                    width: "15%", paddingLeft: "20px",
                                }}>Email</div>
                                <div className="font-semibold" style={{ width: "12.5%", paddingLeft: "20px", wordWrap: "break-word" }}>Created Date</div>
                                <div className="font-semibold" style={{ width: "12.5%", paddingLeft: "20px", wordWrap: "break-word" }}>Email Date</div>
                            </div>

                            {users?.map((user: GetClaimResponse, index) => (
                                <div
                                    key={user.id}
                                    // to={`/edit-users/${user.userId}`}
                                    className={clsx(
                                        'flex items-center py-6 px-5 bg-white h-[72px] last:rounded-b-md',
                                        index > 0 && 'border-t-[1px] border-lightGrey',
                                        index === 0 && 'rounded-t-md'
                                    )}
                                    style={{ padding: "20px" }}
                                    data-testid="product-item"
                                >
                                    <div style={{ width: "15%", wordWrap: "break-word" }}>{user.claimSampleName}</div>
                                    <div style={{ width: "10%", paddingLeft: "20px", wordWrap: "break-word" }}>{user.brandName}</div>
                                    <h2 style={{ width: "15%", paddingLeft: "20px", wordWrap: "break-word" }}>{`${user.firstName} ${user.lastName}`}</h2>
                                    <div style={{ width: "20%", paddingLeft: "20px", wordWrap: "break-word" }}>{user.address}</div>
                                    <div style={{
                                        width: "15%", paddingLeft: "20px",
                                        wordWrap: "break-word"
                                    }}>{user.email}</div>
                                    <div style={{ width: "12.5%", paddingLeft: "20px", wordWrap: "break-word" }}>{user.createdDate ?
                                        user.createdDate?.split('T')?.[0] : ''}</div>
                                    <div style={{ width: "12.5%", paddingLeft: "20px", wordWrap: "break-word" }}>{user.emailSentOn ? user.emailSentOn?.split('T')?.[0] : ''} </div>
                                </div>
                            ))}

                            {users?.length ? (
                                <div
                                    key={1}
                                    className={clsx(
                                        'flex items-center py-6 px-5  text-white  bg-[#474747] h-[72px] last:rounded-b-md',
                                    )}
                                    style={{ padding: "20px" }}
                                    data-testid="product-item"
                                >
                                    <div style={{
                                        width: "15%"
                                    }} className="font-semibold">Claim Sample</div>
                                    <div style={{ width: "10%", paddingLeft: "20px" }} className="font-semibold">Brand</div>
                                    <h2 style={{ width: "15%", paddingLeft: "20px", wordWrap: "break-word" }} className="font-semibold">User Name</h2>
                                    <h2 style={{ width: "20%", paddingLeft: "20px", wordWrap: "break-word" }} className="font-semibold">Address</h2>
                                    <div className="font-semibold" style={{
                                        width: "15%", paddingLeft: "20px",
                                    }}>Email</div>
                                    <div className="font-semibold" style={{ width: "12.5%", paddingLeft: "20px", wordWrap: "break-word" }}>Created Date</div>
                                    <div className="font-semibold" style={{ width: "12.5%", paddingLeft: "20px", wordWrap: "break-word" }}>Email Date</div>
                                </div>
                            ) : ''}
                        </div>
                    )
                )
            }
            {/* new Date(user?.CreationTime).toString() */}
            {
                upError ? (
                    <div className="text-textSecondary text-center py-4 px-2"> No Record Found </div>
                ) : (
                    <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                        <Pagination
                            count={page?.pageLength}
                            page={page?.currentPage}
                            onChange={(e: any, pg) => {
                                setPage({ ...page, currentPage: pg });
                            }}
                            variant="outlined"
                            color="primary"
                        />
                    </div>
                )
            }
        </div >
    );
};

export default GlobalProductSample;
