import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { IClaimSampleSelection } from '../../../pages/Brand/Brand';
import ImageUploader from '../../ImageUploader/ImageUploader';
import Accordion from '../../UI/Accordion/Accordion';
import Button from '../../UI/Button/Button';
import Icon from '../../UI/Icon/Icon';
import Modal from '../../UI/Modal/Modal';
// import { OfferFormType } from './OfferForm';

interface Props {
    index: number;
    setBackClaimSample?: any;
    removeBrandClaim?: any;
    isBrand?: string;
    singleClaimSample?: IClaimSampleSelection
}

const CreateClaim: FC<Props> = ({ index, isBrand, setBackClaimSample, removeBrandClaim, singleClaimSample }) => {

    const [modalOpen, setModalOpen] = useState(false);
    const [defaultImg, setDefaultImg] = useState('');
    const currentDate = new Date().toISOString().split('T')[0];
    const currentTime = `${new Date()
        .getUTCHours()
        .toString()
        .padStart(2, '0')}:${new Date().getUTCMinutes().toString().padStart(2, '0')}`;

    const { brandId } = useParams();
    const { t } = useTranslation('offersLibrary');

    const {
        control,
        formState: { errors },
    } = useForm<any>();

    const { append: appendBoost } = useFieldArray({
        control,
        name: `offers.${index}.boosts`,
    });

    useEffect(() => {
        if (singleClaimSample?.file?.url?.includes('https://') && singleClaimSample?.file?.base64) {
            setDefaultImg(singleClaimSample?.file?.base64)
        } else if (singleClaimSample?.file?.url?.includes('https://') && !singleClaimSample?.file?.base64) {
            setDefaultImg(singleClaimSample?.file?.url)
        }
    }, [singleClaimSample])

    return (
        <div data-testid="create-offer-container" style={{ width: '100%' }}>
            <Accordion
                // defaultOpen
                type="offer"
                title={
                    <>
                        {/* <h1 className="w-[90px] text-left text-lg">Claim {index + 1}</h1> */}

                        {singleClaimSample?.title.length ?
                            <h2 className="w-[340px] text-left text-ellipsis" aria-label="Title">
                                {/* {getValues(`offers.${index}.title`)} */}
                                {singleClaimSample.title}
                            </h2> : null
                        }
                    </>
                }
            >
                <div className="flex flex-row flex-wrap w-full  bg-white justify-between">
                    <div style={{ width: '48%' }} className="flex flex-col bg-white rounded-md p-4 ">
                        {/* <h2 className="text-lg font-semibold">{t('claim-details')}</h2> */}
                        <label className="flex flex-col relative font-semibold">
                            {t('offer-title')}
                            <input
                                type="text"
                                maxLength={50}
                                className={clsx(
                                    'input w-full font-normal',
                                )}
                                value={singleClaimSample?.title}
                                onChange={(e) => {
                                    setBackClaimSample({ title: e.target.value });
                                }
                                }
                            />
                        </label>
                        {!singleClaimSample?.title &&
                            <p className="text-error" role="alert">
                                Please Provide Title
                            </p>
                        }

                    </div>

                    <div style={{ width: '48%' }} className="flex flex-col bg-white rounded-md p-4 ">


                        <label className="flex flex-col relative font-semibold">
                            {t('claim-description')}
                            <input
                                type="text"
                                maxLength={80}
                                className={clsx(
                                    'input w-full font-normal',
                                )}
                                value={singleClaimSample?.description}
                                onChange={(e) => {
                                    setBackClaimSample({ description: e.target.value });
                                }
                                }
                            />

                        </label>
                        {!singleClaimSample?.description &&
                            <p className="text-error " role="alert">
                                Please Provide Description
                            </p>
                        }
                    </div>

                    <div style={{ width: '48%' }} className="flex flex-col bg-white rounded-md p-4 ">

                        <div className="flex flex-row w-full gap-4">
                            <label className="w-[220px] relative">
                                <p className="font-semibold">{t('start-date')}</p>
                                <Icon
                                    name="date"
                                    width="20px"
                                    className="absolute top-[36px] left-4 z-10 pointer-events-none"
                                />
                                <input
                                    type="date"
                                    data-testid="start-date-input"
                                    min={currentDate}
                                    max={singleClaimSample?.endDate}
                                    value={singleClaimSample?.startDate}
                                    onChange={(e) => setBackClaimSample({ startDate: e.target.value })}
                                    id="input-start-date-picker"
                                    className={clsx(
                                        'input w-full pl-10'
                                    )}
                                />
                            </label>
                            <label className="w-[220px] relative">
                                <p className="font-semibold">{t('start-time')}</p>
                                <Icon
                                    name="time"
                                    width="20px"
                                    className="absolute top-[36px] left-4 z-10 pointer-events-none"
                                />
                                <input
                                    type="time"
                                    data-testid="start-time-input"
                                    min={currentTime}
                                    value={singleClaimSample?.startTime}
                                    onChange={(e) => setBackClaimSample({ startTime: e.target.value })}
                                    className={clsx(
                                        'input w-full pl-10'
                                    )}
                                />
                                <span className="absolute top-[36px] right-3 text-dark-grey-text pointer-events-none">
                                    UTC
                                </span>
                                {/* <p className="text-error mt-2" role="alert">
                                </p> */}
                            </label>
                        </div>
                        {(!singleClaimSample?.startDate || !singleClaimSample?.startTime) &&
                            <p className="text-error " role="alert">
                                Please Provide Start Date and Time
                            </p>
                        }

                    </div>

                    <div style={{ width: '48%' }} className="flex flex-col bg-white rounded-md p-4 ">

                        <div className="flex flex-row w-full gap-4">
                            <label className="w-[220px] relative">
                                <p className="font-semibold">{t('end-date')}</p>
                                <Icon
                                    name="date"
                                    width="20px"
                                    className="absolute top-[36px] left-4 z-10 pointer-events-none"
                                />
                                <input
                                    type="date"
                                    data-testid="end-date-input"
                                    min={singleClaimSample?.startDate}
                                    value={singleClaimSample?.endDate}
                                    onChange={(e) => setBackClaimSample({ endDate: e.target.value })}
                                    className={clsx(
                                        'input w-full pl-10'
                                    )}
                                />

                            </label>
                            <label className="w-[220px] relative">
                                <p className="font-semibold">{t('end-time')}</p>
                                <Icon
                                    name="time"
                                    width="20px"
                                    className="absolute top-[36px] left-4 z-10 pointer-events-none"
                                />
                                <input
                                    type="time"
                                    data-testid="end-time-input"
                                    value={singleClaimSample?.endTime}
                                    onChange={(e) => setBackClaimSample({ endTime: e.target.value })}
                                    className={clsx(
                                        'input w-full pl-10'
                                    )}

                                />
                                <span className="absolute top-[36px] right-3 text-dark-grey-text pointer-events-none">
                                    UTC
                                </span>
                            </label>
                        </div>
                        {(!singleClaimSample?.endDate || !singleClaimSample?.endTime) &&
                            <p className="text-error " role="alert">
                                Please Provide End Date and Time
                            </p>
                        }
                    </div>

                    <div className="bg-white w-full p-4">
                        <h2 className="text-lg font-semibold">{t('claim-image')}</h2>
                        <Controller
                            control={control}
                            name={`offers.${index}.imagePath`}
                            rules={{ required: 'Offer image is required.' }}
                            render={({ field: { onChange } }) => (
                                <ImageUploader
                                    subText="or drop an image to upload"
                                    criteria="Image size 500 x 560 px (.jpg .png). Maximum 5MB."
                                    widthReq={500}
                                    heightReq={560}
                                    defaultImg={defaultImg}
                                    imageUrlCB={imagePath => {
                                        onChange(imagePath);
                                        setBackClaimSample({ file: { url: imagePath } });
                                    }}
                                    isImageBase64={!singleClaimSample?.id}
                                    backImageBase64={(e: any) => {
                                        setDefaultImg(e.fullBase64)
                                        setBackClaimSample({ file: { base64: e.imgBase64, name: e.imgName, format: e.imgType, url: '' } });
                                    }}
                                    imgType="BRAND_COVER"
                                />
                            )}
                        />

                        {(!defaultImg) &&
                            <p className="text-error" role="alert">
                                Please Provide Image
                            </p>
                        }
                    </div>
                </div>




                <Button
                    type="cancel"
                    className="font-semibold self-end"
                    onClick={() => {
                        setModalOpen(true);
                    }}
                >
                    Remove Claim Sample
                </Button>
            </Accordion>

            <Modal type="small" isOpen={modalOpen} setIsOpen={setModalOpen}>
                <div className="h-full flex flex-col justify-between">
                    <div>
                        <h2 className="text-[20px] font-semibold mb-3">Remove?</h2>
                        <p>Are you sure you want to remove? You will lose any data entered</p>
                    </div>
                    <div className="flex justify-between">
                        <Button type="text" onClick={() => setModalOpen(false)}>
                            No, go back
                        </Button>
                        <Button
                            type="secondary"
                            onClick={() => {
                                removeBrandClaim()
                                setModalOpen(false);
                            }}
                        >
                            Yes, remove
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default CreateClaim;
