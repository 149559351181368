import { Combobox, Transition } from '@headlessui/react';
import { FC, Fragment, useEffect, useState } from 'react';
import { AiFillCheckCircle } from "react-icons/ai";
import { FaChevronCircleDown } from 'react-icons/fa';
import { getAllCountriesList } from '../../services/queries/userManagement';

interface Props {
  selection?: { [key: string]: any };
  backSelected?: any;
}


const DropdownSingleCountry: FC<Props> = ({ selection, backSelected }) => {
  const [people, setPeople] = useState<any>([])
  const [query, setQuery] = useState('');

  useEffect(() => {
    getAllCountriesList('', '',
      (res: any) => {
        if (res.isSuccess) {
          setPeople(JSON.parse(res.data));
        } else {
          setPeople([]);
        }
      });
  }, []);

  const filteredPeople =
    query === ''
      ? people
      : people?.filter((person: any) =>
        person?.name
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.toLowerCase().replace(/\s+/g, ''))
      )


  return (
    <div className="w-72" style={{ zIndex: 99 }}>
      <Combobox value={selection} onChange={(e) => { backSelected(e) }}>
        <div className="relative mt-1">
          <div style={{ height: '55px' }} className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none border-none sm:text-sm">
            <Combobox.Input
              className="w-full focus:outline-none border-none py-5 pl-3 pr-10 text-sm text-gray-900 focus:ring-0"
              displayValue={(person: any) => person?.name}
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <FaChevronCircleDown
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"            >
              {filteredPeople.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredPeople.map((person: any) => (
                  <Combobox.Option
                    key={person.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-primary text-white' : 'text-gray-900'
                      }`
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                            }`}
                        >
                          {person?.name}
                        </span>
                        {(selection?.name === person.name) ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-primary'
                              }`}
                          >
                            <AiFillCheckCircle
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  )
};

export default DropdownSingleCountry
