/* eslint-disable */
import { AxiosError } from 'axios';
import React from 'react';
import ReactDOM, { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { AuthContextProvider } from './context/AuthContext';
import { BrandContextProvider } from './context/BrandContext';
import { StoryContextProvider } from './context/StoryContext';
import { TopBarContextProvider } from './context/TopBarContext';
import './i18n';
import './index.css';
import reportWebVitals from './reportWebVitals';

// Setup axe accessibility plugin
if (process.env.NODE_ENV !== 'production') {
  import('@axe-core/react')
    .then(axe => {
      axe.default(React, createRoot, 1000);
    })
    .catch(() => {
      console.error('Failed to load @axe-core/react');
    });
}

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 300000, // 5 Mins
      retry: (count, error) =>
        (error as AxiosError).response?.status === 401 && count < 4,
      // Disable query retries.
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthContextProvider>
          <BrandContextProvider>
            <StoryContextProvider>
              <TopBarContextProvider>
                <App />
              </TopBarContextProvider>
            </StoryContextProvider>
          </BrandContextProvider>
        </AuthContextProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
