import { FC, useState } from 'react';
import { BrandOfferResponseOfferListItem } from '../../../api';
import Checkbox from '../../UI/Toggles/Checkbox/Checkbox';

interface Props {
  offer: BrandOfferResponseOfferListItem;
  setLinkedOffers: (value: string[]) => void;
  linkedOffers: string[];
}

const LinkOffersToAds: FC<Props> = ({ offer, setLinkedOffers, linkedOffers }) => {
  const [checked, setChecked] = useState<boolean>(() => {
    if (linkedOffers.find(item => item === offer.id)) {
      return true;
    }
    return false;
  });

  return (
    <div className="border-b flex justify-between p-4 items-center">
      <div className="flex items-center">
        <Checkbox
          type="tick"
          name="linkOfferToAdCheckbox"
          checked={checked}
          id={`${offer.id}-offerOnBrandPage`}
          onChange={() => {
            if (linkedOffers.find(item => item === offer.id)) {
              setLinkedOffers(linkedOffers.filter(item => item !== offer.id));
            } else {
              setLinkedOffers([...linkedOffers, offer.id]);
            }
            setChecked(!checked);
          }}
          className="checked:!bg-primary !border-primary"
        />
        <div className="flex flex-col">
          <h3>{offer.title}</h3>
        </div>
      </div>
    </div>
  );
};

export default LinkOffersToAds;
