import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { BrandResponse } from '../../api';
import TickleApi from '../Services';

const useBrandsById = (id: string | undefined) => {
  const getBrandsById = async () => (await TickleApi.getBrandsById(id || '')).data;

  return useQuery<BrandResponse, AxiosResponse>(['brand'], getBrandsById, {});
};

export default useBrandsById;
