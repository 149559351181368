import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { BrandOfferResponse } from '../../api';
import TickleApi from '../Services';

const useBrandOffersFiltered = (
  brandId: string | undefined,
  startBy: string | undefined,
  validUntil: string | undefined
) => {
  const getBrands = async () =>
    (await TickleApi.getBrandOffers(brandId || '', startBy, validUntil)).data;
  return useQuery<BrandOfferResponse, AxiosError>(
    ['brandOffersFiltered', brandId],
    getBrands,
    {
      enabled: startBy !== undefined && validUntil !== undefined,
    }
  );
};

export default useBrandOffersFiltered;
