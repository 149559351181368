import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ImageUploader from '../../components/ImageUploader/ImageUploader';
import StickyFooter from '../../components/StickyFooter/StickyFooter';
import Icon from '../../components/UI/Icon/Icon';
import VideoUploader from '../../components/VideoUploader/VideoUploader';
import { BrandContext } from '../../context/BrandContext';
import { StoryContext } from '../../context/StoryContext';
import useBrandsById from '../../services/queries/useBrandById';
import { postBrandStory, updateBrandStory } from '../../services/queries/userManagement';

interface Props {
  setBackClaimSample?: any;
}

const CreateStory: FC<Props> = ({ setBackClaimSample }) => {
  const { story } = useContext(StoryContext);
  const navigate = useNavigate();
  const { brandId } = useParams();
  const { setBrand } = useContext(BrandContext);
  const [storyType, setStoryType] = useState<string>(story ? story?.storyType : 'image');
  const { data: brand, refetch: getBrandById } = useBrandsById(brandId);
  const [title, setTitle] = useState<string | any>(story ? story?.title : '');
  // const [shopNowURL, setShopNowURL] = useState<string | any>(story ? story?.shopNowURL : '');
  const [disableBtn, setDisableBtn] = useState<boolean>(false);
  const [storyError, setStoryError] = useState<string>('');
  const [imageType, setImageType] = useState<string>(story ? story?.mediaType : '');
  const [mediaType, setMediaType] = useState<string | any>(
    story ? story?.storyUrlPath : ''
  );

  useEffect(() => {
    getBrandById().then(res => setBrand(res.data));
  }, []);

  const onSubmitAdd = () => {
    if (story) {
      const data = {
        title,
        storyType,
        // shopNowURL,
        // eslint-disable-next-line
        mediaType: storyType === 'video' ? 'mp4' : imageType ? imageType : 'jpeg',
        storyUrlPath: mediaType?.includes('.com')
          ? `brand/${mediaType?.split('brand/')?.[1]}`
          : mediaType,
      };

      // if (/^(ftp|http|https):\/\/[^ "]+$/.test(shopNowURL)) {
        updateBrandStory(data, `/brand/${brandId}/story/${story.id}`, (res: any) => {
          if (res.isSuccess) {
            navigate(`/story/${brandId}`);
          } else {
            setTimeout(() => {
              setStoryError('');
            }, 5000);
            setStoryError(res.error.message);
          }
        });
      // } else {
      //   setStoryError("Please enter correct URL");
      // }
    } else {
      const data = {
        stories: [
          {
            title,
            // shopNowURL,
            storyType,
            // eslint-disable-next-line
            mediaType: storyType === 'video' ? 'mp4' : imageType ? imageType : 'jpeg',
            storyUrlPath: mediaType,
          },
        ],
      };
      // if (/^(ftp|http|https):\/\/[^ "]+$/.test(shopNowURL)) {
        postBrandStory(data, `/brand/${brandId}/story`, (res: any) => {
          if (res.isSuccess) {
            navigate(`/story/${brandId}`);
          } else {
            setTimeout(() => {
              setStoryError('');
            }, 5000);
            setStoryError(res.error.message);
          }
        });
      // } else {
      //   setStoryError("Please enter correct URL");
      // }

    }
  };

  return (
    <div className="wrapper mb-40 items-start">
      <div className="flex justify-start ">
        <button
          type="button"
          onClick={() => navigate(-1)}
          aria-label="go back"
          data-testid="back-button"
        >
          <Icon name="back-arrow" width="24" />
        </button>
        <h1 className="font-semibold">{story ? 'Update' : 'Create'} Story</h1>
      </div>

      <div className="flex flex-col bg-white rounded-md p-5 w-[1000px]">
        <h3 className="font-semibold my-3 ">Title</h3>
        <input
          type="text"
          defaultValue=""
          value={title}
          className="input w-full"
          onChange={(res: any) => {
            setTitle(res.target.value);
          }}
          maxLength={30}
        // {...field}
        />

        {/* <h3 className="font-semibold my-3 ">Shop Now URL</h3>
        <input
          type="text"
          defaultValue=""
          value={shopNowURL}
          className="input w-full"
          onChange={(res: any) => {
            setShopNowURL(res.target.value);
          }}
          maxLength={30}
        // {...field}
        /> */}

        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">
            <div className="font-semibold pt-4">Story Types</div>
          </FormLabel>
          <RadioGroup
            row
            defaultValue={story ? story?.storyType : 'image'}
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel
              value="image"
              control={
                <Radio
                  disabled={storyType === 'video' && mediaType}
                  onChange={e => {
                    setStoryType(e.target.value);
                    setMediaType('');
                  }}
                />
              }
              label="Pic"
            />
            <FormControlLabel
              value="video"
              control={
                <Radio
                  disabled={storyType === 'image' && mediaType}
                  onChange={e => {
                    setStoryType(e.target.value);
                    setMediaType('');
                  }}
                />
              }
              label="Video"
            />
          </RadioGroup>
        </FormControl>

        {mediaType ? (
          <div>(Please Remove uploaded media to change the story type)</div>
        ) : null}

        {storyType === 'image' ? (
          <ImageUploader
            subText="or drop an image to upload"
            criteria="Image size 1080 x 1920 px (.jpg .jpeg .png). Maximum 5MB."
            widthReq={1080}
            heightReq={1920}
            defaultImg={mediaType?.includes('http') ? mediaType : ''}
            imageUrlCB={(res: any) => {
              setMediaType(res);
            }}
            backImageBase64={(e: any) => setImageType(e?.imgType?.split('/')?.[1])}
            imgType="BRAND_STORY_IMAGE"
          />
        ) : (
          <VideoUploader
            subText="or drop a Video to upload"
            criteria="Video (.mp4) size Maximum 30MB."
            widthReq={1080}
            heightReq={1920}
            defaultImg={mediaType?.includes('http') ? mediaType : ''}
            imageUrlCB={(res: any) => {
              setMediaType(res);
            }}
            backUpload={(flag: any) => setDisableBtn(flag)}
            imgType="BRAND_STORY_MEDIA"
          />
        )}
      </div>

      <StickyFooter
        save={() => onSubmitAdd()}
        saveLoading={disableBtn}
        brandError={storyError}
        discard={() => navigate(`/story/${brandId}`)}
      />
    </div>
  );
};

export default CreateStory;
