import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import { ImageUploadResponse } from '../../api';
import { axiosInstance } from '../Services';

export interface ImageUploadRequest {
  type: 'BRAND_LOGO' | 'BRAND_COVER' | 'AD_IMAGE' | 'OFFER_IMAGE' | 'BRAND_CLAIM_IMAGE' | 'BRAND_DOWNLOAD_IMAGE' | 'BRAND_STORY_IMAGE' | 'BRAND_STORY_MEDIA' | 'APP_DOWNLOAD_IMAGE';
  image: File;
}

const usePostUploadImage = (id: string | undefined) => {
  const postUploadImage = async (parameters: ImageUploadRequest) => {
    const { image, type } = parameters;
    const bodyFormData = new FormData();
    bodyFormData.append('image', image);

    return (
      await axiosInstance.post<ImageUploadResponse>('/image', bodyFormData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: { brandId: id, type },
      })
    ).data;
  };

  return useMutation<ImageUploadResponse, AxiosResponse, ImageUploadRequest>(
    ['brand-image', id],
    parameters => postUploadImage(parameters)
  );
};

export default usePostUploadImage;
