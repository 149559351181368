import { Disclosure, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { FC } from 'react';
import Icon from '../Icon/Icon';

interface Props {
  title: React.ReactNode;
  children: React.ReactNode;
  type: 'advert' | 'offer' | 'boost';
  defaultOpen?: boolean;
}

const Accordion: FC<Props> = ({ children, title, type, defaultOpen = false }) => {
  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <div >
          <Disclosure.Button
            className={clsx(
              'w-full min-h-16 flex items-center p-4 justify-between duration-500 ease-in-out transition-all bg-primary text-white',
              // type === 'advert' && 'bg-accentBlue',
              // type === 'offer' && 'bg-lightBlue',
              // type === 'boost' && 'bg-white',
              open ? 'rounded-t-xl' : 'rounded-xl'
            )}
          >
            {title}
            <Icon
              name="bottom-chevron"
              width="24"
              className={clsx(
                'transition-all text-white ease-in-out duration-500',
                open ? 'rotate-180' : 'rotate-0'
              )}
            />
          </Disclosure.Button>
          <Transition
            enter="transition duration-300 ease-in-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-300 ease-in-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel
              className={clsx(
                'flex flex-col gap-5 w-full bg-lightGrey rounded-b-xl',
                type === 'boost' ? 'p-0' : 'p-5'
              )}
            >
              {children}
            </Disclosure.Panel>
          </Transition>
        </div>
      )}
    </Disclosure>
  );
};

export default Accordion;
