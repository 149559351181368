import adsEn from './en/ads.json';
import authEn from './en/auth.json';
import boostsEn from './en/boosts.json';
import brandEn from './en/brand.json';
import commonEn from './en/common.json';
import offersLibraryEn from './en/offersLibrary.json';
import relatedOffersEn from './en/relatedOffers.json';
import usersEn from './en/user.json';

export const defaultNS = 'common';
export const resources = {
  en: {
    common: commonEn,
    users: usersEn,
    auth: authEn,
    brand: brandEn,
    relatedOffers: relatedOffersEn,
    ads: adsEn,
    offersLibrary: offersLibraryEn,
    boosts: boostsEn,
  },
};
