import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useBrandsById from '../../../services/queries/useBrandById';
import {
  getAnAdvertRequest,
  getCategoryRequest,
} from '../../../services/queries/userManagement';
import DropdownMultiSelect from '../../DropdownMultiselect/DropdownMultiselect';
import { AdFormSchema } from './AdFormTypes';

interface AdFormDetailsProps {
  adIsActive: boolean;
  getValues?: any;
  categoryBackArr?: any;
  categoryChildBackArr?: any;
  changeCategorySelection?: any;
  categoriesCheckArr?: any;
}

const AdFormDetails = ({
  adIsActive,
  getValues,
  categoryBackArr,
  categoryChildBackArr,
  changeCategorySelection,
  categoriesCheckArr
}: AdFormDetailsProps) => {
  const { t } = useTranslation('ads');
  const [updatedCategory, setUpdatedCategory] = useState([]);
  const [updatedChildCategory, setUpdatedChildCategory] = useState<any>();
  const [addDetail, setAddDetail] = useState<any>();
  const { brandId, adId } = useParams();
  const { data: brand, refetch, isLoading } = useBrandsById(brandId);
  const [inputTextValue, setInputTextValue] = useState<string>();

  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<AdFormSchema>();

  const titleWatcher = useWatch({ control, name: 'title' });

  const getCategoryData = () => {
    getCategoryRequest('', `category`, (res: any) => {
      if (res.isSuccess) {
        setUpdatedCategory(res.data.parentCategories);
        setUpdatedChildCategory([...res.data.parentCategories, ...res.data.childCategories]);
      } else {
        setUpdatedCategory([]); setUpdatedChildCategory([]);
      }
    });
  };

  const getAdvertData = () => {
    getAnAdvertRequest('', `brand/${brandId}/ad/${adId}`, (res: any) => {
      if (res.isSuccess) {
        setAddDetail(res?.data);
      }
    });
  };

  console.log("categoriesCheckArr", categoriesCheckArr)

  useEffect(() => {
    getCategoryData();
    getAdvertData();
    // if (brand && brand.followedCategories)
    // setCategories(brand.followedCategories.map(item => item.name!));
  }, []);

  // updatedChildCategory?.filter((is: any) => categoriesCheckArr?.map((it: any) => it?.id)?.includes(is?.parentId)) : []

  return (
    <div className="flex flex-col bg-white rounded-md h-fit p-4 gap-3">
      <h2 className="text-lg font-semibold">{t('form.ad-details')}</h2>

      <div className="flex flex-row justify-between flex-wrap w-full">
        <div style={{ width: '48%' }} className="relative">
          <p className="font-semibold">{t('form.title')}</p>
          <div className="w-full">
            <input
              type="text"
              data-testid="title-input"
              maxLength={50}
              style={{ width: '96%' }}
              className={clsx('input', errors.title && 'border-error')}
              {...register('title', {
                required: 'Title is required.',
                minLength: {
                  value: 4,
                  message: 'Title must be at least 4 characters.',
                },
                maxLength: 50,
              })}
            />
          </div>

          <p className="absolute top-17 right-0 text-md text-[#666666]">
            {titleWatcher?.length}/50
          </p>
          <p className="text-error mt-2" role="alert">
            {errors.title?.message}
          </p>
        </div>

        <div style={{ width: '48%' }}>
          <p className="font-semibold">{t('form.google-ad-id')}</p>
          <input
            type="text"
            data-testid="id-input"
            style={{ width: '100%' }}
            // disabled={adIsActive}
            className={clsx('input', errors?.googleAdId && 'border-error')}
            {...register('googleAdId', {
              required: 'Ad ID is required.',
              minLength: {
                value: 4,
                message: 'Google Ad ID must be at least 4 characters.',
              },
              maxLength: 60,
            })}
          />
          <p className="text-error mt-2" role="alert">
            {errors?.googleAdId?.message}
          </p>
        </div>
      </div>

      <p className="font-semibold">Category</p>
      <DropdownMultiSelect
        people={updatedCategory?.length ? updatedCategory : brand?.parentCategories}
        categorySelected={
          addDetail?.followedParentCategories?.length ? addDetail?.followedParentCategories : []
        }
        inputTextValue={(val: any) => setInputTextValue(val)}
        changeCategorySelection={(flag: any) => changeCategorySelection(flag)}
        backPeople={(item: any) => categoryBackArr(item)}
      />

      <DropdownMultiSelect
        people={updatedChildCategory?.length ? updatedChildCategory?.filter((is: any) => categoriesCheckArr?.map((it: any) => it?.id)?.includes(is?.parentId)) : []}
        categorySelected={
          addDetail?.followedChildCategories?.length ? addDetail?.followedChildCategories : []
        }
        isSubcategory
        inputTextValue={(val: any) => console.log(val)}
        changeCategorySelection={(flag: any) => changeCategorySelection(flag)}
        backPeople={(item: any) => categoryChildBackArr(item)}
      />

      {inputTextValue && <div className="text-red-500">{inputTextValue}</div>}
    </div>
  );
};

export default AdFormDetails;
