import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import TickleApi from '../Services';

const useNotifyBrand = () => {
  const notifyBrand = async (brandId: string | undefined) =>
    (await TickleApi.notifyNewBrand(brandId || '')).data;

  return useMutation<any, AxiosResponse, string>((brandId: string) =>
    notifyBrand(brandId)
  );
};

export default useNotifyBrand;
