import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import Button from '../UI/Button/Button';

interface Props {
  ads?: number;
  offers?: number;
  boosts?: number;
  ctaText: string;
  isDraft?: boolean;
  ctaAction: () => void;
}

const AdsSummary: FC<Props> = ({ ads, offers, boosts, ctaAction, ctaText, isDraft }) => {
  const location = useLocation();
  return (
    <div
      className="w-[1000px] rounded-xl h-[150px] bg-white px-12 py-8 flex justify-between items-center"
      data-testid="offers-library-summary"
    >
      <div className="flex gap-16">
        {!isDraft ?
          <>
            {!location.pathname.includes('offers-library') && (
              <div className="flex flex-col">
                <p className="text-[54px] leading-tight">{ads || 0}</p>
                <p className="text-textSecondary">Ads</p>
              </div>
            )}


            <div className="flex flex-col">
              <p className="text-[54px] leading-tight" data-testid="offers-count">
                {offers}
              </p>
              <p className="text-textSecondary">Offers</p>
            </div>

            <div className="flex flex-col">
              <p className="text-[54px] leading-tight" data-testid="boosts-count">
                {boosts}
              </p>
              <p className="text-textSecondary">Boosts</p>
            </div>
          </> : null
        }
      </div>
      <Button type="secondary" onClick={ctaAction}>
        {ctaText}
      </Button>
    </div >
  );
};

export default AdsSummary;
