import { useMutation, useQueryClient } from 'react-query';
import { BrandOfferResponse } from '../../api';
import TickleApi from '../Services';

const useOfferShowOnMutation = () => {
  const queryClient = useQueryClient();

  const editOfferShowOn = ({
    brandId,
    offerId,
    showOn,
  }: {
    brandId: string;
    offerId: string;
    showOn?: 'BRAND_PAGE';
  }) =>
    TickleApi.editOfferShowOn(brandId, offerId, {
      showOn,
    }).then(res => res.data);

  return useMutation('updateOfferShowOn', editOfferShowOn, {
    onSuccess: (_, { brandId, offerId, showOn }) => {
      // Invalidate the getOfferById  and brandById queries so that they will be refetched
      queryClient.invalidateQueries(['offer', offerId]);
      queryClient.invalidateQueries(['brand']);

      // Update the cached brandOffers query to reflect the new shownOnValue
      queryClient.setQueryData<BrandOfferResponse>(
        ['brandOffers', brandId],
        offersResponse => {
          if (offersResponse === undefined) {
            return { items: [], totals: { boosts: 0, offers: 0 } };
          }

          const updatedOffers = offersResponse.items.map(offer => {
            if (offer.id === offerId) return { ...offer, shownOn: showOn || '' };
            return offer;
          });

          return { ...offersResponse, items: updatedOffers };
        }
      );
    },
  });
};

export default useOfferShowOnMutation;
