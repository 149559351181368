import { FC, useContext, useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CodeUploadResponse, OfferResponse } from '../../api';
import OfferForm, { OfferFormType } from '../../components/Forms/OfferForm/OfferForm';

import Icon from '../../components/UI/Icon/Icon';
import { BrandContext } from '../../context/BrandContext';
import useBrandsById from '../../services/queries/useBrandById';
import useCreateOffer from '../../services/queries/useCreateOffer';
import usePostUploadCsv from '../../services/queries/usePostUploadCsv';

const CreateOffer: FC = () => {
  const { setBrand } = useContext(BrandContext);
  const location = useLocation();
  const [countryList, setCountryList] = useState<any>([]);
  const [categoriesChildArr, setCategoriesChildArr] = useState<any>([]);
  const [categoryList, setCategoryList] = useState<any>([]);
  const { brandId } = useParams();
  const nav = useNavigate();
  const { mutateAsync: uploadCsvCodes, isLoading: isUploadCsvLoading } =
    usePostUploadCsv(brandId);

  const { mutate: createOffer } = useCreateOffer(brandId);
  const [offerError, setOfferError] = useState<any>('');

  const { data: brand, refetch: getBrandById } = useBrandsById(brandId);

  useEffect(() => {
    getBrandById().then(res => setBrand(res.data));
  }, []);

  useEffect(() => {
    document.title = brand ? brand?.displayName : 'Tickle';

    return () => {
      document.title = 'Tickle';
    };
  }, [brandId]);

  const getUploadCSVPromises = (
    formOffer: OfferFormType,
    responseOffer: OfferResponse
  ) => {
    const csvPromises: Array<Promise<CodeUploadResponse>> = [];
    const requestTextCheck = `${formOffer.title}${formOffer.subtitle}`;
    const responseTextCheck = `${responseOffer.title}${responseOffer.subtitle}`;
    const matchedOfferId =
      requestTextCheck === responseTextCheck && formOffer.id
        ? formOffer.id
        : responseOffer.id;
    if (formOffer.csvFile) {
      csvPromises.push(
        uploadCsvCodes({
          offerId: matchedOfferId,
          csv: formOffer.csvFile,
          mode: 'APPEND',
        })
      );
    }
    formOffer.boosts?.forEach(boost => {
      if (boost.csvFile) {
        csvPromises.push(
          uploadCsvCodes({
            offerId: matchedOfferId || '',
            boostId: boost.id,
            csv: boost.csvFile,
            mode: 'APPEND',
          })
        );
      }
    });

    return csvPromises;
  };

  const onSubmitOffer: SubmitHandler<OfferFormType> = async data => {
    if (!countryList?.length) {
      setOfferError("Please select atleast one country");
    }
    else if (!categoryList?.length) {
      setOfferError("Please select atleast one category");
    }
    // else if (!categoriesChildArr?.length) {
    //   setOfferError("Please select atleast one subcategory");
    // } 
    else {
      createOffer(
        {
          brandId,
          title: data.title,
          subtitle: data.subtitle,
          countries: countryList ?? [],
          categories: [...categoryList, ...categoriesChildArr],
          description: data.description,
          imagePath: data.imagePath,
          redemptionType: data.redemptionType,
          offerStatus: data.offerStatus,
          redemptionUrl: data.redemptionUrl,
          startTime: `${data.beginDate}T${data.beginTime}:00Z`,
          expiryTime: `${data.endDate}T${data.endTime}:00Z`,
          boosts: data.boosts?.map(boost => ({
            id: boost.id,
            title: boost.title,
            subtitle: boost.subtitle,
            type: boost.type,
            description: boost.description,
            imagePath: boost.imagePath,
            redemptionType: boost.redemptionType,
            redemptionUrl: boost.redemptionUrl,
            boostAvailable: true,
            halo: boost.halo,
          })),
        },
        {
          onSuccess: response => {
            if (response && data) {
              Promise.all(getUploadCSVPromises(data, response))
                .then(() => {
                  if (location.pathname.includes('offers-draft')) {
                    nav(`/offers-draft/${brandId}`, { replace: true });
                  } else {
                    nav(`/offers-library/${brandId}`, { replace: true });
                  }
                })
                .catch(error => {
                  console.log(error);
                });
            }
            setOfferError('');
          },
          onError: (err: any) => {
            setOfferError(err?.response?.data?.message);
          },
        },
      );
    }
  };

  // if (createOfferLoading) {
  //   return (
  //     <div className="wrapper">
  //       <Loader />
  //     </div>
  //   );
  // }

  return (
    <div className="wrapper mb-40" data-testid="create-offer-page-container">
      <div className="w-full flex flex-col gap-6">
        <div className="flex gap-2">
          <button
            type="button"
            onClick={() => nav(-1)}
            aria-label="go back"
            data-testid="back-button"
          >
            <Icon name="back-arrow" width="24" />
          </button>
          <h1 className="font-semibold">Create Offer</h1>
        </div>
        <OfferForm
          onSubmit={onSubmitOffer}
          isDisabled={false}
          backCountry={(e: any) => setCountryList(e?.map((es: any) => es.code))}
          backCategory={(e: any) => setCategoryList(e)}
          categoriesCheckArr={categoryList}
          categoryChildBackArr={(item: any) => setCategoriesChildArr(item)}
          offerError={offerError}
          isSubmitting={isUploadCsvLoading}
        />
      </div>
    </div>
  );
};

export default CreateOffer;
