import clsx from 'clsx';
import { useContext, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import ClaimSampleContainer from './components/Forms/ClaimForm/ClaimFormContainer';
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import { AuthContext } from './context/AuthContext';
import { TopBarContext } from './context/TopBarContext';
import Ads from './pages/Ads/Ads';
import Brand from './pages/Brand/Brand';
import BrandPartners from './pages/BrandPartners/BrandPartners';
import CreateAd from './pages/CreateAd/CreateAd';
import CreateOffer from './pages/CreateOffer/CreateOffer';
import CreateStory from './pages/CreateStory/CreateStory';
import CreateUser from './pages/CreateUser/CreateUser';
import EditAd from './pages/EditAd/EditAd';
import EditOffer from './pages/EditOffer/EditOffer';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import GlobalNewsletter from './pages/GlobalNewsletter/GlobalNewsletter';
import GlobalProductSample from './pages/GlobalProductSample/GlobalProductSample';
import ListUser from './pages/ListUser/ListUser';
import Login from './pages/Login/Login';
import OffersDraft from './pages/OffersDraft/OffersDraft';
import OffersLibrary from './pages/OffersLibrary/OffersLibrary';
import RelatedOffers from './pages/RelatedOffers/RelatedOffers';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Story from './pages/Story/Story';

// Icons

const routes = (
  <Routes>
    <Route path="/create-user" element={<CreateUser />} />

    <Route path="/list-users" element={<ListUser />} />
    <Route path="/edit-users/:id" element={<CreateUser />} />

    {/* <Route path="/ads/:brandId/create" element={<CreateAd />} /> */}
    <Route path="/partners" element={<BrandPartners />} />
    <Route path="/login" element={<Login />} />
    <Route path="/forgot-password" element={<ForgotPassword />} />
    <Route path="/reset-password" element={<ResetPassword />} />
    <Route path="/related-offers" element={<RelatedOffers />} />
    <Route path="/brand/:brandId" element={<Brand />} />
    <Route path="/brand/create" element={<Brand />} />
    <Route path="/offers-library/:brandId" element={<OffersLibrary />} />
    <Route path="/offers-draft/:brandId" element={<OffersDraft />} />
    <Route path="/claim-sample/:brandId" element={<ClaimSampleContainer />} />
    <Route path="/story/:brandId" element={<Story />} />
    <Route path="/story/:brandId/create" element={<CreateStory />} />
    <Route path="/story/:brandId/edit/:storyId" element={<CreateStory />} />
    <Route path="/ads/:brandId" element={<Ads />} />
    <Route path="/ads/:brandId/create" element={<CreateAd />} />
    <Route path="/ads/:brandId/edit/:adId" element={<EditAd />} />
    <Route path="/offers-library/:brandId/create" element={<CreateOffer />} />
    <Route path="/offers-draft/:brandId/create" element={<CreateOffer />} />
    <Route path="/offers-library/:brandId/edit/:offerId" element={<EditOffer />} />
    <Route path="/offers-draft/:brandId/edit/:offerId" element={<EditOffer />} />
    <Route path="/product-sample" element={<GlobalProductSample />} />
    <Route path="/newsletter" element={<GlobalNewsletter />} />
    <Route path="/user/create-user" element={<CreateUser />} />
    <Route path="/users" element={<ListUser />} />
    <Route path="/edit-users/:id" element={<CreateUser />} />
  </Routes>
);
// /ads/:brandId/edit/:adId
// /offers-library/:brandId/edit/:offerId

const App = () => {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  const { topBar, setTopBar } = useContext(TopBarContext);

  useEffect(() => {
    if (
      !user &&
      location.pathname !== '/forgot-password' &&
      location.pathname !== '/reset-password'
    ) {
      navigate('/login');
    }
  }, [location.pathname, navigate, user]);

  useEffect(() => {
    let arr = [];
    arr = topBar?.length ? topBar : [];

    if (topBar?.[topBar.length - 1] === location.pathname) {
      setTopBar([...arr])
    }
    else {
      setTopBar([...arr, location.pathname])
    }
  }, [location.pathname]);

  const breadcrumbsRecord = () => {
    topBar?.pop();
    const secondLastPath = topBar ? topBar[topBar.length - 1] : location.pathname;
    navigate(secondLastPath)
  };

  const showHeader = (): boolean => {
    if (
      location.pathname === '/login' ||
      location.pathname === '/forgot-password' ||
      (location.pathname === '/reset-password' && location.pathname === '/reset-password')
    ) {
      return false;
    }
    return true;
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <main>
      {showHeader() && <Header />}
      <div className="bg-background flex w-full">
        {showHeader() && <Sidebar />}
        <div
          className={clsx(
            'w-full',
            showHeader()
              ? 'mt-[68px] ml-[304px] min-h-[calc(100vh-68px)]'
              : 'min-h-screen'
          )}
        >
          {showHeader() &&
            <div className="text-lg ml-4 mt-2">
              {/* {location.pathname && capitalizeFirstLetter(location.pathname.substring(1).split('/').join('  ->  '))} */}
              {/* <BiLeftArrowCicle onClick={()=>breadcrumbsRecord()} className="mx-4 text-xl cursor-pointer" /> */}
            </div>
          }
          {routes}
        </div>
      </div>
    </main>
  );
};

export default App;
