import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import { AddBrandRequest, BrandResponse } from '../../api';
import TickleApi from '../Services';

const useCreateBrand = () => {
  const createBrand = async (brand: AddBrandRequest) =>
    (await TickleApi.createBrand(brand)).data;

  return useMutation<BrandResponse, AxiosResponse, AddBrandRequest>(['brand'], brand =>
    createBrand(brand)
  );
};

export default useCreateBrand;
