// import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
// import Table from '@mui/material/Table';
import { Pagination } from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { BrandContext } from '../../context/BrandContext';

import Button from '../../components/UI/Button/Button';
import Loader from '../../components/UI/Loader/Loader';
import useUsers from '../../services/queries/useUser';
import { GetUserResponse } from '../../services/queries/useUsers';
import { getAllUsersRequest } from '../../services/queries/userManagement';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(
  firstName: string,
  lastName: string,
  emailAddress: string,
  username: string,
  status: string
) {
  return { firstName, lastName, emailAddress, username, status };
}

const ListUser: FC = () => {
  const { brandId } = useParams();
  const { data: userList, refetch, isLoading } = useUsers();
  const { setBrand } = useContext(BrandContext);
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  // const { data: brand } = useBrandsById(brandId);
  const [users, setUsers] = useState<GetUserResponse[]>([]);
  const [upError, setUpError] = useState<boolean>(false);
  const [page, setPage] = useState<{ currentPage: number; pageLength: number }>({
    currentPage: 1,
    pageLength: 1,
  });
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(true);

  const getData = (statusProp: any) => {
    const searchFilter = search ? `&keyword=${encodeURIComponent(search)}` : '';
    const statusFilter = statusProp ? `&status=${statusProp}` : '';
    setLoading(true);

    getAllUsersRequest(
      '',
      `&page=${page?.currentPage}${searchFilter}${statusFilter}&limit=15`,
      (res: any) => {
        if (res.isSuccess) {
          setUsers(res?.data?.data);
          setPage({
            currentPage: res?.data?.pagination?.current,
            pageLength: res?.data?.pagination?.last,
          });
          if (res?.data?.data?.length === 0) {
            setUpError(true);
          } else {
            setUpError(false);
          }
        } else {
          setUpError(true);
        }
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    getData(status);
  }, [page?.currentPage]);

  const pressEnter = (e: any) => {
    if (e.key === 'Enter') {
      setPage({ ...page, currentPage: 1 });
      getData(status);
      setSearch('');
    }
  };

  return (
    <div
      className="flex flex-col w-full gap-4 mt-10 px-32"
      data-testid="offers-library-container"
    >
      <h2 className="text-lg font-semibold py-2">Users</h2>
      <div className="py-4 flex flex-row justify-between w-full">
        <Box
          component="form"
          sx={{
            '& > :not(style)': { marginRight: '15px', width: '35ch' },
          }}
          noValidate
          autoComplete="on"
        >
          <TextField
            id="outlined-basic"
            onChange={e => setSearch(e.target.value)}
            value={search}
            onKeyDown={e => pressEnter(e)}
            label="Search by email or name(s)"
            variant="outlined"
          />
          <TextField
            id="select"
            label="Status"
            onChange={e => {
              setPage({ ...page, currentPage: 1 });
              getData(e.target.value);
              setStatus(e.target.value === 'All' ? '' : e.target.value);
            }}
            value={status}
            select
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="Blocked">Blocked</MenuItem>
          </TextField>
        </Box>
        <Button
          type="primary"
          onClick={() => {
            navigate(`${window.location.pathname}/../user/create-user`);
          }}
        >
          {t('navigation.create-user')}
        </Button>
      </div>
      {loading ? (
        <div style={{ height: '500px' }}>
          <Loader />
        </div>
      ) : (
        users && (
          <div className="w-full" data-testid="brand-partners-list">
            <table className="w-full rounded-[100px]">
              <thead className="font-medium text-white  bg-[#474747] rounded-[100px]">
                <tr>
                  <th className="w-48 flex justify-start font-semibold p-5">Name</th>
                  <th>
                    <h3 className="font-semibold  flex justify-start  p-5">Email</h3>
                  </th>
                  <th>
                    <h3 className="font-semibold  flex justify-start  p-5">Username</h3>
                  </th>
                  <th>
                    <h3 className="font-semibold  flex justify-start p-5">Status</h3>
                  </th>
                </tr>
              </thead>
              <tbody className="first:border-none p-4">
                {users?.map((user: GetUserResponse, index) => (
                  <tr
                    key={user.Email}
                    onClick={() => navigate(`/edit-users/${user.id}`)}
                    className={clsx(
                      'w-full py-6 px-5 cursor-pointer bg-white h-[72px] hover:bg-lightBlue last:rounded-b-md',
                      index > 0 && 'border-t-[1px] border-lightGrey',
                      index === 0 && 'rounded-t-md'
                    )}
                    data-testid="brand-item"
                  >
                    <td>
                      <h2 className="font-semibold w-1/3  p-5">{`${user.FirstName} ${user.Surname}`}</h2>
                    </td>
                    <td className=" p-5">
                      {user.Email}
                    </td>
                    <td className=" p-5">
                      {user.Username}
                    </td>
                    <td className=" p-5">
                      {user.Status}
                    </td>
                    {/* <div className="flex justify-between items-center w-full">
                      <div className="text-md font-light">
                        <div>&#8729;Email: {user.Email}</div>
                        {user.Username && <div>&#8729;Username: {user.Username} </div>}
                        {user.Status && <div>&#8729;Status: {user.Status} </div>}
                      </div>
                      <Icon name="chevron" width="24px" height="24px" />
                    </div> */}
                  </tr>
                ))}
              </tbody>

              <tfoot className="font-medium text-white  bg-[#474747] rounded-[100px]">
                <tr>
                  <th className="w-48 flex justify-start font-semibold  p-5">Name</th>
                  <th>
                    <h3 className="font-semibold  flex justify-start  p-5">Email</h3>
                  </th>
                  <th>
                    <h3 className="font-semibold  flex justify-start  p-5">Username</h3>
                  </th>
                  <th>
                    <h3 className="font-semibold  flex justify-start p-5">Status</h3>
                  </th>
                </tr>
              </tfoot>
            </table>


          </div>
        )
      )}
      {upError ? (
        <div className="text-textSecondary text-center py-4 px-2"> No Record Found </div>
      ) : (
        <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
          <Pagination
            count={page?.pageLength}
            page={page?.currentPage}
            onChange={(e: any, pg) => {
              setPage({ ...page, currentPage: pg });
            }}
            variant="outlined"
            color="primary"
          />
        </div>
      )}
    </div>
  );
};

export default ListUser;
