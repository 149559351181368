import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import { useEffect } from 'react';

type ToastProps = {
  /** An id to attach to the toast message */
  id?: string;
  variant: 'info' | 'warning' | 'error';
  /** The message to display in the toast */
  message: string;
  /** Controls whether or not to show the toast */
  open: boolean;
  /** The duration in ms to show the toast before triggering the onClose callback. Default is 3000. */
  hideDuration?: number;
  /** Triggers when the toast should be closed. */
  onClose: () => void;
};

const Toast = ({
  id,
  variant,
  message,
  open,
  hideDuration = 3000,
  onClose,
}: ToastProps) => {
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        onClose();
      }, hideDuration);
    }
  }, [open, onClose, hideDuration]);

  return (
    <Transition
      className="fixed inset-x-0 bottom-6 z-50 p-4 w-screen sm:bottom-8 lg:bottom-12"
      show={open}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
      appear
    >
      <div
        id={id}
        role="alert"
        aria-label="alert"
        className={clsx('p-4 mx-auto w-fit rounded-lg', {
          'bg-lightBlue': variant === 'info',
          'bg-warning': variant === 'warning',
          'bg-error text-white font-bold': variant === 'error',
        })}
      >
        {message}
      </div>
    </Transition>
  );
};

export default Toast;
