import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import { CodeUploadResponse } from '../../api';
import { axiosInstance } from '../Services';

export interface CodeUploadRequest {
  offerId: string;
  mode: 'APPEND' | 'OVERWRITE' | 'CLEAR';
  boostId?: string;
  csv: File;
}

const usePostUploadCsv = (id: string | undefined) => {
  // /offer/{offerId}/codes?mode=APPEND
  const postUploadCsv = async (parameters: CodeUploadRequest) => {
    const { offerId, mode, boostId, csv } = parameters;
    const bodyFormData = new FormData();
    bodyFormData.append('csv', csv);
    return (
      await axiosInstance.post<CodeUploadResponse>(
        `/offer/${offerId}/codes`,
        bodyFormData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          params: { brandId: id, offerId, mode, boostId },
        }
      )
    ).data;
  };

  return useMutation<CodeUploadResponse, AxiosResponse, CodeUploadRequest>(
    ['brand-csv', id],
    parameters => postUploadCsv(parameters)
  );
};

export default usePostUploadCsv;
