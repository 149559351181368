import { useMutation, useQueryClient } from 'react-query';
import TickleApi from '../Services';

const useEditWelcomeOfferMutation = () => {
  const queryClient = useQueryClient();

  const editWelcomeOffer = ({ offerId }: { offerId: string }) =>
    TickleApi.setWelcomeOffer(offerId).then(res => res.data);

  return useMutation('updateWelcomeOffer', editWelcomeOffer, {
    onSuccess: () => {
      // Update the cached welcomeoffer query to reflect the new welcome offer
      queryClient.invalidateQueries(['welcomeoffer']);
    },
  });
};

export default useEditWelcomeOfferMutation;
