import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CodeUploadResponse, OfferResponse } from '../../api';
import OfferForm, { OfferFormType } from '../../components/Forms/OfferForm/OfferForm';
import Icon from '../../components/UI/Icon/Icon';
import Loader from '../../components/UI/Loader/Loader';
import { BrandContext } from '../../context/BrandContext';
import useBrandsById from '../../services/queries/useBrandById';
import useEditOffer from '../../services/queries/useEditOffer';
import useOfferById from '../../services/queries/useOfferById';
import usePostUploadCsv from '../../services/queries/usePostUploadCsv';

const EditOffer: FC = () => {
  const [offerIsActive, setOfferIsActive] = useState(false);
  const [offerLoader, setOfferLoader] = useState(true);
  const [offerError, setOfferError] = useState<any>('');
  const [countryList, setCountryList] = useState<any>([]);
  const [categoriesChildArr, setCategoriesChildArr] = useState<any>([]);
  const [categoryList, setCategoryList] = useState<any>([]);
  const location = useLocation();

  const { setBrand } = useContext(BrandContext);
  const { brandId, offerId } = useParams();
  const nav = useNavigate();

  const { mutate: editOffer, isLoading: editOfferLoading } = useEditOffer(
    brandId,
    offerId
  );
  const { data: offer, isLoading: offerLoading } = useOfferById(brandId, offerId);
  const { data: brand, refetch: getBrandById } = useBrandsById(brandId);
  const { mutateAsync: uploadCsvCodes, isLoading: isUploadCsvLoading } =
    usePostUploadCsv(brandId);

  useEffect(() => {
    if (offer) {
      const now = new Date();
      const start = offer.startTime ? new Date(offer.startTime) : now;
      if (start < now) {
        setOfferIsActive(true);
      }
    }

    setTimeout(() => {
      setOfferLoader(false);
    }, 2000)
  }, [offer]);

  const initialFormData = useMemo(() => {
    if (offer === undefined) return undefined;
    const data: OfferFormType = {
      id: offer.id,
      brandId: offer.brandId,
      title: offer.title,
      countries: offer.countries,
      followedCategories: offer.followedCategories,
      followedParentCategories: offer.followedParentCategories,
      followedChildCategories: offer.followedChildCategories,
      beginDate: offer.startTime?.split('T')[0] || '',
      beginTime: offer.startTime?.split('T')[1].slice(0, -4) || '',
      endDate: offer.expiryTime?.split('T')[0] || '',
      endTime: offer.expiryTime?.split('T')[1].slice(0, -4) || '',
      imagePath: offer.imagePath,
      imageUrl: offer.imageUrl,
      description: offer.description,
      subtitle: offer.subtitle,
      redemptionType: offer.redemptionType,
      offerStatus: offer.offerStatus,
      redemptionUrl: offer.redemptionUrl,
      // @ts-ignore
      boosts: offer.boosts,
      offerCodes: offer.offerCodes,
    };

    return data;
  }, [offer]);

  useEffect(() => {
    getBrandById().then(res => setBrand(res.data));
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = brand ? brand?.displayName : 'Tickle';

    return () => {
      document.title = 'Tickle';
    };
  }, [brandId]);

  const getUploadCSVPromises = (
    formOffer: OfferFormType,
    responseOffer: OfferResponse
  ) => {
    const csvPromises: Array<Promise<CodeUploadResponse>> = [];
    const requestTextCheck = `${formOffer.title}${formOffer.subtitle}`;
    const responseTextCheck = `${responseOffer.title}${responseOffer.subtitle}`;
    const matchedOfferId =
      requestTextCheck === responseTextCheck && formOffer.id
        ? formOffer.id
        : responseOffer.id;
    if (formOffer.csvFile) {
      csvPromises.push(
        uploadCsvCodes({
          offerId: matchedOfferId,
          csv: formOffer.csvFile,
          mode: 'APPEND',
        })
      );
    }
    formOffer.boosts?.forEach(boost => {
      if (boost.csvFile) {
        csvPromises.push(
          uploadCsvCodes({
            offerId: matchedOfferId || '',
            boostId: boost.id,
            csv: boost.csvFile,
            mode: 'APPEND',
          })
        );
      }
    });

    return csvPromises;
  };

  console.log("countryList 01", countryList);


  const onSubmit: SubmitHandler<OfferFormType> = async data => {
    console.log("countryList 02", countryList);

    if (!countryList?.length) {
      setOfferError("Please select atleast one country");
    }
    else if (!categoryList?.length) {
      setOfferError("Please select atleast one category");
    }
    // else if (!categoriesChildArr?.length) {
    //   setOfferError("Please select atleast one subcategory");
    // } 
    else {
      editOffer(
        {
          brandId,
          title: data.title,
          subtitle: data.subtitle,
          countries: countryList ?? [],
          categories: [...categoryList, ...categoriesChildArr],
          description: data.description,
          imagePath: data.imagePath,
          redemptionType: data.redemptionType,
          offerStatus: data.offerStatus,
          redemptionUrl: data.redemptionUrl,
          startTime: `${data.beginDate}T${data.beginTime}:00Z`,
          expiryTime: `${data.endDate}T${data.endTime}:00Z`,
          boosts: data.boosts?.map(boost => ({
            id: boost.id,
            title: boost.title,
            subtitle: boost.subtitle,
            type: boost.type,
            description: boost.description,
            imagePath: boost.imagePath,
            redemptionType: boost.redemptionType,
            redemptionUrl: boost.redemptionUrl,
            boostAvailable: true,
            halo: boost.halo,
          })),
        },
        {
          onSuccess: response => {
            Promise.all(getUploadCSVPromises(data, response))
              .then(() => {
                if (location.pathname.includes('offers-draft')) {
                  nav(`/offers-draft/${brandId}`, { replace: true });
                } else {
                  nav(`/offers-library/${brandId}`, { replace: true });
                }
              })
              .catch(error => {
                console.log(error);
              });
          },
        }
      );
    }
  };

  if (offerLoader) {
    return (
      <div className="wrapper">
        <Loader />
      </div>
    );
  }

  return (
    <div className="wrapper mb-40" >
      <div className="w-full ">
        <div className="flex gap-2">
          <button
            type="button"
            onClick={() => nav(-1)}
            aria-label="go back"
            data-testid="back-button"
          >
            <Icon name="back-arrow" width="24" />
          </button>
          <h1 className="font-semibold">Update offer</h1>
        </div>
        <OfferForm
          isDisabled={offerIsActive}
          onSubmit={onSubmit}
          offerError={offerError}
          backCountry={(e: any) => setCountryList(e?.map((es: any) => es.code))}
          backCategory={(e: any) => setCategoryList(e)}
          categoriesCheckArr={categoryList}
          categoryChildBackArr={(item: any) => setCategoriesChildArr(item)}
          isSubmitting={editOfferLoading || isUploadCsvLoading}
          initialData={initialFormData}
        />
      </div>
    </div>
  );


};

export default EditOffer;