import { FC, useEffect, useState } from 'react';
import { BrandOfferResponseOfferListItem, OfferResponse } from '../../../api';
import useBrandOffersFiltered from '../../../services/queries/useBrandOfferFiltered';
import useOffersById from '../../../services/queries/useOffersById';
import LinkOffersToAds from '../../ListViews/LinkOffersToAds/LinkOffersToAds';
import Button from '../../UI/Button/Button';
import Icon from '../../UI/Icon/Icon';
import Loader from '../../UI/Loader/Loader';
import Modal from '../../UI/Modal/Modal';
import { OfferFormType } from '../OfferForm/OfferForm';

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  brandId: string | undefined;
  onLinkOffers: (offersAdded: OfferResponse[]) => void;
  startTime: string | undefined;
  endTime: string | undefined;
  offersLinkedToAd: OfferFormType[];
}

const LinkOffersModal: FC<Props> = ({
  open,
  setOpen,
  brandId,
  onLinkOffers,
  offersLinkedToAd,
  startTime,
  endTime,
}) => {
  const {
    data: offers,
    isFetching: isBrandOffersFetching,
    refetch: refetchBrandOffers,
  } = useBrandOffersFiltered(brandId, startTime, endTime);

  const [selectedOfferIds, setSelectedOfferIds] = useState<string[]>([]);
  const [confirmButtonPressed, setConfirmButtonPressed] = useState(false);
  const { offers: selectedOffersDetails, isLoading: isFetchingDetailedOffers } =
    useOffersById(brandId, selectedOfferIds);
  const [brandOffers, setBrandOffers] = useState<BrandOfferResponseOfferListItem[]>();

  const onLinkOffersConfirm = () => {
    if (isFetchingDetailedOffers) {
      setConfirmButtonPressed(true);
    } else {
      setConfirmButtonPressed(false);
      if (
        selectedOffersDetails.every(
          (offer): offer is OfferResponse => offer !== undefined
        )
      ) {
        onLinkOffers(selectedOffersDetails);
        setOpen(false);
      }
    }
  };
  useEffect(() => {
    setSelectedOfferIds([]);
  }, [open]);

  useEffect(() => {
    if (
      confirmButtonPressed &&
      selectedOffersDetails.every((offer): offer is OfferResponse => offer !== undefined)
    ) {
      onLinkOffersConfirm();
    }
  }, [selectedOffersDetails]);

  useEffect(() => {
    if (open) {
      refetchBrandOffers();
      setBrandOffers(
        offers?.items.filter(
          offer => !offersLinkedToAd.find(item => item.id === offer.id)
        )
      );
    }
  }, [open, startTime, endTime]);

  return (
    <Modal type="large" isOpen={open} setIsOpen={setOpen} height="max-h-[900px]">
      <div className="h-full flex flex-col justify-between">
        <div>
          <div className="flex w-full justify-between mb-9">
            <h2 className="text-lg font-semibold">Link an Offer</h2>
            <button type="button" onClick={() => setOpen(false)}>
              <Icon name="cross" />
            </button>
          </div>
          <p className="text-base">Choose the Offers you want to link to this Ad.</p>
          <div className="flex gap-3 text-dark-grey-text items-center">
            <Icon name="info" />
            <p className="text-md">
              Only Offers within the schedule time will be available to choose from.
            </p>
          </div>
          {isBrandOffersFetching ? (
            <Loader />
          ) : (
            brandOffers &&
            (brandOffers.length > 0 ? (
              <div className="border-t-2 mt-10 mb-5 max-h-[500px] overflow-y-auto">
                {brandOffers.map(offer => {
                  return (
                    <div key={offer.id} className="bg-background hover:bg-lightBlue">
                      <LinkOffersToAds
                        offer={offer}
                        setLinkedOffers={setSelectedOfferIds}
                        linkedOffers={selectedOfferIds}
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="flex justify-center p-16">
                <p className="text-dark-grey-text">There are no offers available</p>
              </div>
            ))
          )}
        </div>
        <div className="flex justify-between">
          <Button
            className=" "
            type="outline"
            onClick={() => {
              setOpen(false);
              setSelectedOfferIds([]);
            }}
          >
            Cancel
          </Button>
          <Button
            type="secondary"
            loading={confirmButtonPressed && isFetchingDetailedOffers}
            disabled={selectedOfferIds.length < 1}
            onClick={() => {
              onLinkOffersConfirm();
            }}
          >
            Add
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default LinkOffersModal;
