import React, { createContext, Dispatch, SetStateAction, useMemo, useState } from 'react';

interface Props {
    children: React.ReactNode;
  }

  export interface TopBarInterface {
    topBar?: string[];
    setTopBar: Dispatch<SetStateAction<string[] | undefined>>;
  }

export const TopBarContext = createContext<TopBarInterface>({} as TopBarInterface);

export const TopBarContextProvider: React.FC<Props> = ({ children }) => {
    const [topBar, setTopBar] = useState<TopBarInterface['topBar']>();
  
    const TopBarContextValue = useMemo(
      () => ({
        topBar,
        setTopBar,
      }),
      [topBar, setTopBar]
    );
    return (
      <TopBarContext.Provider value={TopBarContextValue}>{children}</TopBarContext.Provider>
    );
  };