import axios from 'axios';
import {
  AddEditAdvertRequest as AddEditAdvertRequestApi,
  AddEditOfferRequest,
  Configuration,
  DefaultApi
} from '../api';
import { auth } from '../firebase';

const configuration = new Configuration({
  basePath: process.env.REACT_APP_BASE_URL,
});
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

export const axiosInstance = axios.create();

const refreshToken = localStorage.getItem('refreshToken');

const authResponseIntercepter = axiosInstance.interceptors.response.use(
  res => res,
  async error => {
    if (axios.isAxiosError(error) && error.response) {
      if (error.response.status === 401) {
        window.location.replace(`/login`);
        return Promise.reject(error);
      }
      if (error.response.status !== 401) {

        return Promise.reject(error);
      }
      axios.interceptors.response.eject(authResponseIntercepter);
      if (refreshToken && auth.currentUser) {
        auth.currentUser.getIdToken().then(accessToken => {
          localStorage.setItem('accessToken', accessToken);
        });
      }
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.request.use(config => {
  const token = localStorage.getItem('accessToken');
  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const TickleApi = new DefaultApi(configuration, undefined, axiosInstance);

// TODO remove this extension of AddEditAdvertRequest once this change is made in the backend
export interface AddEditAdvertRequest extends Omit<AddEditAdvertRequestApi, 'offers'> {
  offers: AddEditOfferRequest[];
}

export default TickleApi;
