import { Pagination } from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../components/UI/Button/Button';
import Loader from '../../components/UI/Loader/Loader';
import Checkbox from '../../components/UI/Toggles/Checkbox/Checkbox';
import { getAllBrandsRequest, getCategoryRequest } from '../../services/queries/userManagement';

const BrandPartners: FC = () => {
  const { brandId } = useParams();
  const { t } = useTranslation('brand');
  // const { data: brands, refetch, isLoading } = useBrands();
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(true);
  const [brands, setBrands] = useState<any>([]);
  const [category, setCategory] = useState<any>([]);
  const [upError, setUpError] = useState<boolean>(false);
  const [checkMissingLogo, setCheckMissingLogo] = useState<boolean>(false);
  const [page, setPage] = useState<{ currentPage: number; pageLength: number }>({
    currentPage: 1,
    pageLength: 1,
  });
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getData = (statusProp: any, missingLogo: boolean) => {
    const searchFilter = search ? `&searchFilter=${encodeURIComponent(search)}` : '';
    const statusFilter = statusProp
      ? `&categoryFilter=${encodeURIComponent(statusProp)}`
      : '';
    const missingLogoFilter = `&missingLogos=${encodeURIComponent(missingLogo)}`;
    setLoading(true);

    getAllBrandsRequest(
      '',
      `&page=${page.currentPage}${searchFilter}${statusFilter}${missingLogoFilter}&pageSize=15&sort=asc`,
      (res: any) => {
        if (res.isSuccess) {
          setBrands(res?.data);
          setPage({
            currentPage: page.currentPage > 0 ? page.currentPage : 1,
            pageLength: res?.data?.totalCount
              ? Math.floor(Number(res?.data?.totalCount) / 15)
              : 1,
          });

          if (res?.data?.items?.length === 0) {
            setUpError(true);
          } else {
            setUpError(false);
          }
        } else {
          setUpError(true);
        }
        setLoading(false);
      }
    );
  };

  const getCategory = () => {
    getCategoryRequest('', 'category', (res: any) => {
      if (res.isSuccess) {
        setCategory(res?.data?.items);
      }
    });
  };

  useEffect(() => {
    getCategory();
  }, []);

  useEffect(() => {
    if (page?.currentPage) {
      getData(status, checkMissingLogo);
    }
  }, [page?.currentPage]);

  const pressEnter = (e: any) => {
    if (e.key === 'Enter') {
      setPage({ ...page, currentPage: 1 });
      getData(status, checkMissingLogo);
      setSearch('');
    }
  };

  return (
    <div className="wrapper" data-testid="brand-partners-container">
      <h1 className="text-[28px] font-semibold mb-6 self-start">{t('brand-partners')}</h1>
      <div className="py-4 flex flex-row justify-between w-full">
        <Box
          component="form"
          sx={{
            '& > :not(style)': { marginRight: '15px', width: '35ch' },
          }}
          noValidate
          autoComplete="on"
        >
          <TextField
            id="outlined-basic"
            onChange={e => setSearch(e.target.value)}
            value={search}
            onKeyDown={e => pressEnter(e)}
            label="Search by Brand name"
            variant="outlined"
          />
          <TextField
            id="select"
            label="Category"
            onChange={e => {
              setPage({ ...page, currentPage: 1 });
              getData(e.target.value, checkMissingLogo);
              setStatus(e.target.value);
            }}
            value={status}
            select
          >
            <MenuItem value="">All</MenuItem>
            {category?.length
              ? category
                ?.sort((a: any, b: any) => a.name.localeCompare(b.name))
                ?.map((cat: any, ind: number) => (
                  <MenuItem key={ind} value={cat?.name}>
                    {cat?.name}
                  </MenuItem>
                ))
              : 'No Record Found'}
          </TextField>

          <div className=" mt-4">
            <Checkbox
              type="tick"
              name="setCheckMissingLogo"
              checked={checkMissingLogo}
              id="001"
              label="Missing Logo"
              onChange={(res: any) => { setCheckMissingLogo(!checkMissingLogo); getData(status, !checkMissingLogo) }}
              className="checked:!bg-primary !border-primary"
            />
          </div>

        </Box>
        <Button
          type="primary"
          onClick={() => {
            navigate(`${window.location.pathname}/../brand/create`);
          }}
        >
          {t('create-brand')}
        </Button>
      </div>

      {loading ? (
        <Loader />
      ) : (
        brands &&
        brands.items && (
          <div className="w-full pb-8" data-testid="brand-partners-list">
            <table className="w-full rounded-[100px]">
              <thead className="font-semibold text-white  bg-[#474747] rounded-[100px]">
                <tr>
                  <th className="w-48 flex justify-start font-semibold p-5">Name</th>
                  <th>
                    <h3 className="font-semibold flex justify-start  p-5">Adverts</h3>
                  </th>
                  <th>
                    <h3 className="font-semibold flex justify-start p-5">Offers</h3>
                  </th>
                </tr>
              </thead>
              <tbody className="first:border-none p-4">
                {brands.items
                  ?.sort((a: any, b: any) => a.displayName.localeCompare(b.displayName))
                  .map((brand: any, index: number) => (
                    <tr
                      key={brand.id}
                      className={clsx(
                        'w-full py-6 cursor-pointer px-5 bg-white h-[72px] hover:bg-lightBlue last:rounded-b-md',
                        index > 0 && 'border-t-[1px] border-lightGrey',
                        index === 0 && 'rounded-t-md'
                      )}
                      onClick={() => navigate(`/brand/${brand.id}`)}
                    >
                      <td
                        style={{ width: '40%' }}
                        className="p-5"
                        data-testid="brand-item"
                      >
                        <h2 className="font-semibold w-1/3">{brand.displayName}</h2>
                      </td>

                      <td
                        style={{ width: '30%' }}
                        className="p-5"
                        data-testid="brand-item"
                      >
                        <p className="text-md font-light">
                          {brand.totalAdverts} {t('active-adverts')}
                        </p>
                      </td>
                      <td
                        style={{ width: '30%' }}
                        className="p-5"
                        data-testid="brand-item"
                      >
                        <p className="text-md font-light">
                          {brand.totalOffers} {t('active-offers')}
                        </p>
                      </td>
                      {/* <div className="flex justify-between items-center w-full">
                        <p className="text-md font-light">
                          {brand.totalAdverts} {t('active-adverts')} &#8729; {brand.totalOffers}{' '}
                          {t('active-offers')}
                        </p>
                        <Icon name="chevron" width="24px" height="24px" />
                      </div> */}
                    </tr>
                  ))}
              </tbody>

              <tfoot className="font-medium text-white  bg-[#474747] rounded-[100px]">
                <tr>
                  <th className="w-48 flex justify-start font-semibold  p-5">Name</th>
                  <th>
                    <h3 className="font-semibold  flex justify-start  p-5">Adverts</h3>
                  </th>
                  <th>
                    <h3 className="font-semibold  flex justify-start p-5">Offers</h3>
                  </th>
                </tr>
              </tfoot>
            </table>

            {upError ? (
              <div className="text-textSecondary text-center py-4 px-2">
                {' '}
                No Record Found{' '}
              </div>
            ) : (
              <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                <Pagination
                  count={page?.pageLength}
                  page={Number(page?.currentPage)}
                  onChange={(e: any, pg) => {
                    setPage({ ...page, currentPage: pg });
                  }}
                  variant="outlined"
                  color="primary"
                />
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default BrandPartners;
