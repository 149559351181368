// import ApiService from "../ApiService";
import { getRequest, postLoginRequest, postRequest, postWithoutAuthRequest, putRequest } from '../ApiService';
import ApiUrls from '../ApiUrls';

// class Request{
export const getAllBrandsRequest = async (data: any, urlParams: any, callback: any) => {
  try {
    const response = await getRequest(ApiUrls.listBrand + urlParams, data);
    const errMsg: any = response.data;
    if (response.status === 200 || response.status === 201) {
      callback({ data: response.data, isSuccess: true });
    } else {
      callback({ isSuccess: false, error: errMsg });
    }
  } catch (error) {
    callback('Error Sign', error);
  }
};

export const getAllUsersRequest = async (data: any, urlParams: any, callback: any) => {
  try {
    const response = await getRequest(ApiUrls.listUser + urlParams, data);
    const errMsg: any = response.data;
    if (response.status === 200 || response.status === 201) {
      callback({ data: response.data, isSuccess: true });
    } else {
      callback({ isSuccess: false, error: errMsg });
    }
  } catch (error) {
    callback('Error Sign', error);
  }
};

export const getAUserRequest = async (data: any, urlParams: any, callback: any) => {
  try {
    const response = await getRequest(ApiUrls.updateGetUser + urlParams, data);
    const errMsg: any = response.data;
    if (response.status === 200 || response.status === 201) {
      callback({ data: response.data, isSuccess: true });
    } else {
      callback({ isSuccess: false, error: errMsg });
    }
  } catch (error) {
    callback('Error Sign', error);
  }
};

export const createUserRequest = async (data: any, urlParams: any, callback: any) => {
  try {
    const response = await postRequest(ApiUrls.createUser + urlParams, data);
    const errMsg: any = response.data;
    if (response.status === 200 || response.status === 201) {
      callback({ data: response.data, isSuccess: true });
    } else {
      callback({ isSuccess: false, error: errMsg });
    }
  } catch (error) {
    callback('Error', error);
  }
};

export const loginAdminRequest = async (data: any, urlParams: any, callback: any) => {
  try {
    const response = await postLoginRequest(ApiUrls.loginAdmin + urlParams, data);
    const errMsg: any = response.data;
    if (response.status === 200 || response.status === 201) {
      callback({ data: response.data, isSuccess: true });
    } else {
      callback({ isSuccess: false, error: errMsg });
    }
  } catch (error) {
    callback('Error', error);
  }
};

export const getLoggedInUserRequest = async (data: any, urlParams: any, callback: any) => {
  try {
    const response = await postWithoutAuthRequest(ApiUrls.loginAdminUser + urlParams, data);
    const errMsg: any = response.data;
    if (response.status === 200 || response.status === 201) {
      callback({ data: response.data, isSuccess: true });
    } else {
      callback({ isSuccess: false, error: errMsg });
    }
  } catch (error) {
    callback('Error Sign', error);
  }
};

export const deleteCategoryRequest = async (data: any, urlParams: any, callback: any) => {
  try {
    const response = await putRequest(ApiUrls.deleteCategory + urlParams, data);
    const errMsg: any = response.data;
    if (response.status === 200 || response.status === 201) {
      callback({ data: response.data, isSuccess: true });
    } else {
      callback({ isSuccess: false, error: errMsg });
    }
  } catch (error) {
    callback('Error', error);
  }
};

export const createCategoryRequest = async (data: any, urlParams: any, callback: any) => {
  try {
    const response = await postRequest(ApiUrls.createCategory + urlParams, data);
    const errMsg: any = response.data;
    if (response.status === 200 || response.status === 201) {
      callback({ data: response.data, isSuccess: true });
    } else {
      callback({ isSuccess: false, error: errMsg });
    }
  } catch (error) {
    callback('Error', error);
  }
};

export const updateUserRequest = async (data: any, urlParams: any, callback: any) => {
  try {
    const response = await putRequest(ApiUrls.updateGetUser + urlParams, data);
    const errMsg: any = response.data;
    if (response.status === 200 || response.status === 201) {
      callback({ data: response.data, isSuccess: true });
    } else {
      callback({ isSuccess: false, error: errMsg });
    }
  } catch (error) {
    callback('Error', error);
  }
};

export const getAnAdvertRequest = async (data: any, urlParams: any, callback: any) => {
  try {
    const response = await getRequest(urlParams, data);
    const errMsg: any = response.data;
    if (response.status === 200 || response.status === 201) {
      callback({ data: response.data, isSuccess: true });
    } else {
      callback({ isSuccess: false, error: errMsg });
    }
  } catch (error) {
    callback('Error Sign', error);
  }
};

export const getCategoryRequest = async (data: any, urlParams: any, callback: any) => {
  try {
    const response = await getRequest(urlParams, data);
    const errMsg: any = response.data;
    if (response.status === 200 || response.status === 201) {
      callback({ data: response.data, isSuccess: true });
    } else {
      callback({ isSuccess: false, error: errMsg });
    }
  } catch (error) {
    callback('Error Sign', error);
  }
};

export const getAllBrandClaims = async (data: any, urlParams: any, callback: any) => {
  try {
    const response = await getRequest(urlParams, data);
    const errMsg: any = response.data;
    if (response.status === 200 || response.status === 201) {
      callback({ data: response.data, isSuccess: true });
    } else {
      callback({ isSuccess: false, error: errMsg });
    }
  } catch (error) {
    callback('Error Sign', error);
  }
};

export const getAllClaimSample = async (data: any, urlParams: any, callback: any) => {
  try {
    const response = await postRequest(ApiUrls.claimNewLetter + urlParams, data);
    const errMsg: any = response.data;
    if (response.status === 200 || response.status === 201) {
      callback({ data: response.data, isSuccess: true });
    } else {
      callback({ isSuccess: false, error: errMsg });
    }
  } catch (error) {
    callback('Error', error);
  }
};

export const getNotifyClaimSample = async (data: any, urlParams: any, callback: any) => {
  try {
    const response = await getRequest(urlParams, data);
    const errMsg: any = response.data;
    if (response.status === 200 || response.status === 201) {
      callback({ data: response.data, isSuccess: true });
    } else {
      callback({ isSuccess: false, error: errMsg });
    }
  } catch (error) {
    callback('Error', error);
  }
};

export const getNotifyNewsletter = async (data: any, urlParams: any, callback: any) => {
  try {
    const response = await getRequest(urlParams, data);
    const errMsg: any = response.data;
    if (response.status === 200 || response.status === 201) {
      callback({ data: response.data, isSuccess: true });
    } else {
      callback({ isSuccess: false, error: errMsg });
    }
  } catch (error) {
    callback('Error', error);
  }
};

export const postNotifyBrandOfficials = async (data: any, urlParams: any, callback: any) => {
  try {
    const response = await postRequest(ApiUrls.notifyBrandOfficial + urlParams, data);
    const errMsg: any = response.data;
    if (response.status === 200 || response.status === 201) {
      callback({ data: response.data, isSuccess: true });
    } else {
      callback({ isSuccess: false, error: errMsg });
    }
  } catch (error) {
    callback('Error', error);
  }
};

export const getBrandStory = async (data: any, urlParams: any, callback: any) => {
  try {
    const response = await getRequest(urlParams, data);
    const errMsg: any = response.data;
    if (response.status === 200 || response.status === 201) {
      callback({ data: response.data, isSuccess: true });
    } else {
      callback({ isSuccess: false, error: errMsg });
    }
  } catch (error) {
    callback('Error', error);
  }
};

export const postBrandStory = async (data: any, urlParams: any, callback: any) => {
  try {
    const response = await postRequest(urlParams, data);
    const errMsg: any = response.data;
    if (response.status === 200 || response.status === 201) {
      callback({ data: response.data, isSuccess: true });
    } else {
      callback({ isSuccess: false, error: errMsg });
    }
  } catch (error) {
    callback('Error', error);
  }
};

export const updateBrandStory = async (data: any, urlParams: any, callback: any) => {
  try {
    const response = await putRequest(urlParams, data);
    const errMsg: any = response.data;
    if (response.status === 200 || response.status === 201) {
      callback({ data: response.data, isSuccess: true });
    } else {
      callback({ isSuccess: false, error: errMsg });
    }
  } catch (error) {
    callback('Error', error);
  }
};

export const deleteBrandStory = async (data: any, urlParams: any, callback: any) => {
  try {
    const response = await putRequest(urlParams, data);
    const errMsg: any = response.data;
    if (response.status === 200 || response.status === 201) {
      callback({ data: response.data, isSuccess: true });
    } else {
      callback({ isSuccess: false, error: errMsg });
    }
  } catch (error) {
    callback('Error', error);
  }
};

export const deleteBrandRequest = async (data: any, urlParams: any, callback: any) => {
  try {
    const response = await putRequest(ApiUrls.deleteBrand + urlParams, data);
    const errMsg: any = response.data;
    if (response.status === 200 || response.status === 201) {
      callback({ data: response.data, isSuccess: true });
    } else {
      callback({ isSuccess: false, error: errMsg });
    }
  } catch (error) {
    callback('Error', error);
  }
};

export const postUrlAd = async (data: any, urlParams: any, callback: any) => {
  try {
    const response = await postRequest(ApiUrls.UrlAdvert + urlParams, data);
    const errMsg: any = response.data;
    if (response.status === 200 || response.status === 201) {
      callback({ data: response.data, isSuccess: true });
    } else {
      callback({ isSuccess: false, error: errMsg });
    }
  } catch (error) {
    callback('Error Sign', error);
  }
};

export const getAllCountriesList = async (data: any, urlParams: any, callback: any) => {
  try {
    const response = await getRequest(ApiUrls.listCountry + urlParams, data);
    const errMsg: any = response.data;
    if (response.status === 200 || response.status === 201) {
      callback({ data: response.data, isSuccess: true });
    } else {
      callback({ isSuccess: false, error: errMsg });
    }
  } catch (error) {
    callback('Error Sign', error);
  }
};


// }

// const UserManagementRequest = new Request();

// export default UserManagementRequest;
