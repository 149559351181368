import React, { createContext, Dispatch, SetStateAction, useMemo, useState } from 'react';

interface Props {
    children: React.ReactNode;
}

export interface BrandInterface {
    story?: any;
    setStory: Dispatch<SetStateAction<any | undefined>>;
}

export const StoryContext = createContext<any>({} as any);

export const StoryContextProvider: React.FC<Props> = ({ children }) => {
    const [story, setStory] = useState<any>(undefined);

    const StoryContextValue = useMemo(
        () => ({
            story,
            setStory,
        }),
        [story, setStory]
    );
    return (
        <StoryContext.Provider value={StoryContextValue}>{children}</StoryContext.Provider>
    );
};
