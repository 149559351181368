import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: 'tickle-mvp.appspot.com',
  messagingSenderId: '961172494387',
  appId: process.env.REACT_APP_GOOGLE_APP_ID,
  measurementId: 'G-F3ZW79ZJ98',
  tenantId: process.env.REACT_APP_TENANT_ID,
};

// Initialize Firebase
// eslint-disable-next-line import/prefer-default-export
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
auth.tenantId = process.env.REACT_APP_TENANT_ID;
