import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import { BrandResponse, EditBrandRequest } from '../../api';
import TickleApi from '../Services';

const useEditBrand = (id: string | undefined) => {
  const editBrand = async (newBrand: EditBrandRequest) =>
    (await TickleApi.editBrand(id || '', newBrand)).data;

  return useMutation<BrandResponse, AxiosResponse, EditBrandRequest>(
    ['brand', id],
    newBrand => editBrand(newBrand)
  );
};

export default useEditBrand;
