import clsx from 'clsx';
import Papa from 'papaparse';
import { FC, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { ErrorOption } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OfferAttributeCounts } from '../../api';
import usePostUploadCsvAffiliates from '../../services/queries/usePostUploadCsvAffiliates';
import Button from '../UI/Button/Button';
import Icon from '../UI/Icon/Icon';
import Loader from '../UI/Loader/Loader';
import Modal from '../UI/Modal/Modal';

interface Props {
  type: 'CODES' | 'AFFILIATES';
  boostId?: string;
  setCsvCodes?: (file: File) => void;
  offerRedemptionCounts?: OfferAttributeCounts;
  editMode?: boolean;
  setOfferError?: (error: ErrorOption) => void;
}
const FileUpload: FC<Props> = ({
  type,
  setCsvCodes,
  offerRedemptionCounts,
  setOfferError,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState<FileRejection | undefined>(undefined);
  const [seccussfulOffers, setSeccussfulOffers] = useState(0);
  const [defaultFile, setDefaultFile] = useState<OfferAttributeCounts | undefined>(
    offerRedemptionCounts
  );
  const [uploadError, setUploadError] = useState<string | undefined>();
  const [file, setFile] = useState<
    { file: File; parsedData?: Papa.ParseResult<unknown> } | undefined
  >(undefined);
  const { t } = useTranslation('relatedOffers');

  const { mutate: postUploadCsvAffiliateOffers, isLoading } =
    usePostUploadCsvAffiliates();

  const handleFileOpen = async (files: File[]) => {
    if (files[0]) {
      const csv = files[0];
      const text = await csv.text();
      const parsedFile = Papa.parse(text, { skipEmptyLines: true });
      setFile({ file: csv, parsedData: parsedFile });

      if (type === 'CODES' && setCsvCodes) {
        setCsvCodes(csv);
      }

      if (type === 'AFFILIATES') {
        postUploadCsvAffiliateOffers(
          { save: false, csv },
          {
            onSuccess: response => {
              setSeccussfulOffers(response.successfullyProcessedTotal);
              console.log(response.failedRows);
            },
            onError: err => {
              setUploadError(err.data);
            },
          }
        );
      }
    }
  };
  const handleFileUpload = () => {
    if (file && type === 'AFFILIATES') {
      postUploadCsvAffiliateOffers(
        { save: true, csv: file?.file },
        {
          onSuccess: response => {
            setFile(undefined);
            console.log(response.failedRows);
          },
          onError: err => {
            setUploadError(err.data);
          },
        }
      );
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      'text/csv': ['.csv'],
    },
    maxFiles: 1,
    onDropAccepted: files => handleFileOpen(files),
    onDropRejected: rejection => {
      setOfferError?.({ type: 'custom', message: rejection[0].errors[0].message });
      setFile({ file: rejection[0].file });
      setError(rejection[0]);
    },
  });

  const renderFilePreview = () => {
    if (type === 'CODES' && defaultFile) {
      return `${defaultFile.total} Codes`;
    }
    if (file) {
      if (error) {
        return t('error');
      }
      if (type === 'CODES' && file.parsedData) {
        return `${
          file.parsedData.data.length >= 1
            ? file.parsedData.data.length - 1
            : file.parsedData.data.length
        } Codes`;
      }
      if (isLoading) {
        return <Loader />;
      }
      if (file.parsedData && type === 'AFFILIATES' && !isLoading) {
        return `${seccussfulOffers} Offers`;
      }
    }
    return '';
  };

  return (
    <div data-testid="file-upload-container">
      <div
        {...getRootProps({ className: 'dropzone' })}
        className={clsx(
          'flex flex-col items-center justify-center border-[1px] border-dashed rounded-md w-full h-[172px] pb-4',
          error && 'bg-[#c24f4f]/10 border-[#c24f4f]'
        )}
      >
        {defaultFile && (
          <>
            <div className="flex">
              <div className={clsx('text-md')}>{renderFilePreview()}</div>
            </div>
          </>
        )}
        {file ? (
          <>
            <p
              className={clsx(
                'font-semibold mb-8',
                error ? 'text-[#c24f4f]' : 'text-primary'
              )}
            >
              {error ? error.file.name : file.file.name}
            </p>
            <div className="flex">
              <div
                className={clsx(
                  'text-md',
                  error ? 'text-[#c24f4f]' : 'text-textSecondary'
                )}
              >
                {renderFilePreview()}
                {uploadError}
              </div>
              {error && <Icon name="error-exclamation" width="24" className="ml-2" />}
            </div>
          </>
        ) : (
          !defaultFile && (
            <>
              <input {...getInputProps()} />
              <Button type="outline" onClick={open}>
                {t('buttons.add')}
              </Button>
              <p className="text-md font-normal mt-4 text-textSecondary">
                {t('warning-csv')}
              </p>
            </>
          )
        )}
      </div>
      <div>
        {type === 'AFFILIATES' ? (
          <a
            href="/assets/csv-affiliate-template.csv"
            target="_blank"
            download
            className="text-secondary cursor-pointer hover:underline"
          >
            {t('buttons.download-csv-template')}
          </a>
        ) : (
          <a
            href="/assets/csv-codes-template.csv"
            target="_blank"
            download
            className="text-secondary cursor-pointer hover:underline"
          >
            {t('buttons.download-csv-template')}
          </a>
        )}
      </div>
      {type === 'AFFILIATES' && (
        <div className="text-end mt-6">
          <Button
            type="text"
            onClick={() => {
              setFile(undefined);
              setError(undefined);
            }}
          >
            {t('buttons.cancel')}
          </Button>
          {error ? (
            <Button
              type="secondary"
              onClick={() => {
                setError(undefined);
                setFile(undefined);
                open();
              }}
            >
              {t('buttons.try-again')}
            </Button>
          ) : (
            <Button type="secondary" disabled={!file} onClick={() => setModalOpen(true)}>
              {t('buttons.upload')}
            </Button>
          )}
        </div>
      )}
      <div className="text-end mt-6">
        {type === 'CODES' && (
          <Button
            type="text"
            disabled={!file}
            onClick={() => {
              setFile(undefined);
              setError(undefined);
              setDefaultFile(offerRedemptionCounts);
            }}
          >
            {t('buttons.cancel')}
          </Button>
        )}
        {type === 'CODES' &&
          (!error ? (
            <Button
              type="secondary"
              disabled={!defaultFile}
              onClick={() => {
                setFile(undefined);
                setDefaultFile(undefined);
              }}
            >
              {t('buttons.add-another-csv')}
            </Button>
          ) : (
            <Button
              type="secondary"
              onClick={() => {
                setError(undefined);
                setFile(undefined);
                open();
              }}
            >
              {t('buttons.try-again')}
            </Button>
          ))}
      </div>
      <Modal type="small" isOpen={modalOpen} setIsOpen={setModalOpen}>
        <div className="h-full flex flex-col justify-between">
          <div>
            <h2 className="text-[20px] font-semibold mb-3">{t('modal.title')}</h2>
            <p>{t('modal.subtitle')}</p>
          </div>
          <div className="flex justify-between">
            <Button type="text" onClick={() => setModalOpen(false)}>
              {t('modal.cancel')}
            </Button>
            <Button
              type="secondary"
              onClick={() => {
                handleFileUpload();
                setModalOpen(false);
              }}
            >
              {t('modal.submit')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default FileUpload;
