import clsx from 'clsx';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import Icon from '../UI/Icon/Icon';
import Menu from '../UI/Menu/Menu';

const Header = () => {
  const [open, setOpen] = useState<boolean>(false);
  const { user } = useContext(AuthContext);

  return (
    <header
      className="fixed w-full z-30 flex items-center h-[68px] bg-white border-b-[1px] px-4 md:px-0 lg:px-4 mx-auto sm:max-w-tablet-page md:max-w-tablet-page lg:max-w-desktop-page"
      data-testid="header"
    >
      <nav className="flex items-center justify-between w-full">
        <Link to="/" aria-label="home">
          <Icon name="tickle-logo" width="84px" />
        </Link>
        <>
          {user && (
            <Menu
              position="left"
              trigger={
                <button
                  id="account-user-avatar-btn-menu"
                  type="button"
                  aria-label="menu open"
                  onClick={() => setOpen(!open)}
                >
                  <div className="flex flex-row items-center justify-between w-[220px]">
                    <div className="flex flex-row items-center gap-3">
                      <div className="flex justify-center items-center font-semibold bg-[#666666] text-white rounded-full w-[36px] h-[36px]">
                        {user.email ? user.email[0].toUpperCase() : <p>Account</p>}
                      </div>
                      <div className="flex flex-col text-[14px] text-left">
                        <h2 className="font-semibold">{user.email?.split('@')[0]}</h2>
                        <p>Tickle</p>
                      </div>
                    </div>
                    <div>
                      <Icon
                        className={clsx(
                          'rotate-0 ease-in-out duration-300',
                          open && '!rotate-180'
                        )}
                        name="bottom-chevron"
                        width="24px"
                        height="24px"
                      />
                    </div>
                  </div>
                </button>
              }
            />
          )}
        </>
      </nav>
    </header>
  );
};

export default Header;
