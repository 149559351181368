import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import { AffiliateOfferUploadResponse } from '../../api';
import { axiosInstance } from '../Services';

export interface AffiliateUploadRequest {
  save: boolean;
  csv: File;
}

const usePostUploadCsvAffiliates = () => {
  const postUploadCsvAffiliateOffers = async (parameters: AffiliateUploadRequest) => {
    const { save, csv } = parameters;
    const bodyFormData = new FormData();
    bodyFormData.append('csv', csv);
    return (
      await axiosInstance.post<AffiliateOfferUploadResponse>(
        `/affiliateoffer`,
        bodyFormData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          params: { save },
        }
      )
    ).data;
  };

  return useMutation<AffiliateOfferUploadResponse, AxiosResponse, AffiliateUploadRequest>(
    ['postUploadCsvAffiliateOffers'],
    parameters => postUploadCsvAffiliateOffers(parameters)
  );
};

export default usePostUploadCsvAffiliates;
