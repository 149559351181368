import { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import UserForm from '../../components/Forms/UserForm/UserForm';
import Icon from '../../components/UI/Icon/Icon';
import { BrandContext } from '../../context/BrandContext';
import useBrandsById from '../../services/queries/useBrandById';
import useCreateAd from '../../services/queries/useCreateAd';
import { createUserRequest, updateUserRequest } from '../../services/queries/userManagement';

const CreateUser: FC = () => {
  const { t } = useTranslation('users');
  const nav = useNavigate();

  const { brandId, id } = useParams();

  const { setBrand } = useContext(BrandContext);

  const { mutate: createAd, isLoading: createLoading } = useCreateAd(brandId);
  const { data: brand, refetch: getBrandById } = useBrandsById(brandId);
  useEffect(() => {
    getBrandById().then(res => setBrand(res.data));
  }, []);

  useEffect(() => {
    document.title = brand ? brand?.displayName : 'Tickle';

    return () => {
      document.title = 'Tickle';
    };
  }, [brandId]);

  const createUser = async (e: any) => {
    const data = {
      FirstName: e.FirstName,
      Surname: e.Surname,
      UserName: e.UserName,
      Email: e.Email,
      Status: e.Status,
      Password: e.Password
    };

    createUserRequest(data, '', (res: any) => {
      if (res.isSuccess) {
        nav(`/users`, { replace: true });
      }
    });

  };

  const updateUser = async (e: any) => {
    const data = {
      FirstName: e.FirstName,
      Surname: e.Surname,
      UserName: e.UserName,
      Email: e.Email,
      Status: e.Status,
      Password: e.Password
    };

    updateUserRequest(data, `/${id}`, (res: any) => {
      if (res.isSuccess) {
        nav(`/users`, { replace: true });
      }
    });
  };

  return (
    <div className="wrapper mb-40">
      <div className="flex gap-2 justify-start w-[800px] mx-auto">
        <button
          type="button"
          onClick={() => nav(-1)}
          aria-label="go back"
          data-testid="back-button"
        >
          <Icon name="back-arrow" width="24" />
        </button>
        <h1 className="font-semibold">{!id ? t('form.create') : t('form.edit')}</h1>
      </div>
      <UserForm onSubmit={(e: any, flag: any) => flag === 'update' ? updateUser(e) : createUser(e)} isSubmitting={createLoading} />
    </div>
  );
};

export default CreateUser;
