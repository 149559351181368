import { useIsFetching, useQueries } from 'react-query';
import TickleApi from '../Services';

const useOffersById = (brandId: string | undefined, ids: string[] | undefined) => {
  const queries = ids
    ? ids.map(id => ({
        queryKey: ['offer', id],
        queryFn: () => TickleApi.getOfferById(brandId || '', id).then(res => res.data),
        staleTime: 300000,
      }))
    : [];

  const queryResults = useQueries(queries);
  const isLoading = useIsFetching(['offer']) > 0;

  return {
    results: queryResults,
    offers: queryResults.map(result => result.data),
    isLoading,
  };
};

export default useOffersById;
