import { Combobox, Transition } from "@headlessui/react";
import { FC, Fragment, useEffect, useState } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { FaChevronCircleDown } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

interface Props {
  people: any;
  backPeople: any;
  categorySelected: any
  changeCategorySelection?: any;
  isCountry?: any;
  singleBack?: any;
}

const DropdownMultiSelectCountry: FC<Props> = ({ people, backPeople, categorySelected, isCountry, changeCategorySelection, singleBack }) => {
  const [selectedPeople, setSelectedPeople] = useState<{ ID: string | number, name: string }[]>(categorySelected);
  const [updatedFilter, setUpdatedFilter] = useState<{ id: string | number, name: string }[]>([]);
  const [query, setQuery] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const [uptoDateCategory, setUptoDateCategory] = useState<{ id: string | number, name: string }[]>([]);
  const nav = useNavigate();

  useEffect(() => {
    console.log("Category selected", categorySelected)
    setSelectedPeople(categorySelected);

    // if (categorySelected?.length) {
    // } else {
    //   setSelectedPeople(selectedPeople);
    // }
  }, [categorySelected]);

  useEffect(() => {
    setUptoDateCategory(people);
  }, [people])

  useEffect(() => {
    let upPeople = [];
    if (query === "") {
      upPeople = uptoDateCategory
    } else {
      upPeople = uptoDateCategory.filter((person: any) =>
        person.name
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.toLowerCase().replace(/\s+/g, ""))
      );
    }

    setUpdatedFilter(upPeople)
  }, [uptoDateCategory]);

  useEffect(() => {
    backPeople(selectedPeople);
    if (!isDelete) { setUpdatedFilter(uptoDateCategory); }
  }, [selectedPeople]);

  const selectedUpdated = (values: any) => {
    const lookup = values.reduce((a: any, e: any) => {
      a[e.Id] = a[e.Id] + 1 || 0;
      return a;
    }, {});
    const duplicates = values.filter((e: any) => lookup[e.Id]);
    const unique = values.filter((obj: any, index: number) => {
      return index === values.findIndex((o: any) => obj.Id === o.Id);
    });
    const removeDuplicate = unique.filter((fl: any) => fl.Id !== duplicates?.[0]?.id);

    // setSelectedPeople(removeDuplicate);
    changeCategorySelection(true);
  };

  return (
    <div>
      <div className="flex items-center " >
        {/* <div className="fixed top-16 lg:w-1/4 w-full"> */}
        <Combobox value={selectedPeople} onChange={(e) => selectedUpdated(e)} multiple>
          <div className="relative">
            <div style={{ height: '55px' }} className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none border-none sm:text-sm">
              <Combobox.Input
                className="w-full focus:outline-none border-none py-5 pl-3 pr-10 text-sm text-gray-900 focus:ring-0"
                displayValue={() => ''}
                placeholder={selectedPeople?.length ? '' : 'Select Countries'}
                onFocus={() => setUpdatedFilter(uptoDateCategory)}
                onChange={(e) => {
                  setUpdatedFilter(uptoDateCategory?.filter((fs: any) => fs.name.toLowerCase().includes(e.target.value?.toLowerCase())))
                }}
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <FaChevronCircleDown
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>
            </div>

            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery("")}
            >
              <Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {updatedFilter?.length === 0 && query !== "" ? (
                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                    Nothing found.
                  </div>
                ) : (
                  updatedFilter?.map((person) => (
                    <Combobox.Option
                      key={person.id}
                      onClick={(e: any) => singleBack(person)}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? "bg-blue-600 text-white" : "text-gray-900"
                        }`
                      }
                      value={person}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`block truncate ${selected ? "font-medium" : "font-normal"
                              }`}
                          >
                            {person.name}
                          </span>
                          {(selected
                            || selectedPeople?.length && selectedPeople.filter(s => s.name === person.name)?.length
                          ) ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? "text-white" : "text-blue-600"
                                }`}
                            >
                              <AiFillCheckCircle
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}

                          <span
                            className={`z-10 absolute inset-y-0 left-0 flex items-center pl-3 ${active ? "text-white" : "text-indigo-600"
                              }`}
                          >
                            {/* <AiFillCloseCircle onClick={(e) => removeCategory(e, person)} className="h-5 w-5 cursor-pointer text-red-500" aria-hidden="true" /> */}
                          </span>
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>

        {/* <div className="mx-2">
          {!showAddCategory ?
            <AiFillPlusCircle onClick={() => { setShowAddCategory(true); setAddCategory('') }} className="h-5 w-5 cursor-pointer text-green-500" aria-hidden="true" />
            :
            <AiFillCloseCircle onClick={() => setShowAddCategory(false)} className="h-5 w-5 cursor-pointer text-red-500" aria-hidden="true" />
          }
        </div> */}



      </div>

      <div className="mt-4">
        {selectedPeople?.length ? selectedPeople?.map((person: any) => selectedPeople && uptoDateCategory?.filter((sp: any) => sp.name === person.name)?.map((s: any) => s?.name)).join(", ") : ''}
      </div>
    </div>
  );
}

export default DropdownMultiSelectCountry;