import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { BrandAdsResponse } from '../../api';
import TickleApi from '../Services';

const useBrandAds = (id: string | undefined) => {
  const getBrandAds = async () => (await TickleApi.getBrandAds(id || '')).data;

  return useQuery<BrandAdsResponse, AxiosError>(['ads', id], getBrandAds, {
    enabled: false,
  });
};

export default useBrandAds;
