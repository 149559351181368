import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Image from '../../assets/shared/login-bg.png';
import Button from '../../components/UI/Button/Button';
import Icon from '../../components/UI/Icon/Icon';
import { AuthContext } from '../../context/AuthContext';

type Inputs = {
  email: string;
  password: string;
};

const Login = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation('auth');
  const { login, error } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const onSubmit: SubmitHandler<Inputs> = async data => {
    if (login) {
      login(data.email, data.password);
    }
  };

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSubmit]);

  return (
    <div className="flex flex-row w-full" data-testid="login-container">
      <div className="flex flex-col items-center justify-around gap-12 max-h-[900px] w-[500px]">
        <Icon name="tickle-logo" width="112px" className="mt-32" />
        <div className="flex flex-col justify-between w-[500px] p-6">
          <form className="flex flex-col gap-4">
            <h1 className="text-xl font-semibold">{t('login')}</h1>
            {error && <p className="text-error mb-4 font-semibold">{error}</p>}
            <label>
              <p className="text-md">{t('email')}</p>
              <input
                type="email"
                data-testid="login-email-input"
                className={clsx('input w-full', errors.email && 'border-error')}
                {...register('email', {
                  required: true,
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Incorrect email format',
                  },
                })}
              />
              <p className="text-error mt-2" role="alert">
                {errors.email && 'Valid email required'}
              </p>
            </label>
            <label className="relative">
              <p className="text-md">{t('password')}</p>
              <input
                type={passwordShown ? 'text' : 'password'}
                data-testid="login-password-input"
                className={clsx('input w-full', errors.password && 'border-error')}
                {...register('password', {
                  required: true,
                  minLength: 0,
                  maxLength: 60,
                  value: '',
                })}
              />
              <button
                type="button"
                className="absolute top-0 right-1 cursor-pointer"
                onClick={e => {
                  e.preventDefault();
                  // navigate to forgot password page
                  navigate('/forgot-password', { replace: true });
                }}
                data-testid="login-forgot-password-button"
              >
                <p className="text-md text-primary">{t('forgotten')}</p>
              </button>
              <button
                type="button"
                className="absolute top-8 right-4 cursor-pointer"
                aria-label="Password visiibility toggle"
                onClick={e => {
                  e.preventDefault();
                  setPasswordShown(!passwordShown);
                }}
                data-testid="password-visibility-toggle"
              >
                {passwordShown ? (
                  <Icon name="open-eye" className="h-6 w-6" />
                ) : (
                  <Icon name="closed-eye" className="h-6 w-6" />
                )}
              </button>
              <p className="text-error mt-2" role="alert">
                {errors.password && 'Valid password required'}
              </p>
            </label>
            <Button
              className="mt-4"
              type="secondary"
              size="full"
              onClick={handleSubmit(onSubmit)}
              data-testid="login-submit-button"
            >
              {t('buttons.sign-in')}
            </Button>
          </form>
        </div>
        <p className="text-sm">{t('tickle-footer')}</p>
      </div>
      <div className="flex justify-center w-[100%]">
        <img
          src={Image}
          alt="background"
          style={{ height: '100vh', width: '100%', objectFit: 'cover' }}
        />
      </div>
    </div>
  );
};

export default Login;
