import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { BrandOfferResponse } from '../../api';
import TickleApi from '../Services';

const useBrandOffers = (
  brandId: string | undefined,
  startBy?: string,
  validUntil?: string
) => {
  const getBrands = async () =>
    (await TickleApi.getBrandOffers(brandId || '', startBy, validUntil)).data;

  return useQuery<BrandOfferResponse, AxiosError>(['brandOffers', brandId], getBrands);
};

export default useBrandOffers;
