import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import StickyFooter from '../../StickyFooter/StickyFooter';
import Accordion from '../../UI/Accordion/Accordion';
import Button from '../../UI/Button/Button';
import Modal from '../../UI/Modal/Modal';
import UserFormDetails from './UserFormDetails';

import { FormProvider, useForm } from 'react-hook-form';
import { GetUserResponse } from '../../../services/queries/useUsers';
import { UserFormSchema } from './UserFormTypes';

interface UserFormProps {
  initialData?: UserFormSchema;
  isSubmitting: boolean;
  onSubmit: any;
}

const UserForm = ({ initialData, onSubmit, isSubmitting }: UserFormProps) => {
  const { t } = useTranslation('users');
  const nav = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const [mode] = useState(initialData !== undefined ? 'edit' : 'create');
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [discardModalOpen, setDiscardModalOpen] = useState(false);
  const [clickError, setClickError] = useState(false);
  const [user, setUser] = useState({
    FirstName: '',
    Surname: '',
    Email: '',
    UserName: '',
    Password: '',
    ConfirmPassword: '',
  });
  // const [formWork, SetFormWork] = useState()


  const formMethods = useForm<GetUserResponse>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const {
    trigger,
    handleSubmit,
    watch,
    reset,
    getValues,
    control,
    formState: { errors },
  } = formMethods;

  return (
    <div className="w-[800px] mx-auto flex flex-col gap-6" data-testid="ad-form">
      <div className="flex gap-2">
        <FormProvider {...formMethods}>
          <form className="flex flex-col gap-6 w-full">
            <Accordion
              defaultOpen
              type="advert"
              title={
                <>
                  <h1 className="w-[90px] text-left text-lg">{t('User')}</h1>{' '}
                  {mode === 'edit' ? (
                    <h2
                      className="w-[340px] text-left text-ellipsis"
                      aria-label="ad title"
                    >
                      {/* {getValues('title') ? getValues('title') : 'Ad Title'} */}
                    </h2>
                  ) : (
                    <span className="w-[340px]" />
                  )}
                </>
              }
            >
              <UserFormDetails clickError={clickError} backUserData={(flag: any) => setUser(flag)} />
            </Accordion>
          </form>
        </FormProvider>
      </div>

      <Modal
        type="large"
        isOpen={saveModalOpen}
        setIsOpen={setSaveModalOpen}
        height={Object.keys(errors).length > 0 ? '!h-[400px]' : '!h-[240px]'}
      >
        <div className="h-full flex flex-col justify-between">
          <div>
            <h2 className="text-[20px] font-semibold mb-3">
              {location.pathname === '/user/create-user' ? t('button.create') : t('button.update')}
            </h2>
            {/* <p>{t('form.modal.createA')}</p> */}
            <p className="mt-4">Are you sure you want to {`${location.pathname === '/user/create-user' ? 'Create' : 'Update'}`} User</p>
            {/* <p className="mt-4">{t('form.modal.createB')}</p> */}
            {Object.keys(errors).length > 0 && (
              <>
                <p>Please enter valid:</p>
                {/* <div className="h-[150px] overflow-y-auto">
                  {getAllFormErrors(errors).map((error, i) => (
                    <p key={i} className="text-secondary">
                      • {error}
                    </p>
                  ))}
                </div> */}
              </>
            )}
          </div>
          <div className="flex justify-between">
            <Button type="text" onClick={() => setSaveModalOpen(false)}>
              {t('button.create-back')}
            </Button>
            <Button
              type="secondary"
              loading={isSubmitting}
              onClick={handleSubmit(() => onSubmit(user, id ? 'update' : 'create'))}
            // disabled={Object.keys(errors).length > 0}
            >
              {id ? t('form.update') : t('form.create')}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal type="small" isOpen={discardModalOpen} setIsOpen={setDiscardModalOpen}>
        <div className="h-full flex flex-col justify-between">
          <div>
            <h2 className="text-[20px] font-semibold mb-3">
              {t('form.modal.cancel-title')}
            </h2>
            <p>{t('form.modal.cancel-info')}</p>
          </div>
          <div className="flex justify-between">
            <Button type="text" onClick={() => setDiscardModalOpen(false)}>
              {t('button.cancel-back')}
            </Button>
            <Button
              type="secondary"
              onClick={() => {
                reset();
                nav(`/users`);
              }}
            >
              {t('button.cancel-proceed')}
            </Button>
          </div>
        </div>
      </Modal>
      <StickyFooter
        discard={() => setDiscardModalOpen(true)}
        save={() => {
          if ((user.FirstName && user.Surname && user.Email && user.UserName && user.Password && user.ConfirmPassword) || (id && user.FirstName && user.Surname && user.Email && user.UserName)) {
            trigger();
            setSaveModalOpen(true);
            setClickError(false);
          } else {
            setClickError(true);
          }

        }}
        clickError={clickError}
        positiveCTAText={(id) ? t('form.update') : t('form.create')}
      />
    </div>
  );
};

export default UserForm;
