import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Image from '../../assets/shared/login-bg.png';
import Button from '../../components/UI/Button/Button';
import Icon from '../../components/UI/Icon/Icon';
import { AuthContext } from '../../context/AuthContext';

type Input = {
  email: string;
};

const Login = () => {
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation('auth');
  const { forgotPassword, error } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Input>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const onSubmit: SubmitHandler<Input> = async data => {
    setSubmitted(true);
    if (forgotPassword) {
      forgotPassword(data.email);
    }
  };

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (submitted) return;
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSubmit]);

  return (
    <div className="flex flex-row w-full" data-testid="forgot-password-container">
      <div className="flex flex-col items-center justify-around gap-12 max-h-[900px] w-[500px]">
        <div className="w-full px-6">
          <button type="button" onClick={() => navigate('/login')}>
            <div className="flex items-center">
              <Icon name="back-arrow" width="24px" />
              <span className="block ml-4 text-sm">{t('buttons.back-to-login')}</span>
            </div>
          </button>
        </div>
        <div className="flex flex-col justify-between w-[500px] p-6">
          <form className="flex flex-col gap-4">
            <h1 className="text-xl font-semibold">{t('reset-your-password')}</h1>
            <p className="text-md mb-5">{t('forgot-password-text')}</p>
            {error && <p className="text-error mb-4 font-semibold">{error}</p>}
            <label>
              <p className="text-md">{t('email')}</p>
              <input
                type="email"
                data-testid="forgot-email-input"
                className={clsx('input w-full', errors.email && 'border-error')}
                disabled={submitted}
                {...register('email', {
                  required: true,
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Incorrect email format',
                  },
                })}
              />
              <p className="text-error mt-2" role="alert">
                {errors.email && 'Valid email required'}
              </p>
            </label>
            {submitted && <h2 data-testid="reset-email-sent">{t('email-associated')}</h2>}
            <Button
              className="mt-4"
              type="secondary"
              size="full"
              onClick={handleSubmit(onSubmit)}
              data-testid="forgot-submit-button"
              disabled={submitted}
            >
              {t('buttons.reset-password')}
            </Button>
            <Button
              className="mt-4"
              type="outline"
              size="full"
              onClick={() => navigate('/login')}
              data-testid="forgot-cancel-button"
              disabled={submitted}
            >
              {t('buttons.cancel')}
            </Button>
          </form>
        </div>
        <p className="text-sm">{t('tickle-footer')}</p>
      </div>
      <div className="flex justify-center w-[100%]">
        <img
          src={Image}
          alt="background"
          style={{ height: '100vh', width: '100%', objectFit: 'cover' }}
        />
      </div>
    </div>
  );
};

export default Login;
