import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { AdvertisementResponse } from '../../api';
import TickleApi from '../Services';

const useAdById = (brandId: string | undefined, adId: string | undefined) => {
  const getAdById = async () =>
    (await TickleApi.getAdById(brandId || '', adId || '')).data;

  return useQuery<AdvertisementResponse, AxiosResponse>(
    ['ad', brandId, adId],
    getAdById,
    {
      staleTime: 10 * 60 * 1000,
      enabled: adId !== undefined && brandId !== undefined,
    }
  );
};

export default useAdById;
