import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import { ImageUploadResponse } from '../../api';
import { axiosInstance } from '../Services';

export interface ImageUploadRequest {
  file: File;
}

const usePostUploadVideo = (id: string | undefined) => {
  const postUploadVideo = async (parameters: ImageUploadRequest) => {
    const { file } = parameters;
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);

    // https://tickle-dev-adminapi-apigw-gateway-c9k1ab77.nw.gateway.dev/media?brandId=story&type=BRAND_STORY_MEDIA

    return (
      await axiosInstance.post<ImageUploadResponse>('/media', bodyFormData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: { brandId: id, type: 'BRAND_STORY_MEDIA' },
      })
    ).data;
  };

  return useMutation<ImageUploadResponse | any, AxiosResponse, ImageUploadRequest>(
    ['brand-image', id],
    parameters => postUploadVideo(parameters)
  );
};

export default usePostUploadVideo;
