import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { AdvertisementResponse } from '../../api';
import TickleApi, { AddEditAdvertRequest } from '../Services';

const useEditAd = (brandId: string | undefined, adId: string | undefined) => {
  const queryClient = useQueryClient();

  const editAd = async (ad: AddEditAdvertRequest) =>
    (await TickleApi.editAd(brandId || '', adId || '', ad)).data;

  return useMutation<AdvertisementResponse, AxiosResponse, AddEditAdvertRequest>(
    ['advert', adId],
    editAd,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['ad', brandId, adId]);
      },
    }
  );
};

export default useEditAd;
