import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import { AddEditOfferRequest, OfferResponse } from '../../api';
import TickleApi from '../Services';

const useCreateOffer = (id: string | undefined) => {
  const createOffer = async (offer: AddEditOfferRequest) =>
    (await TickleApi.createOffer(id || '', offer)).data;

  return useMutation<OfferResponse, AxiosResponse, AddEditOfferRequest>(
    ['offer', id],
    offer => createOffer(offer)
  );
};

export default useCreateOffer;
