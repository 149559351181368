import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { OfferResponse } from '../../api';
import TickleApi from '../Services';

const useOfferById = (brandId: string | undefined, id: string | undefined) => {
  const getOfferById = async () =>
    TickleApi.getOfferById(brandId || '', id || '').then(res => res.data);

  return useQuery<OfferResponse, AxiosResponse>(['offer', id], getOfferById);
};

export default useOfferById;
