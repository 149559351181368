class Urls {
    public readonly baseUrl = process.env.REACT_APP_BASE_URL;

    public readonly listUser = this.baseUrl?.concat('/users?pagination=true');

    public readonly listBrand = this.baseUrl?.concat('/brand?pagination=true');

    public readonly claimNewLetter = this.baseUrl?.concat('/claimnewssubscribers');

    public readonly notifyClaim = this.baseUrl?.concat('/notifyclaimsample');

    public readonly notifyNewsletter = this.baseUrl?.concat('/notifynewsletter');

    public readonly notifyBrandOfficial = this.baseUrl?.concat('/notifybrandofficials');

    public readonly postVideo = this.baseUrl?.concat('/media?type=BRAND_STORY_MEDIA');

    public readonly createUser = this.baseUrl?.concat('/create-user');

    // public readonly loginAdmin = 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyCRWLC0t_YmA-VACs68biKdiUBoRbS_8PU';
    
    public readonly loginAdmin = this.baseUrl?.concat('/login');

    public readonly loginAdminUser = 'https://identitytoolkit.googleapis.com/v1'?.concat('/accounts:lookup?');

    public readonly updateGetUser = this.baseUrl?.concat('/users');

    public readonly deleteCategory = this.baseUrl?.concat('/category/delete');

    public readonly deleteBrand = this.baseUrl?.concat('/brand/delete');

    public readonly createCategory = this.baseUrl?.concat('/category');

    public readonly UrlAdvert = this.baseUrl;

    public readonly listCountry = this.baseUrl?.concat('/getcountrylist');
}

const ApiUrls = new Urls();

export default ApiUrls;
