import {
  confirmPasswordReset,
  sendPasswordResetEmail,
  signOut,
  User,
} from 'firebase/auth';
import React, { createContext, Dispatch, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { postLoginRequest } from '../services/ApiService';
import ApiUrls from '../services/ApiUrls';
import { getLoggedInUserRequest } from '../services/queries/userManagement';

interface Props {
  children: React.ReactNode;
}

export interface AuthInterface {
  login: (email: string, password: string) => void;
  logout: () => void;
  setError: Dispatch<string | null>;
  forgotPassword: (email: string) => void;
  resetPassword: (code: string, password: string) => void;
  isLoading: boolean;
  error: string | null;
  user: User | null;
}

export const AuthContext = createContext<AuthInterface>({} as AuthInterface);

export const AuthContextProvider: React.FC<Props> = ({ children }) => {
  const [error, setError] = useState<AuthInterface['error']>(null);
  const [isLoading, setIsLoading] = useState<AuthInterface['isLoading']>(false);
  const [user, setUser] = useState<AuthInterface['user']>(() => {
    const localJSON = localStorage.getItem('user');
    if (localJSON) {
      return JSON.parse(localJSON);
    }
    return null;
  });
  const navigate = useNavigate();
  const location = useLocation();

  const catchLogin = () => {
    setError('Sorry we could not find the account you are trying to log into.');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user');
    localStorage.removeItem('refreshToken');
  };

  useEffect(() => {
    if (error) {
      setError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const login: AuthInterface['login'] = async (email, password) => {
    setError(null);
    setIsLoading(true);
    try {
      const res: any = await postLoginRequest(ApiUrls.loginAdmin, { email, password });

      if (res.status === 200 || res.status === 201) {
        setIsLoading(false);
        getLoggedInUserRequest({ idToken: res.data.idToken }, `key=${process.env.REACT_APP_GOOGLE_API_KEY}`, (resUser: any) => {
          // @ts-ignore
          localStorage.setItem('accessToken', res.data.idToken);
          localStorage.setItem('refreshToken', res.data.refreshToken);
          localStorage.setItem('user', JSON.stringify(resUser.data.users?.[0]));
          setUser(resUser.data.users?.[0]);
          navigate('/partners');
          window.location.reload();
        })

      } else {
        catchLogin();
      }
    } catch {
      catchLogin();
    }
  };

  const logout: AuthInterface['logout'] = async () => {
    setError(null);
    setIsLoading(true);
    try {
      await signOut(auth);
      setUser(null);
      localStorage.removeItem('user');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      setIsLoading(false);
      navigate('/login');
    } catch (caughtError) {
      setIsLoading(false);
    }
  };

  const forgotPassword: AuthInterface['forgotPassword'] = async (email: string) => {
    setIsLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
      setIsLoading(false);
    } catch (caughtError) {
      setError('Password reset failed, please try again later.');
      setIsLoading(false);
    }
  };

  const resetPassword: AuthInterface['resetPassword'] = async (code, password) => {
    setIsLoading(true);
    setError(null);
    try {
      await confirmPasswordReset(auth, code, password);
      setIsLoading(false);
    } catch (caughtError) {
      setError('Password could not be reset, please try again later.');
      setIsLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        login,
        logout,
        setError,
        forgotPassword,
        resetPassword,
        isLoading,
        error,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
