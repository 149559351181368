import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  AddEditBoostRequest,
  AddEditOfferRequest,
  OfferAttributeCounts,
} from '../../../api';
import getAllFormErrors from '../../../helpers/forms/getAllFormErrors';
import useBrandsById from '../../../services/queries/useBrandById';
import { getAllCountriesList, getCategoryRequest } from '../../../services/queries/userManagement';
import CreateOfferBoost from '../../CreateBoost/CreateOfferBoost';
import DropdownMultiSelect from '../../DropdownMultiselect/DropdownMultiselect';
import DropdownMultiSelectCountry from '../../DropdownMultiselect/DropdownMultiselectCountry';
import FileUpload from '../../FileUpload/FileUpload';
import ImageUploader from '../../ImageUploader/ImageUploader';
import StickyFooter from '../../StickyFooter/StickyFooter';
import Accordion from '../../UI/Accordion/Accordion';
import Button from '../../UI/Button/Button';
import Icon from '../../UI/Icon/Icon';
import Modal from '../../UI/Modal/Modal';
import Radio from '../../UI/Radio/Radio';
import Checkbox from '../../UI/Toggles/Checkbox/Checkbox';


export interface BoostsFormType extends AddEditBoostRequest {
  csvFile?: File;
}

export interface OfferFormType extends AddEditOfferRequest {
  imageUrl: string;
  beginDate: string;
  endDate: string;
  beginTime: string;
  endTime: string;
  requiredCoins?: number;
  offerCodes: OfferAttributeCounts | undefined;
  csvFile?: File;
  countries?: any;
  categories?: any;
  followedCategories?: any;
  boosts?: BoostsFormType[];
}
interface Props {
  onSubmit: SubmitHandler<OfferFormType>;
  isDisabled: boolean;
  isSubmitting: boolean;
  initialData?: OfferFormType;
  offerError?: any;
  backCountry?: any;
  backCategory?: any;
  categoryChildBackArr?: any;
  categoriesCheckArr?: any;
}

const CreateOffer: FC<Props> = ({
  onSubmit,
  isDisabled,
  isSubmitting,
  initialData,
  offerError,
  backCountry,
  backCategory,
  categoryChildBackArr,
  categoriesCheckArr
}) => {
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const location = useLocation();
  const [discardModalOpen, setDiscardModalOpen] = useState(false);
  const [categoryArr, setCategoryArr] = useState<any>([]);
  const { t } = useTranslation('offersLibrary');
  const [updatedCategory, setUpdatedCategory] = useState<any>();
  const [updatedChildCategory, setUpdatedChildCategory] = useState<any>();
  const [inputTextValue, setInputTextValue] = useState<string>();
  const codeOptions = [
    { id: '1', name: 'Codes', value: 'CODE' },
    { id: '2', name: 'URL', value: 'URL' },
  ];

  const [redemption, setRedemption] = useState(() => {
    if (initialData && initialData.redemptionType) {
      return initialData.redemptionType === 'CODE' ? codeOptions[0] : codeOptions[1];
    }
    return codeOptions[0];
  });

  const offerOptions = [
    { id: '1', name: 'Publish', value: 'Publish' },
    { id: '2', name: 'Draft', value: 'Draft' },
  ];
  const [offerCode, setOfferCode] = useState(() => {
    if (initialData && initialData.offerStatus) {
      return initialData.offerStatus === 'Draft' ? offerOptions[1] : offerOptions[0];
    }
    return offerOptions[0];
  });

  const nav = useNavigate();
  const { brandId } = useParams();
  const { pathname } = useLocation();

  const currentDate = new Date().toISOString().split('T')[0];
  const currentTime = `${new Date()
    .getUTCHours()
    .toString()
    .padStart(2, '0')}:${new Date().getUTCMinutes().toString().padStart(2, '0')}`;

  const currentUpdatedTime = `${new Date()
    .getUTCHours()
    .toString()
    .padStart(2, '')}:${new Date().getUTCMinutes().toString().padStart(2, '20')}`;

  const formMethods = useForm<OfferFormType>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: initialData || {
      title: '',
      subtitle: '',
      description: '',
      imagePath: '',
      countries: [],
      beginDate: currentDate,
      endDate: currentDate,
      beginTime: currentTime,
      endTime: currentUpdatedTime,
      showOn: 'NULL',
      redemptionType: 'CODE',
      offerStatus: 'Publish',
      boosts: [],
      offerCodes: undefined,
      csvFile: undefined,
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    trigger,
    getValues,
    setError,
    clearErrors,
    control,
    formState: { errors, isDirty },
  } = formMethods;

  const { append: appendBoost } = useFieldArray({ control, name: 'boosts' });

  const [country, setCountry] = useState<any>();
  const [selectedCountry, setSelectedCountry] = useState<any>([]);
  const offer = watch();
  const preCountries = getValues('countries');
  const { data: brand, refetch } = useBrandsById(brandId);
  const [changeCategorySelection, setChangeCategorySelection] = useState<any>();


  useEffect(() => {
    getAllCountriesList('', '', (res: any) => {
      if (res.isSuccess) {
        setCountry(JSON.parse(res.data));
        if (preCountries?.length) {
          setSelectedCountry(
            JSON.parse(res.data)?.filter((fl: any, i: number) => {
              return preCountries?.some((el: any) => fl?.code.includes(el));
            })
          );
        }
      } else {
        setCountry([]);
      }
    });
  }, []);

  const getCategoryData = () => {
    getCategoryRequest('', `category`, (res: any) => {
      if (res.isSuccess) {
        setUpdatedCategory(res.data.parentCategories);
        setUpdatedChildCategory([...res.data.parentCategories, ...res.data.childCategories]);
      } else {
        setUpdatedCategory([]); setUpdatedChildCategory([]);
      }
    });
  };

  useEffect(() => {
    getCategoryData();
  }, []);

  console.log("offer", offer)


  return (
    <>
      <div data-testid="create-offer-container">
        <FormProvider {...formMethods}>
          <Accordion
            defaultOpen
            type="offer"
            title={
              <>
                {/* <h1 className="w-[90px] text-left text-lg">Offer</h1> */}
                <h2 className="w-[340px] text-left text-ellipsis" aria-label="Title">
                  {getValues('title')}
                </h2>
                <div className="flex flex-col text-left">
                  {/* {getValues('offerCodes') && (
                    <div>
                      <span className="text-dark-grey-text">Codes used</span>
                      <span className="ml-4 text-black">{`${getValues(
                        'offerCodes.available'
                      ).toLocaleString()} of ${getValues(
                        'offerCodes.total'
                      ).toLocaleString()}`}</span>
                    </div>
                  )} */}
                  <div className="flex flex-row text-white">
                    <p>{t('dates')}</p>
                    <div className="flex flex-row ml-4 text-white gap-2">
                      <p>{getValues('beginDate').split('-').reverse().join('/')}</p>-
                      <p>{getValues('endDate').split('-').reverse().join('/')}</p>
                    </div>
                  </div>
                </div>
              </>
            }
          >
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-6 bg-white rounded-md p-4 pb-11">
                <h2 className="text-lg font-semibold">{t('offer-details')}</h2>

                <div className="flex flex-row justify-between flex-wrap w-full">
                  <div style={{ width: '48%' }} className="pr-2 flex flex-row  ">
                    <label className="flex w-full flex-col relative font-semibold">
                      {t('offer-title')}
                      <input
                        type="text"
                        maxLength={50}
                        className={clsx(
                          'input w-full font-normal',
                          errors?.title && 'border-error'
                        )}
                        {...register(`title`, {
                          required:
                            offerCode.value === 'Publish'
                              ? 'Offer title is required.'
                              : false,
                          minLength: {
                            value: 4,
                            message: 'Offer title must be at least 4 characters.',
                          },
                          maxLength: 50,
                        })}
                      />
                      <span className="text-md absolute top-[74px] right-0 font-normal">
                        {offer.title?.length}/50
                      </span>
                      <p className="text-error mt-2 font-normal" role="alert">
                        {errors.title?.message}
                      </p>
                    </label>
                  </div>

                  <div style={{ width: '48%' }} className="pr-2 flex flex-row  ">
                    <label className="flex  w-full flex-col relative font-semibold">
                      {t('offer-sub-title')}
                      <input
                        type="text"
                        maxLength={115}
                        className={clsx(
                          'input w-full font-normal',
                          errors?.subtitle && 'border-error'
                        )}
                        {...register(`subtitle`, {
                          required:
                            offerCode.value === 'Publish'
                              ? 'Offer sub title is required.'
                              : false,
                          minLength: {
                            value: 4,
                            message: 'Offer sub title must be at least 4 characters.',
                          },
                          maxLength: 115,
                        })}
                      />
                      <span className="text-md absolute top-[74px] right-0 font-normal">
                        {offer.subtitle?.length}/115
                      </span>
                      <p className="text-error mt-2 font-normal" role="alert">
                        {errors.subtitle?.message}
                      </p>
                    </label>
                  </div>
                </div>

                <div className="flex flex-row justify-between flex-wrap w-full">

                  <div style={{ width: '48%' }} className="pr-2 flex flex-row  ">
                    <div className="flex flex-col bg-white rounded-2xl py-4 w-full">
                      <p className="font-semibold mb-3">Countries</p>
                      <DropdownMultiSelectCountry
                        people={country ?? []}
                        isCountry
                        categorySelected={selectedCountry ?? []}
                        changeCategorySelection={(flag: any) => setChangeCategorySelection(flag)}
                        // changeCategorySelection={(flag: any) => console.log(flag)}
                        singleBack={(e: any) => {
                          const includes = selectedCountry?.filter((ch: any) => ch?.code === e?.code)?.length;
                          if (includes) {
                            const fs = selectedCountry?.filter(
                              (ch: any) => ch?.code !== e?.code
                            );
                            setSelectedCountry(fs ?? []);
                          } else {
                            setSelectedCountry([...selectedCountry, e]);
                          }
                        }}
                        backPeople={(item: any) => {
                          backCountry(selectedCountry);
                        }}
                      />
                    </div>
                  </div>

                  <div style={{ width: '48%' }} className="pr-2 flex flex-row  ">
                    <div className="flex flex-col bg-white rounded-2xl py-4 w-full">
                      <p className="font-semibold">Category</p>
                      <div className="">
                        <DropdownMultiSelect
                          people={updatedCategory?.length ? updatedCategory : brand?.parentCategories}
                          categorySelected={offer.followedParentCategories?.length ? offer.followedParentCategories : []}
                          backPeople={(item: any) => backCategory(item)}
                          inputTextValue={(val: any) => setInputTextValue(val)}
                          changeCategorySelection={(flag: any) => setChangeCategorySelection(flag)}
                        />
                      </div>

                      {/* ?.filter((is: any) => categoriesCheckArr?.map((it: any) => it?.id)?.includes(is?.parentId)) */}

                      <div>
                        <DropdownMultiSelect
                          people={updatedChildCategory?.length ? updatedChildCategory?.filter((is: any) => categoriesCheckArr?.map((it: any) => it?.id)?.includes(is?.parentId)) : []}
                          categorySelected={offer.followedChildCategories?.length ? offer.followedChildCategories : []}
                          backPeople={(item: any) => categoryChildBackArr(item)}
                          inputTextValue={(val: any) => console.log(val)}
                          isSubcategory
                          changeCategorySelection={(flag: any) => setChangeCategorySelection(flag)}
                        />
                      </div>

                      {inputTextValue && <div className="text-red-500">{inputTextValue}</div>}
                    </div>
                  </div>
                </div>

                <div className="flex flex-row w-full justify-between">
                  <div style={{ width: '48%' }}>
                    <label className="flex flex-col relative font-semibold">
                      {t('offer-about-this-offer')}
                      <textarea
                        // maxLength={1000}
                        style={{ height: '240px' }}
                        className={clsx(
                          'input w-full h-[316px] font-normal',
                          errors?.description && 'border-error'
                        )}
                        {...register(`description`, {
                          required:
                            offerCode.value === 'Publish'
                              ? 'Offer description is required.'
                              : false,
                          minLength: {
                            value: 4,
                            message: 'Offer description must be at least 4 characters.',
                          },
                          maxLength: 1000,
                        })}
                      />
                      <span className="text-md absolute -bottom-6 right-0 font-normal">
                        {offer.description?.length}/1000
                      </span>
                      <p className="text-error mt-2 font-normal" role="alert">
                        {errors.description?.message}
                      </p>
                    </label>
                  </div>

                  <div style={{ width: '48%' }}>
                    <div className=" font-semibold">{t('offer-image')}</div>
                    <Controller
                      control={control}
                      name="imagePath"
                      rules={{
                        required:
                          offerCode.value === 'Publish'
                            ? 'Offer image is required.'
                            : false,
                      }}
                      render={({ field: { onChange } }) => (
                        <ImageUploader
                          subText="or drop an image to upload"
                          criteria="Image size 900 x 750 px (.jpg .png or .webp). Maximum 5MB."
                          widthReq={900}
                          heightReq={750}
                          defaultImg={offer.imageUrl || ''}
                          imageUrlCB={onChange}
                          setErrorOffer={error => setError('imagePath', error)}
                          clearErrorsOffer={clearErrors}
                          imgType="OFFER_IMAGE"
                          formError={errors?.imagePath?.message}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="h-full w-full">
                  <div>
                    {/* <Controller
                      control={control}
                      name="imageUrl"
                      render={({ field: { onChange } }) => (
                        <Checkbox
                          type="tick"
                          name="setPartnerStatusForBrand"
                          // checked={checked}
                          id={`${brandId}`}
                          label={t('codes-on')}
                          onChange={(res: any) => console.log('')}
                          className="checked:!bg-primary !border-primary flex" />
                      )}
                    /> */}
                  </div>

                  <div className="flex flex-row justify-between pt-8">
                    <div style={{ width: '48%' }}>
                      <Controller
                        control={control}
                        name="imageUrl"
                        render={({ field: { onChange } }) => (
                          <Checkbox
                            type="tick"
                            name="setPartnerStatusForBrand"
                            // checked={checked}
                            id={`${brandId}`}
                            label={t('show-on')}
                            onChange={(res: any) => console.log('')}
                            className="checked:!bg-primary !border-primary flex"
                          />
                        )}
                      />
                    </div>

                    <div style={{ width: '48%' }}>
                      <p className="font-semibold mb-2">Offer Status</p>
                      <Controller
                        name="offerStatus"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange } }) => (
                          <Radio
                            id="offer status"
                            name="offer-status"
                            options={offerOptions}
                            value={offerCode}
                            onChange={e => {
                              setOfferCode(e);
                              onChange(e.value);
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="bg-white rounded-md p-4">
                
              </div> */}
              <div className="flex flex-col bg-white rounded-md p-4 gap-4">
                <h2 className="text-lg font-semibold">{t('offer-schedule')}</h2>
                <p>{t('offer-schedule-text')}</p>
                <div className="flex flex-row w-full justify-between">
                  <div style={{ width: '48%' }} className="flex flex-row justify-between">
                    <label style={{ width: '48%' }} className=" relative">
                      <p className="font-semibold">{t('start-date')}</p>
                      <Icon
                        name="date"
                        width="20px"
                        className="absolute top-[36px] left-4 z-10 pointer-events-none"
                      />
                      <input
                        type="date"
                        data-testid="start-date-input"
                        min={currentDate}
                        // disabled={isDisabled}
                        id="input-start-date-picker"
                        className={clsx(
                          'input w-full pl-10',
                          errors?.beginDate && 'border-error'
                        )}
                        {...register('beginDate', {
                          required: 'Offer start date is required.',
                          onBlur: () => {
                            trigger([`beginTime`]);
                          },
                          onChange: event => {
                            if (new Date(event.target.value) > new Date(offer.endDate)) {
                              setValue('endDate', '');
                            }
                          },
                        })}
                      />
                      <p className="text-error mt-2" role="alert">
                        {errors?.beginDate?.message}
                      </p>
                    </label>
                    <label style={{ width: '48%' }} className=" relative">
                      <p className="font-semibold">{t('start-time')}</p>
                      <Icon
                        name="time"
                        width="20px"
                        className="absolute top-[36px] left-4 z-10 pointer-events-none"
                      />
                      <input
                        type="time"
                        data-testid="start-time-input"
                        // disabled={isDisabled}
                        className={clsx(
                          'input w-full pl-10',
                          errors?.beginTime && 'border-error'
                        )}
                        {...register('beginTime', {
                          required: 'Offer start time is required.',
                          onBlur: () => {
                            trigger([`endTime`]);
                          },
                          validate: value => {
                            if (value && !isDisabled) {
                              const val = value.replace(':', '');
                              const endTime = offer.endTime.replace(':', '');
                              const currTime = currentTime.replace(':', '');
                              if (offer.beginDate === offer.endDate && +val > +endTime) {
                                return 'Start time can not be later than end time.';
                              }
                              if (offer.beginDate === currentDate && +val < +currTime) {
                                return 'Start time can not be later than current time.';
                              }
                            }
                            return true;
                          },
                        })}
                      />
                      <span className="absolute top-[36px] right-3 text-dark-grey-text pointer-events-none">
                        UTC
                      </span>
                      <p className="text-error mt-2" role="alert">
                        {errors?.beginTime?.message}
                      </p>
                    </label>
                  </div>

                  <div style={{ width: '48%' }} className="flex flex-row justify-between">
                    <label style={{ width: '48%' }} className=" relative">
                      <p className="font-semibold">{t('end-date')}</p>
                      <Icon
                        name="date"
                        width="20px"
                        className="absolute top-[36px] left-4 z-10 pointer-events-none"
                      />
                      <input
                        type="date"
                        data-testid="end-date-input"
                        // disabled={isDisabled}
                        min={offer.beginDate}
                        className={clsx(
                          'input w-full pl-10',
                          errors?.endDate && 'border-error'
                        )}
                        {...register('endDate', {
                          required: 'Offer end date is required.',
                          onBlur: () => {
                            trigger(['beginDate', 'beginTime', 'endTime']);
                          },
                        })}
                      />
                      <p className="text-error mt-2" role="alert">
                        {errors?.endDate?.message}
                      </p>
                    </label>
                    <label style={{ width: '48%' }} className=" relative">
                      <p className="font-semibold">{t('end-time')}</p>
                      <Icon
                        name="time"
                        width="20px"
                        className="absolute top-[36px] left-4 z-10 pointer-events-none"
                      />
                      <input
                        type="time"
                        data-testid="end-time-input"
                        // disabled={isDisabled}
                        className={clsx(
                          'input w-full pl-10',
                          errors?.endTime && 'border-error'
                        )}
                        {...register('endTime', {
                          required: 'Offer end time is required.',
                          onBlur: () => {
                            trigger('beginTime');
                          },
                          validate: value => {
                            if (!isDisabled && offer.beginDate === offer.endDate) {
                              const val = value.replace(':', '');
                              const startTime = offer.beginTime.replace(':', '');
                              if (+val < +startTime) {
                                return 'End time can not be earlier than start time.';
                              }
                            }
                            return true;
                          },
                        })}
                      />
                      <span className="absolute top-[36px] right-3 text-dark-grey-text pointer-events-none">
                        UTC
                      </span>
                      <p className="text-error mt-2" role="alert">
                        {errors?.endTime?.message}
                      </p>
                    </label>
                  </div>
                </div>

                {/* <div className="flex flex-row w-full gap-4">
                  
                </div> */}
              </div>
              <div className="flex flex-col bg-white rounded-md p-4 gap-4">
                <h2 className="text-lg font-semibold">Offer redemption</h2>

                <div className="flex flex-col w-full gap-4">
                  <label htmlFor="Redemption-method" className="w-[220px] relative">
                    <p className="font-semibold mb-2">Redemption method</p>
                    <Controller
                      name="redemptionType"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange } }) => (
                        <Radio
                          id="redemption type"
                          name="Redemption-method"
                          disabled={
                            !!getValues(`offerCodes.available`) ||
                            !!getValues('redemptionUrl')
                          }
                          options={codeOptions}
                          value={redemption}
                          onChange={e => {
                            setRedemption(e);
                            onChange(e.value);
                          }}
                        />
                      )}
                    />
                  </label>

                  {redemption.id === '1' && (
                    <Controller
                      control={control}
                      name="csvFile"
                      render={({ field: { onChange } }) => (
                        <FileUpload
                          type="CODES"
                          setCsvCodes={e => {
                            clearErrors('csvFile');
                            onChange(e);
                          }}
                          setOfferError={err => {
                            setError('csvFile', err);
                          }}
                          offerRedemptionCounts={getValues(`offerCodes`)}
                        />
                      )}
                    />
                  )}
                  {redemption.id === '2' && (
                    <div className="w-full">
                      <p className="font-semibold">URL</p>
                      <input
                        type="text"
                        id="redemption-input"
                        placeholder="www.urlexample.com/12356-wsdgbisAbc"
                        className={clsx(
                          'input w-full',
                          errors?.redemptionUrl && 'border-error'
                        )}
                        {...register(`redemptionUrl`, {
                          required: 'Offer redemption URL is required.',
                          pattern: {
                            value:
                              /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
                            message: 'Offer redemption URL is invalid.',
                          },
                        })}
                      />
                      <p className="opacity-60">Enter the URL which hosts your Offer</p>
                    </div>
                  )}

                  {/* <label className="flex flex-col relative font-semibold">
                    {t('codes-on')}
                    <input
                      type="number"
                      maxLength={115}
                      required={redemption.value === 'CODE'}
                      disabled={redemption.value !== 'CODE'}
                      className={clsx(
                        'input w-full font-normal',
                        errors?.subtitle && 'border-error'
                      )}
                      {...register(`offerCodes.available`, {
                        required: redemption.value === 'CODE' ? 'Offer Code available is required.' : false,
                        minLength: {
                          value: 1,
                          message: 'Offer code must be at least 1 characters',
                        },
                        maxLength: 115,
                      })}
                    />
                  </label> */}
                  <p className="opacity-60">
                    (Remove URL / CSV to switch Redemption Method)
                  </p>
                </div>
              </div>
            </div>

            {offer.boosts?.map((boost, i) => (
              <div key={boost.id}>
                {/* <Modal type="large" isOpen={modalOpen} setIsOpen={setModalOpen}> */}
                <CreateOfferBoost
                  key={boost.id}
                  boost={boost}
                  index={i}
                  isDisabled={isDisabled}
                />
                {/* </Modal> */}
              </div>
            ))}
            {(offer.boosts || []).length < 2 && (
              <div className="bg-white rounded-md p-4 flex justify-center">
                <Button
                  type="outline"
                  className="flex items-center justify-center gap-1 !w-[264px]"
                  onClick={() => {
                    const newBoost = {
                      id: [...new Array(24)]
                        .map(() => Math.floor(Math.random() * 16).toString(16))
                        .join(''),
                      type: 'STANDARD',
                      redemptionType: 'CODE',
                      offerStatus: 'Publish',
                    };
                    appendBoost({
                      ...newBoost,
                    });
                    setModalOpen(true);
                  }}
                >
                  <Icon name="rocket" width="24" />
                  {(offer.boosts || []).length === 1 ? 'Add second Boost' : 'Add a Boost'}
                </Button>
              </div>
            )}
          </Accordion>
        </FormProvider>
      </div>
      <Modal
        type="small"
        isOpen={saveModalOpen}
        setIsOpen={setSaveModalOpen}
        height={Object.keys(errors).length > 0 ? '!h-[400px]' : '!h-[240px]'}
      >
        <div className="h-full flex flex-col justify-between">
          <div>
            <h2 className="text-[20px] font-semibold mb-3">
              {pathname.includes('create') ? t('buttons.create') : t('buttons.update')}
            </h2>
            <p>
              {pathname.includes('create') ? t('positive-info') : t('positive-info-edit')}
            </p>
            {Object.keys(errors).length > 0 && (
              <>
                <p>Please enter valid:</p>
                <div className="h-[150px] overflow-y-auto">
                  {getAllFormErrors(errors).length ? (
                    getAllFormErrors(errors).map((error, i) => (
                      <p key={i} className="text-secondary">
                        {/* eslint-disable-next-line */}• • • • • • • • • •{' '}
                        • {error.length ? error : 'Offer Code is required'}
                      </p>
                    ))
                  ) : (
                    <p className="text-secondary">• Offer Code is required</p>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="flex justify-between">
            <Button type="text" onClick={() => setSaveModalOpen(false)}>
              No, go back
            </Button>
            <Button
              type="secondary"
              onClick={handleSubmit(onSubmit)}
              loading={isSubmitting}
              disabled={Object.keys(errors).length > 0}
            >
              {pathname.includes('create') ? 'Create offer' : 'Update offer'}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal type="small" isOpen={discardModalOpen} setIsOpen={setDiscardModalOpen}>
        <div className="h-full flex flex-col justify-between">
          <div>
            <h2 className="text-[20px] font-semibold mb-3">
              Are you sure you want to discard?
            </h2>
            <p>You will lose any progress made within this session.</p>
          </div>
          <div className="flex justify-between">
            <Button type="text" onClick={() => setDiscardModalOpen(false)}>
              No, keep editing
            </Button>
            <Button
              type="secondary"
              onClick={() => {
                reset();
                if (location.pathname.includes('offers-draft')) {
                  nav(`/offers-draft/${brandId}`);
                } else {
                  nav(`/offers-library/${brandId}`);
                }
              }}
            >
              Yes, close and discard
            </Button>
          </div>
        </div>
      </Modal>
      {(isDirty || changeCategorySelection) &&
        <StickyFooter
          discard={() => setDiscardModalOpen(true)}
          save={() => {
            trigger();
            setSaveModalOpen(true);
          }}
          brandError={offerError}
          positiveCTAText={pathname.includes('create') ? 'Create offer' : 'Update offer'}
        />
      }
    </>
  );
};

export default CreateOffer;
