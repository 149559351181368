import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { OfferResponse } from '../../../api';
import getAllFormErrors from '../../../helpers/forms/getAllFormErrors';

import StickyFooter from '../../StickyFooter/StickyFooter';
import Accordion from '../../UI/Accordion/Accordion';
import Button from '../../UI/Button/Button';
import Modal from '../../UI/Modal/Modal';
import AdOfferForm from '../OfferForm/AdOfferForm/AdOfferForm';
import AdFormDetails from './AdFormDetails';
import AdFormImage from './AdFormImage';
import AdFormSchedule from './AdFormSchedule';
import { AdFormSchema } from './AdFormTypes';
import LinkOffersModal from './LinkOffersModal';

interface AddFormProps {
  initialData?: AdFormSchema;
  adIsActive: boolean;
  isSubmitting: boolean;
  onSubmit: SubmitHandler<AdFormSchema>;
  categoryBackArr?: any;
  categoryChildBackArr?: any;
  categoriesCheckArr?: any;
  advertError?: any;
  backCountry?: any;
}

const AdForm = ({ initialData, adIsActive, onSubmit, isSubmitting, advertError, categoryBackArr, backCountry, categoryChildBackArr, categoriesCheckArr }: AddFormProps) => {
  const { t } = useTranslation('ads');
  const nav = useNavigate();

  const [mode] = useState(initialData !== undefined ? 'edit' : 'create');
  const { brandId } = useParams();
  const [linkOffersModalOpen, setLinkOffersModalOpen] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [discardModalOpen, setDiscardModalOpen] = useState(false);
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [changeCategorySelection, setChangeCategorySelection] = useState(false);

  const currentDate = new Date().toISOString().split('T')[0];
  const currentTime = `${new Date()
    .getUTCHours()
    .toString()
    .padStart(2, '0')}:${new Date().getUTCMinutes().toString().padStart(2, '0')}`;

  const currentUpdateTime = `${new Date()
    .getUTCHours()
    .toString()
    .padStart(2, '0')}:${new Date().getUTCMinutes().toString().padStart(2, '20')}`;

  const formMethods = useForm<AdFormSchema>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: initialData || {
      imagePath: '',
      googleAdId: '',
      title: '',
      beginDate: currentDate,
      endDate: '',
      beginTime: currentTime,
      endTime: '',
      categories: [],
      followedCategories: [],
      offers: [],
    },
  });
  const {
    trigger,
    handleSubmit,
    watch,
    reset,
    getValues,
    control,
    formState: { errors, isDirty },
  } = formMethods;

  const formWatcher = watch();
  const { append: appendOffers } = useFieldArray({
    control,
    name: 'offers',
  });
  const onLinkOffers = (offersAdded: OfferResponse[]) => {
    appendOffers(
      offersAdded?.map(offer => ({
        ...offer,
        beginDate: offer?.startTime?.split('T')[0] || '',
        beginTime: offer?.startTime?.split('T')[1].slice(0, -4) || '',
        endDate: offer?.expiryTime?.split('T')[0] || '',
        endTime: offer?.expiryTime?.split('T')[1].slice(0, -4) || '',
        redemptionType: offer?.redemptionType,
        offerStatus: offer?.offerStatus,
        boosts: offer.boosts?.map(boost => ({
          ...boost,
          id: boost.id || '',
          boostAvailable: true,
        })),
        offerCodes: offer.offerCodes,
      }))
    );
  };

  useEffect(() => {
    setTimeout(() => { setSaveModalOpen(false) }, 500)
  }, [isSubmitting])


  return (
    <div className="w-full mx-auto flex flex-col gap-6" data-testid="ad-form">
      <div className="flex gap-2">
        <FormProvider {...formMethods}>
          <form className="flex flex-col gap-6 w-full">
            <Accordion
              defaultOpen
              type="advert"
              title={
                <>
                  {/* <h1 className="w-[90px] text-left text-lg">{t('ad')}</h1>{' '} */}
                  {mode === 'edit' ? (
                    <h2
                      className="w-[340px] text-left text-ellipsis"
                      aria-label="ad title"
                    >
                      {getValues('title') ? getValues('title') : 'Ad Title'}
                    </h2>
                  ) : (
                    <span className="w-[340px]" />
                  )}
                  <div className="flex flex-row text-white">
                    <p>{t('form.dates')}</p>
                    <div className="flex flex-row ml-4 text-white gap-2">
                      <p>{getValues('beginDate').split('-').reverse().join('/')}</p>-
                      <p>{getValues('endDate').split('-').reverse().join('/')}</p>
                    </div>
                  </div>
                </>
              }
            >
              <AdFormDetails adIsActive={adIsActive} getValues={getValues}
                changeCategorySelection={(flag: any) => setChangeCategorySelection(flag)}
                categoryBackArr={(item: any) => categoryBackArr(item)}
                categoryChildBackArr={(item: any) => categoryChildBackArr(item)}
                categoriesCheckArr={categoriesCheckArr}
              />
              <AdFormSchedule adIsActive={adIsActive} />
              <AdFormImage defaultImage={getValues('imageUrl') || undefined} />
            </Accordion>


            <div className="flex flex-row justify-between w-full mt-4">
              <h2 className="font-semibold my-3 text-[24px]">Create Offer</h2>

              <Button
                type="outline"
                onClick={() => {
                  appendOffers({
                    brandId: getValues('brandId'),
                    imageUrl: '',
                    requiredCoins: 1,
                    imagePath: '',
                    title: '',
                    subtitle: '',
                    description: '',
                    enableHalo: false,
                    enableWeightage: false,
                    beginDate: currentDate,
                    beginTime: currentTime,
                    endDate: currentDate,
                    startTime: currentTime,
                    expiryTime: currentUpdateTime,
                    endTime: currentUpdateTime,
                    redemptionType: 'CODE',
                    offerStatus: 'Publish',
                    offerCodes: undefined,
                    boosts: [],
                  });
                }}
              >
                Create Offer
              </Button>
            </div>
            {formWatcher.offers?.length ? formWatcher.offers?.map((offer, index) => {
              const now = new Date();
              const start = offer.startTime ? new Date(offer.startTime) : now;
              let offerIsActive = false;
              if (start < now) {
                offerIsActive = true;
              }
              return (
                <AdOfferForm
                  key={index}
                  offer={offer}
                  index={index}
                  backCountry={(e: any) => { setChangeCategorySelection(true); backCountry(e) }}
                  offerIsActive={offerIsActive}
                />
              );
            }) :
              <div className="w-full rounded-xl flex flex-row justify-center">
                <p className="mb-3 mt-4 text-textSecondary">No Offer available</p>
              </div>
            }


            {/* <div className="w-full bg-lightBlue rounded-xl p-4 h-[237px]">
              <h2>Offer</h2>
              <div className="m-auto w-fit flex flex-col items-center mt-7">
                <Button
                  type="outline"
                  onClick={() => {
                    appendOffers({
                      brandId: getValues('brandId'),
                      imageUrl: '',
                      requiredCoins: 1,
                      imagePath: '',
                      title: '',
                      subtitle: '',
                      description: '',
                      enableHalo: false,
                      enableWeightage: false,
                      beginDate: currentDate,
                      beginTime: '',
                      endDate: '',
                      startTime: currentTime,
                      expiryTime: '',
                      endTime: '',
                      redemptionType: 'CODE',
                      offerStatus: 'Publish',
                      offerCodes: undefined,
                      boosts: [],
                    });
                  }}
                >
                  Create Offer
                </Button>
                <p className="mt-4">
                  Or{' '}
                  <button
                    type="button"
                    className="text-primary underline"
                    onClick={() =>
                      errors.expiryTime ||
                        errors.endTime ||
                        getValues('endDate') === '' ||
                        getValues('endTime') === ''
                        ? setScheduleModalOpen(true)
                        : setLinkOffersModalOpen(true)
                    }
                  >
                    link an existing Offer
                  </button>
                </p>
              </div>
            </div> */}

          </form>
        </FormProvider>
      </div>

      <Modal
        type="large"
        isOpen={scheduleModalOpen}
        setIsOpen={setScheduleModalOpen}
        height="max-h-[900px]"
      >
        <div className="flex flex-col gap-4 w-full mb-9">
          <h2 className="text-lg font-semibold">Please enter a schedule</h2>
          <p className="text-base">
            You must create a schedule within your Ad in order to link an Offer.
          </p>
        </div>
        <div className="flex justify-end">
          <Button
            type="secondary"
            onClick={() => {
              setScheduleModalOpen(false);
            }}
          >
            Ok
          </Button>
        </div>
      </Modal>
      <LinkOffersModal
        open={linkOffersModalOpen}
        setOpen={setLinkOffersModalOpen}
        brandId={brandId}
        onLinkOffers={onLinkOffers}
        offersLinkedToAd={getValues('offers') || []}
        startTime={
          getValues(`beginDate`) && getValues(`beginTime`)
            ? `${getValues(`beginDate`)}T${getValues(`beginTime`)}:00Z`
            : undefined
        }
        endTime={
          getValues(`endDate`) && getValues(`endTime`)
            ? `${getValues(`endDate`)}T${getValues(`endTime`)}:00Z`
            : undefined
        }
      />

      <Modal
        type="large"
        isOpen={saveModalOpen}
        setIsOpen={setSaveModalOpen}
        height={Object.keys(errors).length > 0 ? '!h-[400px]' : '!h-[240px]'}
      >
        <div className="h-full flex flex-col justify-between">
          <div>
            <h2 className="text-[20px] font-semibold mb-3">
              {mode === 'create' ? t('button.create') : t('button.update')}
            </h2>
            <p>{t('form.modal.createA')}</p>
            <p className="mt-4">{t('form.modal.createB')}</p>

            {advertError && <p className="text-secondary">{advertError}</p>}
            {Object.keys(errors).length > 0 && (
              <>
                {getAllFormErrors(errors)?.length > 0 && <p>Please enter valid:</p>}
                <div className="h-[150px] overflow-y-auto">
                  {getAllFormErrors(errors).map((error, i) => (
                    <p key={i} className="text-secondary">
                      • {error}
                    </p>
                  ))}
                  {getAllFormErrors(errors)?.length === 0 &&
                    <p className="text-secondary">
                      • Please fill red marked fields
                    </p>
                  }
                </div>
              </>
            )}
          </div>
          <div className="flex justify-between">
            <Button type="text" onClick={() => setSaveModalOpen(false)}>
              {t('button.create-back')}
            </Button>
            <Button
              type="secondary"
              loading={isSubmitting}
              onClick={handleSubmit(onSubmit)}
              disabled={Object.keys(errors).length > 0}
            >
              {mode === 'create' ? t('form.create') : t('form.update')}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal type="small" isOpen={discardModalOpen} setIsOpen={setDiscardModalOpen}>
        <div className="h-full flex flex-col justify-between">
          <div>
            <h2 className="text-[20px] font-semibold mb-3">
              {t('form.modal.cancel-title')}
            </h2>
            <p>{t('form.modal.cancel-info')}</p>
          </div>
          <div className="flex justify-between">
            <Button type="text" onClick={() => setDiscardModalOpen(false)}>
              {t('button.cancel-back')}
            </Button>
            <Button
              type="secondary"
              onClick={() => {
                reset();
                nav(`/ads/${getValues('brandId')}`);
              }}
            >
              {t('button.cancel-proceed')}
            </Button>
          </div>
        </div>
      </Modal>
      {(isDirty || changeCategorySelection) &&
        <StickyFooter
          discard={() => setDiscardModalOpen(true)}
          save={() => {
            trigger();
            // if (!advertError) {
            setSaveModalOpen(true);
            // }
          }}
          brandError={advertError}
          positiveCTAText={mode === 'create' ? t('form.create') : t('form.update')}
        />
      }
    </div>
  );
};

export default AdForm;
