import clsx from 'clsx';
import { FC, useState } from 'react';

interface Props {
  className?: string;
  startDate?: string;
  endDate?: string;
  state?: string; // 'LIVE' | 'EXPIRED' | 'SCHEDULED';
}
const StatusTag: FC<Props> = ({ className, startDate, endDate, state }) => {
  const now = new Date();
  const start = startDate ? new Date(startDate) : now;
  const end = endDate ? new Date(endDate) : now;

  const [status] = useState(() => {
    if ((start < now && end > now) || state === 'LIVE') {
      return 'Active';
    }
    if ((start > now && end > now) || state === 'SCHEDULED') {
      return 'Scheduled';
    }
    if ((start < now && end < now) || state === 'EXPIRED') {
      return 'Expired';
    }
    throw new Error('Invalid dates!');
  });

  return (
    <div
      className={clsx(
        'text-md rounded-3xl w-24 text-statusText text-center py-2 px-4',
        {
          'bg-statusActive': status === 'Active',
        },
        {
          'bg-statusExpired text': status === 'Expired',
        },
        {
          'bg-statusScheduled': status === 'Scheduled',
        },
        className
      )}
    >
      {status}
    </div>
  );
};

export default StatusTag;
