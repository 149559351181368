import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Icon from '../../UI/Icon/Icon';
import { AdFormSchema } from './AdFormTypes';

interface AdFormScheduleProps {
  adIsActive: boolean;
}

const AdFormSchedule = ({ adIsActive }: AdFormScheduleProps) => {
  const { t } = useTranslation('ads');

  const {
    register,
    trigger,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<AdFormSchema>();

  const currentDate = new Date().toISOString().split('T')[0];
  const currentTime = `${new Date()
    .getUTCHours()
    .toString()
    .padStart(2, '0')}:${new Date().getUTCMinutes().toString().padStart(2, '0')}`;
  const formWatcher = watch();

  return (
    <div className="flex flex-col bg-white rounded-md h-fit p-4 gap-4">
      <h2 className="text-lg font-semibold">{t('form.ad-schedule')}</h2>
      <p className="text-[#666666]">{t('form.ad-schedule-text')}</p>


      <div className="flex flex-row justify-between w-full">

        <div style={{ width: "48%" }} className=" flex flex-row justify-between ">
          <label style={{ width: "48%" }} className=" relative">
            <p className="font-semibold">{t('form.start-date')}</p>
            <Icon
              name="date"
              width="20px"
              className="absolute top-[36px] left-4 z-10 pointer-events-none"
            />
            <input
              type="date"
              data-testid="start-date-input"
              min={currentDate}
              // disabled={adIsActive}
              id="input-start-date-picker"
              className={clsx('input w-full pl-10', errors.beginDate && 'border-error')}
              {...register('beginDate', {
                required: 'Start date is required.',
                onBlur: () => {
                  trigger(['beginTime']);
                },
                onChange: event => {
                  if (new Date(event.target.value) > new Date(formWatcher.endDate)) {
                    setValue('endDate', '');
                  }
                },
              })}
            />
            <p className="text-error mt-2" role="alert">
              {errors.beginDate?.message}
            </p>
          </label>

          <label style={{ width: "48%" }} className=" relative">
            <p className="font-semibold">{t('form.start-time')}</p>
            <Icon
              name="time"
              width="20px"
              className="absolute top-[36px] left-4 z-10 pointer-events-none"
            />
            <input
              type="time"
              data-testid="start-time-input"
              // disabled={adIsActive}
              className={clsx('input w-full pl-10', errors.beginTime && 'border-error')}
              {...register('beginTime', {
                required: 'Start time is required.',
                onBlur: () => {
                  trigger(['endTime']);
                },
                validate: value => {
                  if (value && !adIsActive) {
                    const val = value.replace(':', '');
                    const endTime = formWatcher.endTime.replace(':', '');
                    const currTime = currentTime.replace(':', '');
                    if (formWatcher.beginDate === formWatcher.endDate && +val > +endTime) {
                      return 'Start time can not be later than end time.';
                    }
                    if (formWatcher.beginDate === currentDate && +val < +currTime) {
                      return 'Start time can not be less than current time.';
                    }
                  }
                  return true;
                },
              })}
            />
            <span className="absolute top-[36px] right-3 text-dark-grey-text pointer-events-none">
              UTC
            </span>
            <p className="text-error mt-2" role="alert">
              {errors.beginTime?.message}
            </p>
          </label>
        </div>
        <div style={{ width: "48%" }} className="flex flex-row justify-between">
          <label style={{ width: "48%" }} className=" relative">
            <p className="font-semibold">{t('form.end-date')}</p>
            <Icon
              name="date"
              width="20px"
              className="absolute top-[36px] left-4 z-10 pointer-events-none"
            />
            <input
              type="date"
              data-testid="end-date-input"
              // disabled={adIsActive}
              min={formWatcher.beginDate}
              className={clsx('input w-full pl-10', errors.endDate && 'border-error')}
              {...register('endDate', {
                onBlur: () => {
                  trigger(['beginDate', 'beginTime', 'endTime']);
                },
                required: 'End date is required.',
              })}
            />
            <p className="text-error mt-2" role="alert">
              {errors.endDate?.message}
            </p>
          </label>

          <label style={{ width: "48%" }} className=" relative">
            <p className="font-semibold">{t('form.end-time')}</p>
            <Icon
              name="time"
              width="20px"
              className="absolute top-[36px] left-4 z-10 pointer-events-none"
            />
            <input
              type="time"
              data-testid="end-time-input"
              // disabled={adIsActive}
              className={clsx('input w-full pl-10', errors.endTime && 'border-error')}
              {...register('endTime', {
                required: 'End time is required.',
                onBlur: () => {
                  trigger(['beginTime']);
                },
                validate: value => {
                  if (!adIsActive && formWatcher.beginDate === formWatcher.endDate) {
                    const val = value.replace(':', '');
                    const startTime = formWatcher.beginTime.replace(':', '');
                    if (+val < +startTime) {
                      return 'End time can not be earlier than start time.';
                    }
                  }
                  return true;
                },
              })}
            />
            <span className="absolute top-[36px] right-3 text-dark-grey-text pointer-events-none">
              UTC
            </span>
            <p className="text-error mt-2" role="alert">
              {errors.endTime?.message}
            </p>
          </label>
        </div>

      </div>

    </div>
  );
};

export default AdFormSchedule;
