import axios from 'axios';
import { getAccessToken } from './TokenService';

// class Service {
const token = getAccessToken();

export const getRequest = (url: string, data: any) => {
  return axios.get(url, {
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    params: data,
    validateStatus: (status: any) => {
      if (status === 401) {
        window.location.replace(`/login`);
        return false
        // eslint-disable-next-line
      } else {
        return status >= 200 && status <= 400
      }
    },
  });
};

export const postRequest = (url: string, body: any) => {
  return axios.post(url, body, {
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    validateStatus: (status: any) => {
      if (status === 401) {
        window.location.replace(`/login`);
        return false
        // eslint-disable-next-line
      } else {
        return status >= 200 && status <= 400
      }
    },
  });
};
export const postWithoutAuthRequest = (url: string, body: any) => {
  return axios.post(url, body, {
    headers: {
      'Content-Type': 'application/json',
    },
    validateStatus: (status: any) => {
      if (status === 401) {
        window.location.replace(`/login`);
        return false
        // eslint-disable-next-line
      } else {
        return status >= 200 && status <= 400
      }
    },
  });
};

export const postLoginRequest = (url: string | undefined, body: any) => {
  return axios.post(url?.length ? url : '', body, {
    headers: {
      'Content-Type': 'application/json',
    },
    validateStatus: (status: any) => status >= 200 && status <= 404,
  });
};

export const deleteRequest = (url: string, body: any) => {
  return axios.delete(url, {
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    validateStatus: (status: any) => {
      if (status === 401) {
        window.location.replace(`/login`);
        return false
        // eslint-disable-next-line
      } else {
        return status >= 200 && status <= 400
      }
    },
  });
};

export const putRequest = (url: string, body: any) => {
  return axios.put(url, body, {
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    validateStatus: (status: any) => {
      if (status === 401) {
        window.location.replace(`/login`);
        return false
        // eslint-disable-next-line
      } else {
        return status >= 200 && status <= 400
      }
    },
  });
};

export const patchRequest = (url: string, id: string, body: any) => {
  return axios.patch(`${url}${id}/`, body, {
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    validateStatus: (status: any) => {
      if (status === 401) {
        window.location.replace(`/login`);
        return false
        // eslint-disable-next-line
      } else {
        return status >= 200 && status <= 400
      }
    },
  });
};
// }

// const ApiService = new Service();

// export default ApiService;
