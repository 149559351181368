import { Menu as HeadlessMenu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { FC, Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../context/AuthContext';

type MenuProps = {
  trigger: JSX.Element;
  position: 'left' | 'right' | 'center';
};

const Menu: FC<MenuProps> = ({ trigger, position = 'center' }) => {
  const { t } = useTranslation('common');
  const { logout } = useContext(AuthContext);

  return (
    <HeadlessMenu as="div" className="inline-block relative">
      <HeadlessMenu.Button as={Fragment}>{trigger}</HeadlessMenu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <HeadlessMenu.Items
          className={clsx(
            'overflow-hidden absolute z-30 mt-3 w-full rounded-md opacity-0 scale-95 origin-top-right bg-white p-4 shadow-lg',
            { 'right-0': position === 'left' },
            { 'right-1/2 translate-x-1/2': position === 'center' }
          )}
        >
          <HeadlessMenu.Item>
            {() => (
              <button
                className="bg-white p-2 hover:bg-lightBlue w-full rounded-lg"
                type="button"
                onClick={() => logout()}
              >
                {t('buttons.signout')}
              </button>
            )}
          </HeadlessMenu.Item>
        </HeadlessMenu.Items>
      </Transition>
    </HeadlessMenu>
  );
};

export default Menu;
