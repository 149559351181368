import { RadioGroup as HeadlessRadio } from '@headlessui/react';
import clsx from 'clsx';
import { FC, Fragment, useId, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrandOfferResponseOfferListItem } from '../../../api';
import Icon from '../../UI/Icon/Icon';
import Checkbox from '../../UI/Toggles/Checkbox/Checkbox';

interface Props {
  item: BrandOfferResponseOfferListItem;
  changes: Map<string, boolean>;
  setChanges: (val: Map<string, boolean>) => void;
  onClick: () => void;
  isDraft?: boolean;
}

const ListOfferLibrary: FC<Props> = ({ item, changes, onClick, setChanges, isDraft }) => {
  const { t } = useTranslation('offersLibrary');
  const [adTitles, setAdTitles] = useState<string[]>([]);
  const uniqueId = useId();

  const change = changes.get(item.id);
  const onBrandChecked = change !== undefined ? change : item.shownOn === 'BRAND_PAGE';

  const handleToggleShowOn = () => {
    const newState = !onBrandChecked;

    if (changes.get(item.id) !== undefined) {
      changes.delete(item.id);
      setChanges(new Map([...changes]));
    } else {
      setChanges(new Map(changes.set(item.id, newState)));
    }
  };

  return (
    <tr aria-label="offer">
      <td className={`p-4 ${isDraft && 'w-40'}`}>
        <img src={item.imageUrl} alt="Offer" className="w-40 rounded-lg" />
      </td>
      <td>
        <button type="button" onClick={onClick}>
          <p className="text-[1.125rem] hover:underline cursor-pointer">{item.title}</p>
        </button>
      </td>
      {!isDraft ? <>
        <td>
          <h4 data-testid="offer-boosts">{item.numberOfBoosts}</h4>
        </td>
        <td
          className={clsx('relative', item.ads?.length > 0 && 'group')}
          data-testid="ad-focusable-el"
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
          onMouseOver={() => {
            setAdTitles(item.ads?.map(obj => obj.title));
          }}
          onFocus={() => {
            setAdTitles(item.ads?.map(obj => obj.title));
          }}
        >
          <h4>
            {item.ads?.length || 0} {item.ads?.length === 1 ? t('ad') : t('ads')}
            <span
              data-testid="ads-tooltip"
              className="absolute hidden group-hover:flex group-hover:flex-col group-focus:flex group-focus:flex-col -left-[412px] top-[264px] -translate-y-full w-[500px] h-[170px] p-5 bg-[#3F628C] rounded-lg text-white text-md z-20 after:content-[''] after:absolute after:left-[432px] after:-top-7 after:-translate-x-1/2 after:border-[14px] after:border-r-transparent after:border-t-transparent after:border-b-[#3F628C] after:border-l-transparent"
            >
              <ol>
                {item.ads?.length > 0 &&
                  adTitles.map((ad, index) => {
                    if (index > 2) return <Fragment key={uniqueId + index} />;
                    return (
                      <li className="mb-1" key={uniqueId + index} data-testid="ad-item">
                        {index + 1}. {ad}
                      </li>
                    );
                  })}
              </ol>
            </span>
          </h4>
        </td>
        <td>
          <span className="block w-[24px] h-[24px]">
            <Checkbox
              type="tick"
              name="offerInformationCheckbox"
              checked={onBrandChecked}
              id={`${item.id}-offerOnBrandPage`}
              onChange={handleToggleShowOn}
              className="checked:!bg-primary !border-primary"
            />
          </span>
        </td>
        <td>
          <span className="block w-[24px] h-[24px]">
            <HeadlessRadio.Option
              data-testid={`radio-gift-${item.id}`}
              key={item.id}
              value={item.id}
              className={() =>
                clsx('gap-3 rounded-lg transition-colors selector-base flex items-center')
              }
            >
              {({ checked }) => (
                <>
                  <div
                    className={clsx(
                      'flex shrink-0 justify-center items-center  border-primary w-[26px] h-[26px] rounded-full border-2 transition-colors',
                      { 'border-selector-border-1': !checked },
                      {
                        'border-popup-selector-active': !checked,
                      },
                      {
                        'bg-transparent': !checked,
                      }
                    )}
                  >
                    <Icon
                      name="radio-large"
                      className={clsx('flex items-center w-[12px] h-[12px]', {
                        invisible: !checked,
                      })}
                    />
                  </div>
                </>
              )}
            </HeadlessRadio.Option>
          </span>
        </td>
      </> : null}

    </tr>
  );
};

export default ListOfferLibrary;
