/* tslint:disable */
/* eslint-disable */
/**
 * Tickle Admin Api
 * Admin api to be used by portal
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';
import { Configuration } from './configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, createRequestFunction, serializeDataIfNeeded, setSearchParams, toPathString } from './common';
// @ts-ignore
import { BASE_PATH, BaseAPI, RequestArgs, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddBrandRequest
 */
export interface AddBrandRequest {
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'handle': string;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'partnerStatus': string;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'budgetRemaining'?: string | number;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'budgetTotal'?: string | number;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'numberOfCoins': string | number;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'magicLink': string;
    /**
     * 
     * @type {boolean}
     * @memberof BrandResponse
     */
    'isHotKeyNotificationSent'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'brandWebsite': string;
    /**
     * 
     * @type {boolean}
     * @memberof BrandResponse
     */
    'newsletter': boolean;
    /**
     * 
     * @type {any}
     * @memberof BrandResponse
     */
    'brandContactsForClaims': any | undefined;
    /**
     * 
     * @type {any}
     * @memberof BrandResponse
     */
    'brandContactsForNewsLetter': any | undefined;
    /**
     * 
     * @type {any}
     * @memberof BrandResponse
     */
    'country'?: any | undefined;
    /**
     * 
     * @type {any}
     * @memberof BrandResponse
     */
    'isAutomatedEmail': any | undefined;
    /**
     * 
     * @type {boolean}
     * @memberof BrandResponse
     */
    'claimSample': boolean;
    /**
     * 
     * @type {any}
     * @memberof BrandResponse
     */
    'brandClaimSamples'?: any;
    /**
     * 
     * @type {boolean}
     * @memberof BrandResponse
     */
    'downloadApp': boolean;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'downloadAppDescription': string;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'downloadAppLink': string;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'coverImagePath'?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'logoImagePath'?: string;
    /**
     * 
     * @type {Array<CategoryRequest>}
     * @memberof BrandResponse
     */
    'categories'?: Array<CategoryRequest>;
    /**
     * 
     * @type {Array<CategoryRequest>}
     * @memberof BrandResponse
     */
    'parentCategories'?: Array<CategoryRequest>;
    /**
     * 
     * @type {Array<CategoryRequest>}
     * @memberof BrandResponse
     */
    'childCategories'?: Array<CategoryRequest>;
    /**
     * 
     * @type {Array<CategoryRequest>}
     * @memberof EditBrandRequest
     */
    'followedCategories'?: Array<CategoryRequest>;
    /**
     * 
     * @type {Array<CategoryRequest>}
     * @memberof EditBrandRequest
     */
    'followedParentCategories'?: Array<CategoryRequest>;
    /**
     * 
     * @type {Array<CategoryRequest>}
     * @memberof EditBrandRequest
     */
    'followedChildCategories'?: Array<CategoryRequest>;
}
/**
 * 
 * @export
 * @interface AddEditAdvertRequest
 */
export interface AddEditAdvertRequest {
    /**
     * 
     * @type {string}
     * @memberof AddEditAdvertRequest
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddEditAdvertRequest
     */
    'googleAdId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddEditAdvertRequest
     */
    'countries'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddEditAdvertRequest
     */
    'imagePath'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddEditAdvertRequest
     */
    'startTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddEditAdvertRequest
     */
    'expiryTime'?: string;
    /**
     * 
     * @type {Array<CategoryRequest>}
     * @memberof AddEditAdvertRequest
     */
    'categories'?: Array<CategoryRequest>;
    /**
     * 
     * @type {Array<CategoryRequest>}
     * @memberof AddEditAdvertRequest
     */
    'parentCategories'?: Array<CategoryRequest>;
    /**
     * 
     * @type {Array<CategoryRequest>}
     * @memberof AddEditAdvertRequest
     */
    'childCategories'?: Array<CategoryRequest>;
    /**
     * 
     * @type {Array<CategoryRequest>}
     * @memberof AddEditAdvertRequest
     */
    'followedCategories'?: Array<CategoryRequest>;
    /**
     * 
     * @type {Array<CategoryRequest>}
     * @memberof AddEditAdvertRequest
     */
    'followedParentCategories'?: Array<CategoryRequest>;
    /**
     * 
     * @type {Array<CategoryRequest>}
     * @memberof AddEditAdvertRequest
     */
    'followedChildCategories'?: Array<CategoryRequest>;
    /**
     * 
     * @type {Array<AddEditOfferRequest>}
     * @memberof AddEditAdvertRequest
     */
    'offers'?: Array<AddEditOfferRequest>;
}
/**
 * 
 * @export
 * @interface AddEditBoostRequest
 */
export interface AddEditBoostRequest {
    /**
     * 
     * @type {string}
     * @memberof AddEditBoostRequest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AddEditBoostRequest
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddEditBoostRequest
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddEditBoostRequest
     */
    'subtitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddEditBoostRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddEditBoostRequest
     */
    'imagePath'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddEditBoostRequest
     */
    'redemptionType'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddEditBoostRequest
     */
    'offerStatus'?: string;
    /**
     * 
     * @type {any}
     * @memberof AddEditBoostRequest
     */
    'countries'?: any;
    /**
     * 
     * @type {number}
     * @memberof AddEditBoostRequest
     */
    'weightage'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddEditBoostRequest
     */
    'quantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof AddEditBoostRequest
     */
    'redemptionUrl'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddEditBoostRequest
     */
    'boostAvailable'?: boolean;
    /**
     * 
     * @type {Halo}
     * @memberof AddEditBoostRequest
     */
    'halo'?: Halo;
}
/**
 * 
 * @export
 * @interface AddEditOfferRequest
 */
export interface AddEditOfferRequest {
    /**
     * 
     * @type {string}
     * @memberof AddEditOfferRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddEditOfferRequest
     */
    'brandId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddEditOfferRequest
     */
    'title'?: string;
    /**
     * 
     * @type {number}
     * @memberof AddEditOfferRequest
     */
    'requiredCoins'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AddEditOfferRequest
     */
    'enableWeightage'?: boolean | any;
    /**
     * 
     * @type {boolean}
     * @memberof AddEditOfferRequest
     */
    'enableHalo'?: boolean | any;
    /**
     * 
     * @type {string}
     * @memberof AddEditOfferRequest
     */
    'subtitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddEditOfferRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddEditOfferRequest
     */
    'imagePath'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddEditOfferRequest
     */
    'startTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddEditOfferRequest
     */
    'expiryTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddEditOfferRequest
     */
    'redemptionType'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddEditOfferRequest
     */
    'offerStatus'?: string;
    /**
     * 
     * @type {any}
     * @memberof AddEditOfferRequest
     */
    'countries'?: any;
    /**
     * 
     * @type {any}
     * @memberof AddEditOfferRequest
     */
    'categories'?: any;
    /**
     * 
     * @type {any}
     * @memberof AddEditOfferRequest
     */
    'followedCategories'?: any;
    /**
     * 
     * @type {any}
     * @memberof AddEditOfferRequest
     */
    'followedParentCategories'?: any;
    /**
     * 
     * @type {any}
     * @memberof AddEditOfferRequest
     */
    'followedChildCategories'?: any;
    /**
     * 
     * @type {string}
     * @memberof AddEditOfferRequest
     */
    'redemptionUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof AddEditOfferRequest
     */
    'usageLimit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AddEditOfferRequest
     */
    'showOn'?: string;
    /**
     * 
     * @type {Array<AddEditBoostRequest>}
     * @memberof AddEditOfferRequest
     */
    'boosts'?: Array<AddEditBoostRequest>;
}
/**
 * 
 * @export
 * @interface AdvertisementResponse
 */
export interface AdvertisementResponse {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementResponse
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementResponse
     */
    'brandId': string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementResponse
     */
    'googleAdId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddEditAdvertRequest
     */
    'countries'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementResponse
     */
    'imagePath': string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementResponse
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementResponse
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementResponse
     */
    'expiryTime': string;
    /**
     * 
     * @type {Array<CategoryRequest>}
     * @memberof AdvertisementResponse
     */
    'categories'?: Array<CategoryRequest>;
    /**
     * 
     * @type {Array<CategoryRequest>}
     * @memberof AdvertisementResponse
     */
    'parentCategories'?: Array<CategoryRequest>;
    /**
     * 
     * @type {Array<CategoryRequest>}
     * @memberof AdvertisementResponse
     */
    'childCategories'?: Array<CategoryRequest>;
    /**
     * 
     * @type {Array<CategoryRequest>}
     * @memberof AdvertisementResponse
     */
    'followedCategories'?: Array<CategoryRequest>;
    /**
     * 
     * @type {Array<CategoryRequest>}
     * @memberof AdvertisementResponse
     */
    'followedParentCategories'?: Array<CategoryRequest>;
    /**
     * 
     * @type {Array<CategoryRequest>}
     * @memberof AdvertisementResponse
     */
    'followedChildCategories'?: Array<CategoryRequest>;
    /**
     * 
     * @type {Array<OfferResponse>}
     * @memberof AdvertisementResponse
     */
    'offers'?: Array<OfferResponse>;
}
/**
 * 
 * @export
 * @interface AffiliateOfferUploadFailedRowResponse
 */
export interface AffiliateOfferUploadFailedRowResponse {
    /**
     * 
     * @type {number}
     * @memberof AffiliateOfferUploadFailedRowResponse
     */
    'index': number;
    /**
     * 
     * @type {UploadedCsvAffiliatedOfferRow}
     * @memberof AffiliateOfferUploadFailedRowResponse
     */
    'row': UploadedCsvAffiliatedOfferRow;
    /**
     * 
     * @type {string}
     * @memberof AffiliateOfferUploadFailedRowResponse
     */
    'error': string;
}
/**
 * 
 * @export
 * @interface AffiliateOfferUploadResponse
 */
export interface AffiliateOfferUploadResponse {
    /**
     * 
     * @type {number}
     * @memberof AffiliateOfferUploadResponse
     */
    'successfullyProcessedTotal': number;
    /**
     * 
     * @type {Array<AffiliateOfferUploadFailedRowResponse>}
     * @memberof AffiliateOfferUploadResponse
     */
    'failedRows': Array<AffiliateOfferUploadFailedRowResponse>;
}
/**
 * 
 * @export
 * @interface BoostResponse
 */
export interface BoostResponse {
    /**
     * 
     * @type {string}
     * @memberof BoostResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoostResponse
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoostResponse
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoostResponse
     */
    'subtitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoostResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoostResponse
     */
    'imagePath'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoostResponse
     */
    'imageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoostResponse
     */
    'redemptionType'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoostResponse
     */
    'offerStatus'?: string;
    /**
     * 
     * @type {any}
     * @memberof BoostResponse
     */
    'countries'?: any;
    /**
     * 
     * @type {number}
     * @memberof AddEditBoostRequest
     */
    'weightage'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddEditBoostRequest
     */
    'quantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof BoostResponse
     */
    'redemptionUrl'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BoostResponse
     */
    'boostAvailable'?: boolean;
    /**
     * 
     * @type {Halo}
     * @memberof BoostResponse
     */
    'halo'?: Halo;
}
/**
 * 
 * @export
 * @interface BrandAd
 */
export interface BrandAd {
    /**
     * 
     * @type {string}
     * @memberof BrandAd
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BrandAd
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof BrandAd
     */
    'title': string;
    /**
     * State of the brand ad
     * @type {string}
     * @memberof BrandAd
     */
    'state': string;
}
/**
 * 
 * @export
 * @interface BrandAdsResponse
 */
export interface BrandAdsResponse {
    /**
     * 
     * @type {Array<BrandAd>}
     * @memberof BrandAdsResponse
     */
    'ads': Array<BrandAd>;
    /**
     * 
     * @type {BrandAdsTotals}
     * @memberof BrandAdsResponse
     */
    'totals': BrandAdsTotals;
}
/**
 * 
 * @export
 * @interface BrandAdsTotals
 */
export interface BrandAdsTotals {
    /**
     * 
     * @type {number}
     * @memberof BrandAdsTotals
     */
    'ads': number;
    /**
     * 
     * @type {number}
     * @memberof BrandAdsTotals
     */
    'offers': number;
    /**
     * 
     * @type {number}
     * @memberof BrandAdsTotals
     */
    'boosts': number;
}
/**
 * 
 * @export
 * @interface BrandListItem
 */
export interface BrandListItem {
    /**
     * 
     * @type {string}
     * @memberof BrandListItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BrandListItem
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof BrandListItem
     */
    'logoUrl': string;
    /**
     * 
     * @type {string}
     * @memberof BrandListItem
     */
    'coverImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof BrandListItem
     */
    'handle': string;
    /**
     * 
     * @type {number}
     * @memberof BrandListItem
     */
    'totalAdverts': number;
    /**
     * 
     * @type {number}
     * @memberof BrandListItem
     */
    'totalOffers': number;
    /**
     * 
     * @type {string}
     * @memberof BrandListItem
     */
    'partnerStatus': string;
}
/**
 * 
 * @export
 * @interface BrandOfferResponse
 */
export interface BrandOfferResponse {
    /**
     * 
     * @type {Array<BrandOfferResponseOfferListItem>}
     * @memberof BrandOfferResponse
     */
    'items': Array<BrandOfferResponseOfferListItem>;
    /**
     * 
     * @type {BrandOfferResponseTotals}
     * @memberof BrandOfferResponse
     */
    'totals': BrandOfferResponseTotals;
}
/**
 * 
 * @export
 * @interface BrandOfferResponseOfferListItem
 */
export interface BrandOfferResponseOfferListItem {
    /**
     * 
     * @type {string}
     * @memberof BrandOfferResponseOfferListItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BrandOfferResponseOfferListItem
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof BrandOfferResponseOfferListItem
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof BrandOfferResponseOfferListItem
     */
    'imageUrl': string;
    /**
     * 
     * @type {number}
     * @memberof BrandOfferResponseOfferListItem
     */
    'numberOfBoosts': number;
    /**
     * 
     * @type {Array<BrandOfferResponseOfferListItemAdListItem>}
     * @memberof BrandOfferResponseOfferListItem
     */
    'ads': Array<BrandOfferResponseOfferListItemAdListItem>;
    /**
     * 
     * @type {boolean}
     * @memberof BrandOfferResponseOfferListItem
     */
    'shownOnBrandPage': boolean;
    /**
     * 
     * @type {string}
     * @memberof BrandOfferResponseOfferListItem
     */
    'shownOn': string;
    /**
     * 
     * @type {string}
     * @memberof BrandOfferResponseOfferListItem
     */
    'offerStatus'?: string;
    /**
     * 
     * @type {any}
     * @memberof BrandOfferResponseOfferListItem
     */
    'countries'?: any;
}
/**
 * 
 * @export
 * @interface BrandOfferResponseOfferListItemAdListItem
 */
export interface BrandOfferResponseOfferListItemAdListItem {
    /**
     * 
     * @type {string}
     * @memberof BrandOfferResponseOfferListItemAdListItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BrandOfferResponseOfferListItemAdListItem
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface BrandOfferResponseTotals
 */
export interface BrandOfferResponseTotals {
    /**
     * 
     * @type {number}
     * @memberof BrandOfferResponseTotals
     */
    'offers': number;
    /**
     * 
     * @type {number}
     * @memberof BrandOfferResponseTotals
     */
    'boosts': number;
}
/**
 * 
 * @export
 * @interface BrandResponse
 */
export interface BrandResponse {
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'logoImagePath': string;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'logoUrl': string;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'coverImagePath': string;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'downloadAppImage'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBrandRequest
     */
    'downloadAppImageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'claimSampleImage'?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'claimSampleImageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'coverImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'handle': string;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'budgetTotal': string;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'budgetRemaining': string;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'numberOfCoins': string;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'magicLink': string;
    /**
     * 
     * @type {boolean}
     * @memberof BrandResponse
     */
    'isHotKeyNotificationSent'?: boolean;
    /**
    * 
    * @type {string}
    * @memberof BrandResponse
    */
    'brandWebsite': string;
    /**
     * 
     * @type {boolean}
     * @memberof BrandResponse
     */
    'newsletter': boolean | undefined;
    /**
     * 
     * @type {any}
     * @memberof BrandResponse
     */
    'brandContactsForClaims': any | undefined;
    /**
     * 
     * @type {any}
     * @memberof BrandResponse
     */
    'brandContactsForNewsLetter': any | undefined;
    /**
     * 
     * @type {any}
     * @memberof BrandResponse
     */
    'country'?: any | undefined;
    /**
     * 
     * @type {any}
     * @memberof BrandResponse
     */
    'isAutomatedEmail': any | undefined;
    /**
     * 
     * @type {boolean}
     * @memberof BrandResponse
     */
    'claimSample': boolean | undefined;
    /**
     * 
     * @type {any}
     * @memberof BrandResponse
     */
    'brandClaimSamples'?: any;
    /**
     * 
     * @type {boolean}
     * @memberof BrandResponse
     */
    'downloadApp': boolean | undefined;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'downloadAppDescription': string;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'downloadAppLink': string;
    /**
     * 
     * @type {Array<OfferListItem>}
     * @memberof BrandResponse
     */
    'offers': Array<OfferListItem>;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'partnerStatus': string;
    /**
     * 
     * @type {Array<Category>}
     * @memberof BrandResponse
     */
    'categories': Array<Category>;
    /**
     * 
     * @type {Array<Category>}
     * @memberof BrandResponse
     */
    'parentCategories': Array<Category>;
    /**
     * 
     * @type {Array<Category>}
     * @memberof BrandResponse
     */
    'childCategories': Array<Category>;
    /**
     * 
     * @type {Array<Category>}
     * @memberof BrandResponse
     */
    'followedCategories': Array<Category>;
    /**
     * 
     * @type {Array<Category>}
     * @memberof BrandResponse
     */
    'followedParentCategories': Array<Category>;
    /**
     * 
     * @type {Array<Category>}
     * @memberof BrandResponse
     */
    'followedChildCategories': Array<Category>;
}
/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    'parentId'?: string;
}
/**
 * 
 * @export
 * @interface CategoryRequest
 */
export interface CategoryRequest {
    /**
     * 
     * @type {string}
     * @memberof CategoryRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryRequest
     */
    'parentId': string;
}
/**
 * 
 * @export
 * @interface CodeUploadResponse
 */
export interface CodeUploadResponse {
    /**
     * 
     * @type {number}
     * @memberof CodeUploadResponse
     */
    'imported': number;
    /**
     * 
     * @type {boolean}
     * @memberof CodeUploadResponse
     */
    'appended': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CodeUploadResponse
     */
    'cleared': boolean;
}
/**
 * 
 * @export
 * @interface EditBrandRequest
 */
export interface EditBrandRequest {
    /**
     * 
     * @type {string}
     * @memberof EditBrandRequest
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBrandRequest
     */
    'budgetRemaining'?: string | number;
    /**
     * 
     * @type {string}
     * @memberof EditBrandRequest
     */
    'budgetTotal'?: string | number;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'numberOfCoins': string | number;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'magicLink': string;
    /**
     * 
     * @type {boolean}
     * @memberof BrandResponse
     */
    'isHotKeyNotificationSent'?: boolean;
    /**
    * 
    * @type {string}
    * @memberof BrandResponse
    */
    'brandWebsite': string;
    /**
     * 
     * @type {boolean}
     * @memberof BrandResponse
     */
    'newsletter': boolean;
    /**
     * 
     * @type {any}
     * @memberof BrandResponse
     */
    'brandContactsForClaims': any | undefined;
    /**
     * 
     * @type {any}
     * @memberof BrandResponse
     */
    'brandContactsForNewsLetter': any | undefined;
    /**
     * 
     * @type {any}
     * @memberof BrandResponse
     */
    'country'?: any | undefined;
    /**
     * 
     * @type {any}
     * @memberof BrandResponse
     */
    'isAutomatedEmail': any | undefined;
    /**
     * 
     * @type {boolean}
     * @memberof BrandResponse
     */
    'claimSample': boolean;
    /**
     * 
     * @type {any}
     * @memberof BrandResponse
     */
    'brandClaimSamples'?: any;
    /**
     * 
     * @type {boolean}
     * @memberof BrandResponse
     */
    'downloadApp': boolean;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'downloadAppDescription': string;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'downloadAppLink': string;
    /**
     * 
     * @type {string}
     * @memberof EditBrandRequest
     */
    'coverImagePath'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBrandRequest
     */
    'logoImagePath'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBrandRequest
     */
    'downloadAppImage'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBrandRequest
     */
    'downloadAppImageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBrandRequest
     */
    'claimSampleImage'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBrandRequest
     */
    'claimSampleImageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBrandRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditBrandRequest
     */
    'handle': string;
    /**
     * 
     * @type {string}
     * @memberof EditBrandRequest
     */
    'partnerStatus': string;
    /**
     * 
     * @type {Array<CategoryRequest>}
     * @memberof EditBrandRequest
     */
    'categories'?: Array<CategoryRequest>;
    /**
     * 
     * @type {Array<CategoryRequest>}
     * @memberof EditBrandRequest
     */
    'parentCategories'?: Array<CategoryRequest>;
    /**
     * 
     * @type {Array<CategoryRequest>}
     * @memberof EditBrandRequest
     */
    'childCategories'?: Array<CategoryRequest>;
    /**
     * 
     * @type {Array<CategoryRequest>}
     * @memberof EditBrandRequest
     */
    'followedCategories'?: Array<CategoryRequest>;
    /**
     * 
     * @type {Array<CategoryRequest>}
     * @memberof EditBrandRequest
     */
    'followedParentCategories'?: Array<CategoryRequest>;
    /**
     * 
     * @type {Array<CategoryRequest>}
     * @memberof EditBrandRequest
     */
    'followedChildCategories'?: Array<CategoryRequest>;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface FileUploadRequest
 */
export interface FileUploadRequest {
    /**
     * 
     * @type {string}
     * @memberof FileUploadRequest
     */
    'file'?: string;
}
/**
 * 
 * @export
 * @interface GetBrandsResponse
 */
export interface GetBrandsResponse {
    /**
     * 
     * @type {Array<BrandListItem>}
     * @memberof GetBrandsResponse
     */
    'items': Array<BrandListItem>;
}
/**
 * 
 * @export
 * @interface Halo
 */
export interface Halo {
    /**
     * 
     * @type {number}
     * @memberof Halo
     */
    'distributionUnit'?: number;
    /**
     * 
     * @type {number}
     * @memberof Halo
     */
    'distributionUnitInterval'?: number;
}
/**
 * 
 * @export
 * @interface ImageUploadResponse
 */
export interface ImageUploadResponse {
    /**
     * 
     * @type {string}
     * @memberof ImageUploadResponse
     */
    'imagePath': string;
    /**
     * 
     * @type {string}
     * @memberof ImageUploadResponse
     */
    'imagePathCompressedAsWebp': string;
    /**
     * 
     * @type {string}
     * @memberof ImageUploadResponse
     */
    'imagePathCompressedAsJpeg': string;
}
/**
 * 
 * @export
 * @interface OfferAttributeCounts
 */
export interface OfferAttributeCounts {
    /**
     * 
     * @type {number}
     * @memberof OfferAttributeCounts
     */
    'available': number;
    /**
     * 
     * @type {number}
     * @memberof OfferAttributeCounts
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface OfferListItem
 */
export interface OfferListItem {
    /**
     * 
     * @type {string}
     * @memberof OfferListItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OfferListItem
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof OfferListItem
     */
    'imagePath': string;
    /**
     * 
     * @type {string}
     * @memberof OfferListItem
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof OfferListItem
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof OfferListItem
     */
    'expiryTime': string;
    /**
     * 
     * @type {string}
     * @memberof OfferListItem
     */
    'showOn'?: string;
}
/**
 * 
 * @export
 * @interface OfferResponse
 */
export interface OfferResponse {
    /**
     * 
     * @type {string}
     * @memberof OfferResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OfferResponse
     */
    'brandId': string;
    /**
     * 
     * @type {string}
     * @memberof OfferResponse
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof AdvertisementResponse
     */
    'requiredCoins'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AddEditOfferRequest
     */
    'enableWeightage'?: boolean | any;
    /**
     * 
     * @type {boolean}
     * @memberof AddEditOfferRequest
     */
    'enableHalo'?: boolean | any;
    /**
     * 
     * @type {string}
     * @memberof OfferResponse
     */
    'subtitle': string;
    /**
     * 
     * @type {string}
     * @memberof OfferResponse
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof OfferResponse
     */
    'imagePath': string;
    /**
     * 
     * @type {string}
     * @memberof OfferResponse
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof OfferResponse
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof OfferResponse
     */
    'expiryTime': string;
    /**
     * 
     * @type {string}
     * @memberof OfferResponse
     */
    'redemptionType': string;
    /**
     * 
     * @type {string}
     * @memberof OfferResponse
     */
    'offerStatus'?: string;
    /**
     * 
     * @type {any}
     * @memberof OfferResponse
     */
    'countries'?: any;
    /**
     * 
     * @type {any}
     * @memberof OfferResponse
     */
    'followedCategories'?: any;
    /**
     * 
     * @type {any}
     * @memberof OfferResponse
     */
    'followedParentCategories'?: any;
    /**
     * 
     * @type {any}
     * @memberof OfferResponse
     */
    'followedChildCategories'?: any;
    /**
     * 
     * @type {string}
     * @memberof OfferResponse
     */
    'redemptionUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof OfferResponse
     */
    'usageLimit'?: number;
    /**
     * 
     * @type {OfferAttributeCounts}
     * @memberof OfferResponse
     */
    'offerCodes'?: OfferAttributeCounts;
    /**
     * BRAND_PAGE/NULL
     * @type {string}
     * @memberof OfferResponse
     */
    'showOn'?: string;
    /**
     * 
     * @type {Array<BoostResponse>}
     * @memberof OfferResponse
     */
    'boosts'?: Array<BoostResponse>;
}
/**
 * 
 * @export
 * @interface ShowOnRequest
 */
export interface ShowOnRequest {
    /**
     * BRAND_PAGE/NULL
     * @type {string}
     * @memberof ShowOnRequest
     */
    'showOn'?: string;
}
/**
 * 
 * @export
 * @interface TestRequestModel
 */
export interface TestRequestModel {
    /**
     * 
     * @type {number}
     * @memberof TestRequestModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TestRequestModel
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TestRequestModel
     */
    'created'?: string;
}
/**
 * 
 * @export
 * @interface TestResponseModel
 */
export interface TestResponseModel {
    /**
     * 
     * @type {number}
     * @memberof TestResponseModel
     */
    'id': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof TestResponseModel
     */
    'items'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TestResponseModel
     */
    'camelCaseName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TestResponseModel
     */
    'nameOverriddenByAttribute'?: string;
}
/**
 * 
 * @export
 * @interface UploadedCsvAffiliatedOfferRow
 */
export interface UploadedCsvAffiliatedOfferRow {
    /**
     * 
     * @type {string}
     * @memberof UploadedCsvAffiliatedOfferRow
     */
    'merchantName'?: string;
    /**
     * 
     * @type {number}
     * @memberof UploadedCsvAffiliatedOfferRow
     */
    'merchantId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UploadedCsvAffiliatedOfferRow
     */
    'imageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadedCsvAffiliatedOfferRow
     */
    'productName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadedCsvAffiliatedOfferRow
     */
    'merchantDeepLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadedCsvAffiliatedOfferRow
     */
    'categoryName'?: string;
    /**
     * 
     * @type {number}
     * @memberof UploadedCsvAffiliatedOfferRow
     */
    'categoryId'?: number;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Ad
         * @param {string} brandId 
         * @param {AddEditAdvertRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAd: async (brandId: string, body?: AddEditAdvertRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('createAd', 'brandId', brandId)
            const localVarPath = `/brand/{brandId}/ad`
                .replace(`{${"brandId"}}`, encodeURIComponent(String(brandId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Brand
         * @param {AddBrandRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBrand: async (body?: AddBrandRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brand`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Offer
         * @param {string} brandId 
         * @param {AddEditOfferRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOffer: async (brandId: string, body?: AddEditOfferRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('createOffer', 'brandId', brandId)
            const localVarPath = `/brand/{brandId}/offer`
                .replace(`{${"brandId"}}`, encodeURIComponent(String(brandId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Brand
         * @param {string} brandId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBrand: async (brandId: string, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('deleteBrand', 'brandId', brandId)
            const localVarPath = `/brand/delete/{brandId}`
                .replace(`{${"brandId"}}`, encodeURIComponent(String(brandId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit Ad
         * @param {string} brandId 
         * @param {string} adId 
         * @param {AddEditAdvertRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAd: async (brandId: string, adId: string, body?: AddEditAdvertRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('editAd', 'brandId', brandId)
            // verify required parameter 'adId' is not null or undefined
            assertParamExists('editAd', 'adId', adId)
            const localVarPath = `/brand/{brandId}/ad/{adId}`
                .replace(`{${"brandId"}}`, encodeURIComponent(String(brandId)))
                .replace(`{${"adId"}}`, encodeURIComponent(String(adId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit Brand
         * @param {string} brandId 
         * @param {EditBrandRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editBrand: async (brandId: string, body?: EditBrandRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('editBrand', 'brandId', brandId)
            const localVarPath = `/brand/{brandId}`
                .replace(`{${"brandId"}}`, encodeURIComponent(String(brandId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit Offer
         * @param {string} brandId 
         * @param {string} offerId 
         * @param {AddEditOfferRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editOffer: async (brandId: string, offerId: string, body?: AddEditOfferRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('editOffer', 'brandId', brandId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('editOffer', 'offerId', offerId)
            const localVarPath = `/brand/{brandId}/offer/{offerId}`
                .replace(`{${"brandId"}}`, encodeURIComponent(String(brandId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit Offer Show On
         * @param {string} brandId 
         * @param {string} offerId 
         * @param {ShowOnRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editOfferShowOn: async (brandId: string, offerId: string, body?: ShowOnRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('editOfferShowOn', 'brandId', brandId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('editOfferShowOn', 'offerId', offerId)
            const localVarPath = `/brand/{brandId}/offer/{offerId}/showon`
                .replace(`{${"brandId"}}`, encodeURIComponent(String(brandId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Ad By Id
         * @param {string} brandId 
         * @param {string} adId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdById: async (brandId: string, adId: string, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('getAdById', 'brandId', brandId)
            // verify required parameter 'adId' is not null or undefined
            assertParamExists('getAdById', 'adId', adId)
            const localVarPath = `/brand/{brandId}/ad/{adId}`
                .replace(`{${"brandId"}}`, encodeURIComponent(String(brandId)))
                .replace(`{${"adId"}}`, encodeURIComponent(String(adId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Brand Ads
         * @param {string} brandId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandAds: async (brandId: string, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('getBrandAds', 'brandId', brandId)
            const localVarPath = `/brand/{brandId}/ads`
                .replace(`{${"brandId"}}`, encodeURIComponent(String(brandId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all offer details for specific brand
         * @param {string} brandId 
         * @param {string} [startBy] Date the offer must have started before .i.e 2022-08-30T12:00:00.000Z
         * @param {string} [validUntil] Date the offer must at least be valid until .i.e 2022-10-01T12:00:00.00Z
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandOffers: async (brandId: string, startBy?: string, validUntil?: string, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('getBrandOffers', 'brandId', brandId)
            const localVarPath = `/brand/{brandId}/offer`
                .replace(`{${"brandId"}}`, encodeURIComponent(String(brandId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startBy !== undefined) {
                localVarQueryParameter['startBy'] = startBy;
            }

            if (validUntil !== undefined) {
                localVarQueryParameter['validUntil'] = validUntil;
            }



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Brands
         * @param {string} [sort] Sort by name: asc/desc
         * @param {string} [partnerStatus] Is a partner: PARTNER
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrands: async (sort?: string, partnerStatus?: string, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brand`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (partnerStatus !== undefined) {
                localVarQueryParameter['partnerStatus'] = partnerStatus;
            }



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        getUsers: async (sort?: string, partnerStatus?: string, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users?pagination=true`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (partnerStatus !== undefined) {
                localVarQueryParameter['partnerStatus'] = partnerStatus;
            }



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Brand By Id
         * @param {string} brandId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandsById: async (brandId: string, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('getBrandsById', 'brandId', brandId)
            const localVarPath = `/brand/{brandId}`
                .replace(`{${"brandId"}}`, encodeURIComponent(String(brandId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Offer By Id
         * @param {string} brandId 
         * @param {string} offerId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferById: async (brandId: string, offerId: string, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('getOfferById', 'brandId', brandId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('getOfferById', 'offerId', offerId)
            const localVarPath = `/brand/{brandId}/offer/{offerId}`
                .replace(`{${"brandId"}}`, encodeURIComponent(String(brandId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetSwaggerUIHandler
         * @param {string} path 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSwaggerUI: async (path: string, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'path' is not null or undefined
            assertParamExists('getSwaggerUI', 'path', path)
            const localVarPath = `/swagger-ui/{path}`
                .replace(`{${"path"}}`, encodeURIComponent(String(path)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the global welcome offer
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWelcomeOffer: async (body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/welcomeoffer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Notify new brand has joined Tickle
         * @param {string} brandId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyNewBrand: async (brandId: string, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('notifyNewBrand', 'brandId', brandId)
            const localVarPath = `/notification/{brandId}`
                .replace(`{${"brandId"}}`, encodeURIComponent(String(brandId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Handles csv submission
         * @param {string} id 
         * @param {string} mode Mode is one of: APPEND, OVERWRITE, CLEAR
         * @param {string} [boostId] Id of boost within the offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUploadCsv: async (id: string, mode: string, boostId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postUploadCsv', 'id', id)
            // verify required parameter 'mode' is not null or undefined
            assertParamExists('postUploadCsv', 'mode', mode)
            const localVarPath = `/offer/{id}/codes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (mode !== undefined) {
                localVarQueryParameter['mode'] = mode;
            }

            if (boostId !== undefined) {
                localVarQueryParameter['boostId'] = boostId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Handles csv submission for affiliate offers
         * @param {string} save Save is one of: true, false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUploadCsvAffiliateOffers: async (save: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'save' is not null or undefined
            assertParamExists('postUploadCsvAffiliateOffers', 'save', save)
            const localVarPath = `/affiliateoffer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (save !== undefined) {
                localVarQueryParameter['save'] = save;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Displays form for csv submission
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUploadCsvAffiliateOffersForm: async (body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/affiliateoffer-upload-form`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Display form for csv submission
         * @param {string} id 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUploadCsvForm: async (id: string, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postUploadCsvForm', 'id', id)
            const localVarPath = `/offer/{id}/codes-upload-form`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Handles image submission
         * @param {string} type Image type is one of: BRAND_LOGO, BRAND_COVER, AD_IMAGE, OFFER_IMAGE
         * @param {string} brandId Brand id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUploadImage: async (type: string, brandId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('postUploadImage', 'type', type)
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('postUploadImage', 'brandId', brandId)
            const localVarPath = `/image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (brandId !== undefined) {
                localVarQueryParameter['brandId'] = brandId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Display form for image submission
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUploadImageForm: async (body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/image-upload-form`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets the global welcome offer
         * @param {string} offerId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setWelcomeOffer: async (offerId: string, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('setWelcomeOffer', 'offerId', offerId)
            const localVarPath = `/welcomeoffer/{offerId}`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary A test handler
         * @param {string} testPathParam1 
         * @param {string} testPathParam2 
         * @param {string} queryParam1 queryParam1Desc
         * @param {string} queryParam2 queryParam2Desc
         * @param {TestRequestModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPost: async (testPathParam1: string, testPathParam2: string, queryParam1: string, queryParam2: string, body?: TestRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testPathParam1' is not null or undefined
            assertParamExists('testPost', 'testPathParam1', testPathParam1)
            // verify required parameter 'testPathParam2' is not null or undefined
            assertParamExists('testPost', 'testPathParam2', testPathParam2)
            // verify required parameter 'queryParam1' is not null or undefined
            assertParamExists('testPost', 'queryParam1', queryParam1)
            // verify required parameter 'queryParam2' is not null or undefined
            assertParamExists('testPost', 'queryParam2', queryParam2)
            const localVarPath = `/test/post/{testPathParam1}/{testPathParam2}`
                .replace(`{${"testPathParam1"}}`, encodeURIComponent(String(testPathParam1)))
                .replace(`{${"testPathParam2"}}`, encodeURIComponent(String(testPathParam2)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (queryParam1 !== undefined) {
                localVarQueryParameter['queryParam1'] = queryParam1;
            }

            if (queryParam2 !== undefined) {
                localVarQueryParameter['queryParam2'] = queryParam2;
            }



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Ad
         * @param {string} brandId 
         * @param {AddEditAdvertRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAd(brandId: string, body?: AddEditAdvertRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdvertisementResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAd(brandId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Brand
         * @param {AddBrandRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBrand(body?: AddBrandRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBrand(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Offer
         * @param {string} brandId 
         * @param {AddEditOfferRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOffer(brandId: string, body?: AddEditOfferRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOffer(brandId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Brand
         * @param {string} brandId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBrand(brandId: string, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBrand(brandId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit Ad
         * @param {string} brandId 
         * @param {string} adId 
         * @param {AddEditAdvertRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editAd(brandId: string, adId: string, body?: AddEditAdvertRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdvertisementResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editAd(brandId, adId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit Brand
         * @param {string} brandId 
         * @param {EditBrandRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editBrand(brandId: string, body?: EditBrandRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editBrand(brandId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit Offer
         * @param {string} brandId 
         * @param {string} offerId 
         * @param {AddEditOfferRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editOffer(brandId: string, offerId: string, body?: AddEditOfferRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editOffer(brandId, offerId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit Offer Show On
         * @param {string} brandId 
         * @param {string} offerId 
         * @param {ShowOnRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editOfferShowOn(brandId: string, offerId: string, body?: ShowOnRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editOfferShowOn(brandId, offerId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Ad By Id
         * @param {string} brandId 
         * @param {string} adId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdById(brandId: string, adId: string, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdvertisementResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdById(brandId, adId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Brand Ads
         * @param {string} brandId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBrandAds(brandId: string, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandAdsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandAds(brandId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all offer details for specific brand
         * @param {string} brandId 
         * @param {string} [startBy] Date the offer must have started before .i.e 2022-08-30T12:00:00.000Z
         * @param {string} [validUntil] Date the offer must at least be valid until .i.e 2022-10-01T12:00:00.00Z
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBrandOffers(brandId: string, startBy?: string, validUntil?: string, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandOfferResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandOffers(brandId, startBy, validUntil, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get All Brands
         * @param {string} [sort] Sort by name: asc/desc
         * @param {string} [partnerStatus] Is a partner: PARTNER
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBrands(sort?: string, partnerStatus?: string, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBrandsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBrands(sort, partnerStatus, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async getUsers(sort?: string, partnerStatus?: string, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(sort, partnerStatus, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Brand By Id
         * @param {string} brandId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBrandsById(brandId: string, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandsById(brandId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Offer By Id
         * @param {string} brandId 
         * @param {string} offerId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfferById(brandId: string, offerId: string, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfferById(brandId, offerId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary GetSwaggerUIHandler
         * @param {string} path 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSwaggerUI(path: string, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSwaggerUI(path, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the global welcome offer
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWelcomeOffer(body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWelcomeOffer(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Notify new brand has joined Tickle
         * @param {string} brandId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notifyNewBrand(brandId: string, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notifyNewBrand(brandId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Handles csv submission
         * @param {string} id 
         * @param {string} mode Mode is one of: APPEND, OVERWRITE, CLEAR
         * @param {string} [boostId] Id of boost within the offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUploadCsv(id: string, mode: string, boostId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CodeUploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUploadCsv(id, mode, boostId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Handles csv submission for affiliate offers
         * @param {string} save Save is one of: true, false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUploadCsvAffiliateOffers(save: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffiliateOfferUploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUploadCsvAffiliateOffers(save, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Displays form for csv submission
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUploadCsvAffiliateOffersForm(body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUploadCsvAffiliateOffersForm(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Display form for csv submission
         * @param {string} id 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUploadCsvForm(id: string, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUploadCsvForm(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Handles image submission
         * @param {string} type Image type is one of: BRAND_LOGO, BRAND_COVER, AD_IMAGE, OFFER_IMAGE
         * @param {string} brandId Brand id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUploadImage(type: string, brandId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageUploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUploadImage(type, brandId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Display form for image submission
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUploadImageForm(body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUploadImageForm(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets the global welcome offer
         * @param {string} offerId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setWelcomeOffer(offerId: string, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setWelcomeOffer(offerId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary A test handler
         * @param {string} testPathParam1 
         * @param {string} testPathParam2 
         * @param {string} queryParam1 queryParam1Desc
         * @param {string} queryParam2 queryParam2Desc
         * @param {TestRequestModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPost(testPathParam1: string, testPathParam2: string, queryParam1: string, queryParam2: string, body?: TestRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPost(testPathParam1, testPathParam2, queryParam1, queryParam2, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Ad
         * @param {string} brandId 
         * @param {AddEditAdvertRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAd(brandId: string, body?: AddEditAdvertRequest, options?: any): AxiosPromise<AdvertisementResponse> {
            return localVarFp.createAd(brandId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Brand
         * @param {AddBrandRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBrand(body?: AddBrandRequest, options?: any): AxiosPromise<BrandResponse> {
            return localVarFp.createBrand(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Offer
         * @param {string} brandId 
         * @param {AddEditOfferRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOffer(brandId: string, body?: AddEditOfferRequest, options?: any): AxiosPromise<OfferResponse> {
            return localVarFp.createOffer(brandId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Brand
         * @param {string} brandId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBrand(brandId: string, body?: object, options?: any): AxiosPromise<BrandResponse> {
            return localVarFp.deleteBrand(brandId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit Ad
         * @param {string} brandId 
         * @param {string} adId 
         * @param {AddEditAdvertRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAd(brandId: string, adId: string, body?: AddEditAdvertRequest, options?: any): AxiosPromise<AdvertisementResponse> {
            return localVarFp.editAd(brandId, adId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit Brand
         * @param {string} brandId 
         * @param {EditBrandRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editBrand(brandId: string, body?: EditBrandRequest, options?: any): AxiosPromise<BrandResponse> {
            return localVarFp.editBrand(brandId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit Offer
         * @param {string} brandId 
         * @param {string} offerId 
         * @param {AddEditOfferRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editOffer(brandId: string, offerId: string, body?: AddEditOfferRequest, options?: any): AxiosPromise<OfferResponse> {
            return localVarFp.editOffer(brandId, offerId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit Offer Show On
         * @param {string} brandId 
         * @param {string} offerId 
         * @param {ShowOnRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editOfferShowOn(brandId: string, offerId: string, body?: ShowOnRequest, options?: any): AxiosPromise<OfferResponse> {
            return localVarFp.editOfferShowOn(brandId, offerId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Ad By Id
         * @param {string} brandId 
         * @param {string} adId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdById(brandId: string, adId: string, body?: object, options?: any): AxiosPromise<AdvertisementResponse> {
            return localVarFp.getAdById(brandId, adId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Brand Ads
         * @param {string} brandId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandAds(brandId: string, body?: object, options?: any): AxiosPromise<BrandAdsResponse> {
            return localVarFp.getBrandAds(brandId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all offer details for specific brand
         * @param {string} brandId 
         * @param {string} [startBy] Date the offer must have started before .i.e 2022-08-30T12:00:00.000Z
         * @param {string} [validUntil] Date the offer must at least be valid until .i.e 2022-10-01T12:00:00.00Z
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandOffers(brandId: string, startBy?: string, validUntil?: string, body?: object, options?: any): AxiosPromise<BrandOfferResponse> {
            return localVarFp.getBrandOffers(brandId, startBy, validUntil, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Brands
         * @param {string} [sort] Sort by name: asc/desc
         * @param {string} [partnerStatus] Is a partner: PARTNER
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrands(sort?: string, partnerStatus?: string, body?: object, options?: any): AxiosPromise<GetBrandsResponse> {
            return localVarFp.getBrands(sort, partnerStatus, body, options).then((request) => request(axios, basePath));
        },
        getUsers(sort?: string, partnerStatus?: string, body?: object, options?: any): AxiosPromise<any> {
            return localVarFp.getUsers(sort, partnerStatus, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Brand By Id
         * @param {string} brandId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandsById(brandId: string, body?: object, options?: any): AxiosPromise<BrandResponse> {
            return localVarFp.getBrandsById(brandId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Offer By Id
         * @param {string} brandId 
         * @param {string} offerId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferById(brandId: string, offerId: string, body?: object, options?: any): AxiosPromise<OfferResponse> {
            return localVarFp.getOfferById(brandId, offerId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetSwaggerUIHandler
         * @param {string} path 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSwaggerUI(path: string, body?: object, options?: any): AxiosPromise<string> {
            return localVarFp.getSwaggerUI(path, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the global welcome offer
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWelcomeOffer(body?: object, options?: any): AxiosPromise<OfferResponse> {
            return localVarFp.getWelcomeOffer(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Notify new brand has joined Tickle
         * @param {string} brandId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyNewBrand(brandId: string, body?: object, options?: any): AxiosPromise<BrandResponse> {
            return localVarFp.notifyNewBrand(brandId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Handles csv submission
         * @param {string} id 
         * @param {string} mode Mode is one of: APPEND, OVERWRITE, CLEAR
         * @param {string} [boostId] Id of boost within the offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUploadCsv(id: string, mode: string, boostId?: string, options?: any): AxiosPromise<CodeUploadResponse> {
            return localVarFp.postUploadCsv(id, mode, boostId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Handles csv submission for affiliate offers
         * @param {string} save Save is one of: true, false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUploadCsvAffiliateOffers(save: string, options?: any): AxiosPromise<AffiliateOfferUploadResponse> {
            return localVarFp.postUploadCsvAffiliateOffers(save, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Displays form for csv submission
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUploadCsvAffiliateOffersForm(body?: object, options?: any): AxiosPromise<string> {
            return localVarFp.postUploadCsvAffiliateOffersForm(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Display form for csv submission
         * @param {string} id 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUploadCsvForm(id: string, body?: object, options?: any): AxiosPromise<string> {
            return localVarFp.postUploadCsvForm(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Handles image submission
         * @param {string} type Image type is one of: BRAND_LOGO, BRAND_COVER, AD_IMAGE, OFFER_IMAGE
         * @param {string} brandId Brand id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUploadImage(type: string, brandId: string, options?: any): AxiosPromise<ImageUploadResponse> {
            return localVarFp.postUploadImage(type, brandId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Display form for image submission
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUploadImageForm(body?: object, options?: any): AxiosPromise<string> {
            return localVarFp.postUploadImageForm(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets the global welcome offer
         * @param {string} offerId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setWelcomeOffer(offerId: string, body?: object, options?: any): AxiosPromise<string> {
            return localVarFp.setWelcomeOffer(offerId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary A test handler
         * @param {string} testPathParam1 
         * @param {string} testPathParam2 
         * @param {string} queryParam1 queryParam1Desc
         * @param {string} queryParam2 queryParam2Desc
         * @param {TestRequestModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPost(testPathParam1: string, testPathParam2: string, queryParam1: string, queryParam2: string, body?: TestRequestModel, options?: any): AxiosPromise<TestResponseModel> {
            return localVarFp.testPost(testPathParam1, testPathParam2, queryParam1, queryParam2, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Create Ad
     * @param {string} brandId 
     * @param {AddEditAdvertRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createAd(brandId: string, body?: AddEditAdvertRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createAd(brandId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Brand
     * @param {AddBrandRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createBrand(body?: AddBrandRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createBrand(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Offer
     * @param {string} brandId 
     * @param {AddEditOfferRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createOffer(brandId: string, body?: AddEditOfferRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createOffer(brandId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Brand
     * @param {string} brandId 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteBrand(brandId: string, body?: object, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteBrand(brandId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit Ad
     * @param {string} brandId 
     * @param {string} adId 
     * @param {AddEditAdvertRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editAd(brandId: string, adId: string, body?: AddEditAdvertRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).editAd(brandId, adId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit Brand
     * @param {string} brandId 
     * @param {EditBrandRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editBrand(brandId: string, body?: EditBrandRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).editBrand(brandId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit Offer
     * @param {string} brandId 
     * @param {string} offerId 
     * @param {AddEditOfferRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editOffer(brandId: string, offerId: string, body?: AddEditOfferRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).editOffer(brandId, offerId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit Offer Show On
     * @param {string} brandId 
     * @param {string} offerId 
     * @param {ShowOnRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editOfferShowOn(brandId: string, offerId: string, body?: ShowOnRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).editOfferShowOn(brandId, offerId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Ad By Id
     * @param {string} brandId 
     * @param {string} adId 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAdById(brandId: string, adId: string, body?: object, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAdById(brandId, adId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Brand Ads
     * @param {string} brandId 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getBrandAds(brandId: string, body?: object, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getBrandAds(brandId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all offer details for specific brand
     * @param {string} brandId 
     * @param {string} [startBy] Date the offer must have started before .i.e 2022-08-30T12:00:00.000Z
     * @param {string} [validUntil] Date the offer must at least be valid until .i.e 2022-10-01T12:00:00.00Z
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getBrandOffers(brandId: string, startBy?: string, validUntil?: string, body?: object, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getBrandOffers(brandId, startBy, validUntil, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Brands
     * @param {string} [sort] Sort by name: asc/desc
     * @param {string} [partnerStatus] Is a partner: PARTNER
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getBrands(sort?: string, partnerStatus?: string, body?: object, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getBrands(sort, partnerStatus, body, options).then((request) => request(this.axios, this.basePath));
    }
    public getUsers(sort?: string, partnerStatus?: string, body?: object, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getUsers(sort, partnerStatus, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Brand By Id
     * @param {string} brandId 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getBrandsById(brandId: string, body?: object, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getBrandsById(brandId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Offer By Id
     * @param {string} brandId 
     * @param {string} offerId 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getOfferById(brandId: string, offerId: string, body?: object, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getOfferById(brandId, offerId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetSwaggerUIHandler
     * @param {string} path 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSwaggerUI(path: string, body?: object, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getSwaggerUI(path, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the global welcome offer
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getWelcomeOffer(body?: object, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getWelcomeOffer(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Notify new brand has joined Tickle
     * @param {string} brandId 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public notifyNewBrand(brandId: string, body?: object, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).notifyNewBrand(brandId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Handles csv submission
     * @param {string} id 
     * @param {string} mode Mode is one of: APPEND, OVERWRITE, CLEAR
     * @param {string} [boostId] Id of boost within the offer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postUploadCsv(id: string, mode: string, boostId?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postUploadCsv(id, mode, boostId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Handles csv submission for affiliate offers
     * @param {string} save Save is one of: true, false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postUploadCsvAffiliateOffers(save: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postUploadCsvAffiliateOffers(save, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Displays form for csv submission
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postUploadCsvAffiliateOffersForm(body?: object, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postUploadCsvAffiliateOffersForm(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Display form for csv submission
     * @param {string} id 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postUploadCsvForm(id: string, body?: object, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postUploadCsvForm(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Handles image submission
     * @param {string} type Image type is one of: BRAND_LOGO, BRAND_COVER, AD_IMAGE, OFFER_IMAGE
     * @param {string} brandId Brand id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postUploadImage(type: string, brandId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postUploadImage(type, brandId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Display form for image submission
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postUploadImageForm(body?: object, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postUploadImageForm(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets the global welcome offer
     * @param {string} offerId 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public setWelcomeOffer(offerId: string, body?: object, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).setWelcomeOffer(offerId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary A test handler
     * @param {string} testPathParam1 
     * @param {string} testPathParam2 
     * @param {string} queryParam1 queryParam1Desc
     * @param {string} queryParam2 queryParam2Desc
     * @param {TestRequestModel} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public testPost(testPathParam1: string, testPathParam2: string, queryParam1: string, queryParam2: string, body?: TestRequestModel, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).testPost(testPathParam1, testPathParam2, queryParam1, queryParam2, body, options).then((request) => request(this.axios, this.basePath));
    }
}


